import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './index.module.scss'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import { ReactComponent as Logo } from 'assets/images/logo.svg'
import { motion, useAnimation, AnimatePresence } from 'framer-motion'
import Container from '../../Container'
import ThemeToggle from '../ThemeToggle'
import Hamburger from '../Hamburger'
import ShareDropdown from '../ShareDropdown'
import useStore from 'components/layout/_Layout/introStore'
import { useLocation } from 'react-router-dom'
import gaEvent from 'utils/analytics'
import { useDocumentSize } from 'utils/useDocumentSize'
import { useIntroHasPlayed } from 'utils/useIntroHasPlayed'
import { useTranslation } from 'react-i18next'

export default function Header({
  theme,
  setTheme,
  hideExtra,
  position,
  whiteLogo = false,
  pageType,
}) {
  const { t } = useTranslation('ada')
  const introComplete = useStore((state) => state.introComplete)
  const controls = useAnimation()
  const documentSize = useDocumentSize()

  const [scrolled, setScrolled] = useState(false)
  const [sticky, setSticky] = useState(false)
  const location = useLocation()
  const introHasPlayed = useIntroHasPlayed()

  useEffect(() => {
    if (introComplete) {
      controls.start({
        opacity: 1,
        y: 0,
        transition: { duration: 0.5, delay: 0.75 },
      })
      return
    }
    if (introHasPlayed || pageType) {
      controls.start({
        opacity: 1,
        y: 0,
        transition: { duration: 0.5, delay: 1 },
      })
    }
  }, [introComplete, controls, introHasPlayed, pageType])

  useEffect(() => {
    const handleScroll = () => {
      let inView = window?.scrollY < 120
      !scrolled && setScrolled(true)

      if (!inView) {
        if (!sticky) {
          setSticky(true)
        }
      } else {
        if (sticky) {
          setSticky(false)
        }
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [sticky, scrolled])

  const variants = {
    initial: { opacity: 0, y: '-20px' },
    animate: { opacity: 1, y: 0, transition: { duration: 0.35 } },
    exit: { opacity: 0, y: '-20px' },
  }

  return (
    <AnimatePresence>
      <motion.header
        key="main-header"
        initial={{ opacity: 0, y: '-20px' }}
        animate={controls}
        exit={{ opacity: 0, y: '-20px' }}
        transition={{
          duration: scrolled ? 0.35 : 0.5,
          delay: introHasPlayed ? (scrolled ? 0 : 1) : 8.75,
        }}
        className={cn(
          styles.header,
          styles[theme],
          styles[position],
          styles[pageType],
          {
            [styles.hasHash]: location?.hash,
            [styles.scrolled]: scrolled,
            [styles.sticky]: sticky,
            [styles.hidden]: !introComplete && !introHasPlayed,
          }
        )}>
        <Container className={styles.container} fullbleed>
          <div className={styles.inner}>
            {(!sticky ||
              (pageType === 'event' && documentSize?.width > 767)) && (
              <motion.div
                className={cn(
                  styles.logo,
                  whiteLogo ? styles.whiteLogo : undefined
                )}
                {...variants}>
                <Link
                  to="/"
                  aria-label={t('header.logo.aria')}
                  onClick={() =>
                    gaEvent('Main Navigation', 'Click', 'RTW Logo')
                  }>
                  <Logo alt={t('header.logo.alt')} />
                </Link>
              </motion.div>
            )}

            <div className={styles.buttons}>
              {!hideExtra && (
                <>
                  {!sticky && (
                    <motion.div className={styles.shareBtn} {...variants}>
                      <ShareDropdown />
                    </motion.div>
                  )}

                  {!sticky && (
                    <motion.div className={styles.themeBtn} {...variants}>
                      <ThemeToggle theme={theme} setTheme={setTheme} />
                    </motion.div>
                  )}
                </>
              )}

              <Hamburger pageType={pageType} sticky={sticky} />
            </div>
          </div>
        </Container>
      </motion.header>
    </AnimatePresence>
  )
}

Header.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark']),
  setTheme: PropTypes.func,
  hideExtra: PropTypes.bool,
  position: PropTypes.oneOf(['relative', 'fixed']),
  whiteLogo: PropTypes.bool,
  pageType: PropTypes.string,
}
