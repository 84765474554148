import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './index.module.scss'
import Button from '../Button'
import eventShape from 'shapes/event'
import { parseHtml } from 'utils/parseHtml'
import gaEvent from 'utils/analytics'
import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { isAfter } from 'date-fns'

const EventCard = ({ event, open, setActiveEvent, id }) => {
  const { t } = useTranslation('ada')
  const {
    title,
    bgColor,
    date,
    datetime_end,
    summary,
    post_event_message,
    cta_text,
    cta_text_post_event,
    cta_link,
    cta_link_post_event,
    bgImage,
  } = event || {}

  function handleClick() {
    setActiveEvent(title)
    gaEvent('IRL Events', 'Click', 'Expand Event', title)
  }
  const isEventsOver =
    process.env.REACT_APP_STATE === 'events_over' && title !== 'Los Angeles'
  const isPastEvent =
    isAfter(new Date(), new Date(datetime_end)) || isEventsOver
  const description = isPastEvent ? post_event_message : summary

  // when events are over, if an event has coalition resources, show page link
  // before events are over, if an event is not over, show page link
  const showPageLink =
    (isEventsOver && (title === 'Detroit' || title === 'Washington D.C.')) ||
    (!isEventsOver && !isPastEvent)

  const ctaText = showPageLink ? cta_text : cta_text_post_event
  const ctaLink = showPageLink ? cta_link : cta_link_post_event

  return (
    <AnimatePresence exitBeforeEnter>
      <div
        className={cn(styles.wrapper, {
          [styles.active]: open,
          [styles.past]: isPastEvent,
          [styles.eventsOver]: isEventsOver,
        })}>
        {!open && (
          <motion.button
            className={styles.inner}
            layoutId={`event=${title}`}
            onClick={handleClick}
            style={{ '--theme-color': bgColor }}
            aria-label={t('expand_event_card.aria', { title: title })}>
            <motion.h2
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
              }}
              exit={{
                opacity: 0,
              }}
              className={cn(styles.verticalTitle)}>
              {title}
            </motion.h2>
          </motion.button>
        )}

        {open && (
          <motion.div
            layoutId={`event=${title}`}
            className={styles.inner}
            style={{ '--theme-color': bgColor }}>
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{
                opacity: 1,
                y: 0,
                transition: {
                  ease: 'easeInOut',
                  duration: 0.35,
                  delay: 0.4,
                },
              }}
              exit={{ opacity: 0, y: 10 }}
              className={cn(styles.hiddenWrapper, { [styles.active]: open })}>
              <div className={styles.contentWrapper}>
                <div className={styles.top}>
                  <h2 className={styles.title}>{title}</h2>
                  <div className={styles.date}>{date}</div>
                </div>

                {description && (
                  <div className={styles.description}>
                    {parseHtml(description)}
                  </div>
                )}
              </div>
              <Button
                fullWidth
                link={ctaLink}
                gaEventData={['IRL Events', 'Click', 'Learn More', title]}
                className={cn(styles.cta)}
                aria={t('global.learn_more', { title: title, suffix: 'event' })}
                type="primary">
                {ctaText}
              </Button>
            </motion.div>
          </motion.div>
        )}

        {bgImage && (
          <img
            className={cn(styles.bgImage, {
              [styles.active]: open,
              [styles.top]: bgImage.top,
            })}
            alt=""
            src={bgImage.src}
          />
        )}
      </div>
    </AnimatePresence>
  )
}

EventCard.propTypes = {
  collapsed: PropTypes.bool,
  open: PropTypes.bool,
  event: eventShape,
  setActiveEvent: PropTypes.func,
  id: PropTypes.string,
}

export default EventCard
