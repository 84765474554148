/* eslint-disable import/no-unresolved */
import PropTypes from 'prop-types'
import styles from './index.module.scss'
import { useRef } from 'react'
import { useTreeState } from '@react-stately/tree'
import { useMenu } from '@react-aria/menu'
import { useOverlay, DismissButton } from '@react-aria/overlays'
import { FocusScope } from '@react-aria/focus'
import MenuItem from '../MenuItem'

const Menu = ({ onAction, active, ...props }) => {
  let ref = useRef()
  let state = useTreeState({ ...props, selectionMode: 'none' })
  let { menuProps } = useMenu(props, state, ref)

  // Handle events that should cause the menu to close,
  // e.g. blur, clicking outside, or pressing the escape key.
  let overlayRef = useRef()
  let { overlayProps } = useOverlay(
    {
      onClose: props.onClose,
      shouldCloseOnBlur: true,
      isOpen: true,
      isDismissable: true,
    },
    overlayRef
  )

  // Wrap in <FocusScope> so that focus is restored back to the
  // trigger when the menu is closed. In addition, add hidden
  // <DismissButton> components at the start and end of the list
  // to allow screen reader users to dismiss the popup easily.
  return (
    <FocusScope restoreFocus>
      <div {...overlayProps} ref={overlayRef}>
        <DismissButton onDismiss={props.onClose} />
        <ul {...menuProps} ref={ref} className={styles.menu}>
          {[...state.collection].map((item, index) => (
            <MenuItem
              key={item.key}
              state={state}
              item={item}
              onAction={onAction}
              onClose={props.onClose}
              active={active}
              index={index}
              total={[...state.collection]?.length}
            />
          ))}
        </ul>
        <DismissButton onDismiss={props.onClose} />
      </div>
    </FocusScope>
  )
}

Menu.propTypes = {
  onAction: PropTypes.func,
  onClose: PropTypes.func,
  active: PropTypes.string,
}
export default Menu
