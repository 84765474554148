import { useRef } from 'react'
import { useListBox, useOption } from 'react-aria'
import cn from 'classnames'
import PropTypes from 'prop-types'
import styles from './index.module.scss'

const Option = ({ className = '', item, state }) => {
  const ref = useRef()
  const { optionProps, isSelected, isFocused } = useOption(
    { key: item.key },
    state,
    ref
  )

  return (
    <li
      {...optionProps}
      ref={ref}
      className={cn(className, styles.item, {
        selected: isSelected,
        focused: isFocused,
      })}>
      {item.rendered}
    </li>
  )
}

Option.propTypes = {
  item: PropTypes.shape({
    key: PropTypes.string,
    rendered: PropTypes.node,
  }),
  state: PropTypes.object,
  className: PropTypes.string,
}

const ListBox = ({ className = '', itemClassName = '', ...props }) => {
  const { listBoxRef, state } = props
  const { listBoxProps } = useListBox(props, state, listBoxRef)

  return (
    <ul
      {...listBoxProps}
      ref={listBoxRef}
      className={cn(className, styles.container)}>
      {[...state.collection].map((item) => (
        <Option
          key={item.key}
          item={item}
          state={state}
          className={itemClassName}
        />
      ))}
    </ul>
  )
}

ListBox.propTypes = {
  listBoxRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  state: PropTypes.object,
  className: PropTypes.string,
  itemClassName: PropTypes.string,
}

export default ListBox
