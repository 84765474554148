/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import { AnimatePresence, motion } from 'framer-motion'
import cn from 'classnames'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import Container from '../../../layout/Container'
import Grid from '../../../layout/Grid'
import SectionHeader from 'components/pieces/SectionHeader'
import Button from 'components/pieces/Button'
import ValueOption from '../ValueOption'
import { useState } from 'react'
import ValueCard from '../ValueCard'
import gaEvent from 'utils/analytics'
import { useReducedMotionQuery } from 'utils/useReducedMotionQuery'

const Wellbeing = () => {
  const [selectedValues, setSelectedValues] = useState([])
  const [showResults, setShowResults] = useState(false)
  const reducedMotion = useReducedMotionQuery()
  const { t } = useTranslation('wellbeing', 'common')
  const values = t('values', { returnObjects: true })
  const ctas = t('ctas', { returnObjects: true })

  const toggleSelectedValue = (value) => {
    if (selectedValues.includes(value.title)) {
      setSelectedValues(
        selectedValues.filter((valueTitle) => valueTitle !== value.title)
      )
    } else if (selectedValues.length < 3) {
      setSelectedValues([...selectedValues, value.title])
    }
  }

  const containerVariant = {
    hidden: { opacity: reducedMotion ? 1 : 0, y: 0 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        staggerChildren: reducedMotion ? 0 : 0.03,
      },
    },
  }

  const item = {
    hidden: { opacity: 0, y: reducedMotion ? 0 : 50 },
    show: { opacity: 1, y: 0, transition: { ease: 'easeOut' } },
  }

  const resultsContainerVariant = {
    hidden: { opacity: 0, y: 0 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        staggerChildren: reducedMotion ? 0 : 0.1,
        ease: 'easeOut',
      },
    },
  }

  const resultItem = {
    hidden: { opacity: 0, y: 50 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        ease: 'easeOut',
      },
    },
  }

  const getSelectedValueIndex = (value) => {
    const index = selectedValues.indexOf(value.title)

    if (index === -1) {
      return null
    }

    return index + 1
  }
  const descriptionText = showResults
    ? t('description_before_selection')
    : t('description_after_selection')

  return (
    <Container className={cn(styles.container)}>
      <Grid className={styles.grid}>
        <div className={styles.header}>
          <SectionHeader
            eyebrow={t('eyebrow')}
            title={t('title')}
            description={descriptionText}
          />
          {!showResults && (
            <AnimatePresence initial={reducedMotion ? false : true}>
              <motion.ul
                variants={containerVariant}
                className={styles.valueList}
                initial="hidden"
                animate="show"
                role="menu">
                {values?.map((value) => (
                  <motion.li role="menuitem" variants={item} key={value.title}>
                    <ValueOption
                      key={value.title}
                      value={value}
                      activeIndex={getSelectedValueIndex(value)}
                      onClick={toggleSelectedValue}
                    />
                  </motion.li>
                ))}
                <motion.li variants={item}>
                  <Button
                    fullWidth
                    aria={t('ada:wellbeing.item.aria')}
                    onClick={() => {
                      setShowResults(true)
                      gaEvent('Wellbeing', 'Click', `Let's go`)
                    }}
                    className={cn(styles.valuesSubmit, {
                      [styles.disabled]: selectedValues.length < 3,
                    })}>
                    {t('common:learn_more')}
                  </Button>
                </motion.li>
              </motion.ul>
            </AnimatePresence>
          )}
        </div>
      </Grid>
      <AnimatePresence>
        {showResults && (
          <motion.div
            variants={resultsContainerVariant}
            initial="hidden"
            animate="show">
            <motion.div variants={resultItem}>
              <Grid>
                <motion.div className={styles.linksWrapper}>
                  {ctas?.map((cta, j) => (
                    <Button
                      key={`wellbeing-cta-${j}`}
                      link={cta?.cta_link}
                      gaEventData={[
                        'Wellbeing',
                        'Click',
                        'Content Link',
                        cta?.cta_text,
                      ]}
                      type="outlineReversedWhite"
                      className={styles.cta}>
                      {cta?.cta_text}
                    </Button>
                  ))}
                </motion.div>
              </Grid>
            </motion.div>
            <motion.div variants={resultItem}>
              <Grid>
                <div className={styles.cardWrapper}>
                  {selectedValues.map((value, i) => {
                    return <ValueCard value={value} key={i} />
                  })}
                  <div className={styles.backButton}>
                    <Button onClick={() => setShowResults(false)}>
                      {t('common:back')}
                    </Button>
                  </div>
                </div>
              </Grid>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </Container>
  )
}

export default Wellbeing

Wellbeing.propTypes = {
  events: PropTypes.array,
}
