import styles from './index.module.scss'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { motion, useAnimation, useReducedMotion } from 'framer-motion'
import PropTypes from 'prop-types'
import cn from 'classnames'
import image1 from './images/image1.png'
import image2 from './images/image2.png'
import image3 from './images/image3.png'
import image4 from './images/image4.png'
import image5 from './images/image5.png'
import elipse1 from './images/elipse-1.png'
import { ReactComponent as PlayButton } from 'assets/images/play-blue.svg'
import { ReactComponent as PauseButton } from 'assets/images/pause.svg'
import gsap from 'gsap'
import MotionPathPlugin from 'gsap/MotionPathPlugin'
import useStore from 'components/layout/_Layout/introStore'
import Button from '../Button'
import { useDocumentSize } from 'utils/useDocumentSize'
import { useTranslation } from 'react-i18next'
import TranscriptButton from '../TranscriptButton'
import { useIntroHasPlayed } from 'utils/useIntroHasPlayed'

const animDuration = 14

const IntroAnimation = () => {
  const { t } = useTranslation('global', 'ada')
  const [imgScale, setImgeScale] = useState(null)
  const [activeText, setActiveText] = useState(null)
  const [isPaused, setIsPaused] = useState(null)
  const setIntroComplete = useStore((state) => state.setIntroComplete)
  const documentSize = useDocumentSize()
  const containerRef = useRef()
  const framerControls = useAnimation()
  const skipIntro = useIntroHasPlayed()
  const reducedMotion = useReducedMotion()

  const masterTimeline = useMemo(() => {
    return gsap.timeline()
  }, [])

  useEffect(() => {
    framerControls.start({ pathLength: 1 })
  }, [framerControls])

  const getEl = useMemo(() => {
    return gsap.utils.selector(containerRef)
  }, [])

  useEffect(() => {
    gsap.registerPlugin(MotionPathPlugin)
  }, [])

  useEffect(() => {
    // Pause and resume logic
    if (typeof isPaused !== 'boolean') return
    const elapsedTime = masterTimeline.time()
    if (isPaused) {
      masterTimeline.pause()
      framerControls.stop()
    } else {
      masterTimeline.play()
      framerControls.start({
        pathLength: 1,
        transition: { duration: animDuration - elapsedTime, ease: 'easeOut' },
      })
    }
  }, [isPaused, masterTimeline, framerControls, getEl])

  useEffect(() => {
    if (documentSize <= 480) {
      setImgeScale(0.4)
      return
    }
    setImgeScale(0.5)
  }, [documentSize])

  const completeAnimation = useCallback(() => {
    // Undo body styling
    if (!document?.body) return
    document.body.style.overflow = 'auto'
    document.body.style.height = '100%'
    if (containerRef.current) {
      containerRef.current.style.display = 'none'
    }
    // Make sure user only sees intro once per session
    setIntroComplete()
    sessionStorage.setItem('intro-complete', true)
  }, [setIntroComplete])

  // Unlock scroll
  useEffect(() => {
    if (skipIntro || reducedMotion) {
      setTimeout(() => {
        completeAnimation()
      }, 10)
    }
  }, [skipIntro, completeAnimation, reducedMotion])

  const main = useCallback(() => {
    //SVG
    const svg = getEl('#line-svg')[0]
    const circle = getEl('#svg-circle')[0]
    const path = getEl('#svg-path')[0]
    const container = containerRef.current

    // Anim
    let tl = gsap.timeline({ ease: 'easeOut' })
    return tl
      .to(
        container,
        {
          backgroundPositionX: '100%',
          duration: animDuration,
        },
        'main'
      )
      .fromTo(
        circle,
        {
          opacity: 0,
        },
        { opacity: 1, duration: 1 },
        'main+=1'
      )
      .to(
        circle,
        {
          motionPath: {
            path: path,
            align: path,
            alignOrigin: [0.5, 0.5],
          },
          duration: animDuration,
        },
        'main+=1'
      )
      .fromTo(
        svg,
        {
          y: '-15%',
          x: '10%',
        },
        {
          x: '-75%',
          y: '-20%',
          duration: animDuration,
          onComplete: () => {
            setActiveText(0)
            completeAnimation()
          },
        },
        'main'
      )
  }, [getEl, completeAnimation])

  const begining = useCallback(() => {
    setActiveText(0)
    let tl = gsap.timeline({ ease: 'back.out(1.7)', duration: 0.75 })
    const img1 = getEl('#img-1')[0]
    const img2 = getEl('#img-2')[0]
    const title1 = getEl('#title-1')[0]
    return tl
      .fromTo(
        img1,
        { opacity: 0, scale: 0 },
        { opacity: 1, scale: imgScale },
        'intro'
      )

      .fromTo(
        img2,
        { opacity: 0, scale: 0 },
        { opacity: 1, scale: imgScale },
        'intro+=0.25'
      )
      .fromTo(
        title1,
        { y: 70, opacity: 0 },
        { opacity: 1, y: 0, duration: 0.4, onComplete: () => setActiveText(1) },
        'intro+=0.45'
      )
  }, [getEl, imgScale])

  const middle = useCallback(() => {
    let tl = gsap.timeline({ ease: 'back.out(1.7)', duration: 0.75 })
    const img3 = getEl('#img-3')[0]
    const img4 = getEl('#img-4')[0]
    const elp1 = getEl('#elipse-1')[0]

    const title2 = getEl('#title-2')[0]
    return tl
      .fromTo(
        img3,
        { opacity: 0, scale: 0 },
        { opacity: 1, scale: imgScale },
        'middle'
      )
      .fromTo(
        elp1,
        { opacity: 0, scale: 0 },
        { opacity: 0.3, scale: imgScale },
        'middle'
      )
      .fromTo(
        img4,
        { opacity: 0, scale: 0 },
        { opacity: 1, scale: imgScale },
        'middle+=0.25'
      )
      .fromTo(
        title2,
        { y: 70, opacity: 0 },
        { opacity: 1, y: 0, duration: 0.4, onComplete: () => setActiveText(2) },
        'middle+=0.45'
      )
  }, [getEl, imgScale])

  const end = useCallback(() => {
    let tl = gsap.timeline({ ease: 'back.out(1.7)', duration: 0.75 })
    const img5 = getEl('#img-5')[0]
    const elp2 = getEl('#elipse-2')[0]

    const title3 = getEl('#title-3')[0]
    return tl
      .fromTo(
        img5,
        { opacity: 0, scale: 0 },
        { opacity: 1, scale: imgScale },
        'end'
      )
      .fromTo(
        elp2,
        { opacity: 0, scale: 0.6, y: -40 },
        { opacity: 0.1, scale: 0.7, y: 0 },
        'end'
      )
      .fromTo(
        title3,
        { opacity: 0, scale: 0.75 },
        { opacity: 1, scale: 1 },
        'end+=0.5'
      )
  }, [getEl, imgScale])

  const fadeOut = useCallback(() => {
    let tl = gsap.timeline()
    const container = containerRef.current
    return tl.to(container, { opacity: 0, duration: 2 })
  }, [])

  useEffect(() => {
    if (!imgScale) return

    masterTimeline
      .add(main(), 'first')
      .add(begining(), `first`)
      .add(begining().reverse(), 'first+=3.5')
      .add(middle(), 'first+=3.5')
      .add(middle().reverse(), 'first+=8.5')
      .add(end(), 'first+=8.5')
      .add(end().reverse(), 'first+=13')
      .add(fadeOut(), 'first+=13')
  }, [getEl, begining, main, middle, end, fadeOut, imgScale, masterTimeline])

  return (
    <>
      <div
        style={{
          backgroundSize: '600%',
        }}
        ref={containerRef}
        className={cn(styles.container, 'dark')}>
        <svg
          width="300%"
          height="100%"
          viewBox="0 0 2830 658"
          fill="none"
          id="line-svg"
          className={styles.line}
          xmlns="http://www.w3.org/2000/svg">
          <motion.path
            id="svg-path"
            initial={{ pathLength: 0 }}
            animate={framerControls}
            transition={{ duration: animDuration, ease: 'easeOut' }}
            d="M1 598C96 525.5 351.296 372.682 639.5 421C893 463.5 924 606.5 1146.5 646.5C1369 686.5 1555.38 597.56 1659.5 545.5C1786.5 482 2094.86 168.581 2371 327C2591.5 453.5 2744.5 338 2829.5 1"
            stroke="url(#paint0_linear_1610_65719)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1610_65719"
              x1="249"
              y1="538"
              x2="3071"
              y2="280"
              gradientUnits="userSpaceOnUse">
              <stop offset="0.142319" stopColor="#012E76" />
              <stop offset="1" stopColor="#FBFBFB" />
            </linearGradient>
          </defs>
          <circle cx="10" cy="10" r="10" fill="#C74289" id="svg-circle" />
        </svg>
        {/* First group of animation elements */}
        <img
          className={cn(styles.image1, styles.image)}
          src={image1}
          id="img-1"
          alt=""
          animate={{ scale: [0, 0.5, 0.5, 0.2], opacity: [0, 1, 1, 0] }}
          transition={{
            ease: 'backOut',
            duration: 2,
            delay: 0.2,
            times: [0, 0.2, 0.8, 1],
          }}
        />
        <img
          className={cn(styles.image2, styles.image)}
          animate={{ scale: [0, 0.5, 0.5, 0.2], opacity: [0, 1, 1, 0] }}
          id="img-2"
          transition={{
            ease: 'backOut',
            duration: 2.2,
            delay: 0.75,
            times: [0, 0.2, 0.8, 1],
          }}
          src={image2}
          alt=""
        />
        <h2
          aria-live={activeText === 0 ? 'polite' : 'off'}
          id="title-1"
          className={cn(styles.text, styles.text1)}>
          {t('intro.1')}
        </h2>

        <img
          className={cn(styles.elipse2, styles.image)}
          src={elipse1}
          alt=""
          id="elipse-1"
        />

        {/* Second group of animation elements */}

        <img
          className={cn(styles.image3, styles.image)}
          src={image3}
          id="img-3"
          alt=""
        />
        <h2
          aria-live={activeText === 1 ? 'polite' : 'off'}
          id="title-2"
          className={cn(styles.text, styles.text2)}>
          {t('intro.2')}
        </h2>
        <img
          className={cn(styles.image4, styles.image)}
          src={image4}
          id="img-4"
          alt=""
        />

        <img
          className={cn(styles.elipse1, styles.image)}
          src={elipse1}
          id="elipse-2"
          alt=""
        />
        {/* Third group of animation elements */}
        <h2
          aria-live={activeText === 2 ? 'polite' : 'off'}
          id="title-3"
          className={cn(styles.text, styles.text3)}>
          {t('intro.3')}
        </h2>
        <img
          className={cn(styles.image5, styles.image)}
          src={image5}
          id="img-5"
          alt=""
        />
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          transition={{ ease: 'backOut', duration: 0.5 }}
          animate={{ opacity: 1, y: 0 }}>
          <button
            aria-label={isPaused ? t('ada:intro.play') : t('ada:intro.pause')}
            className={cn(styles.playPauseButton, { [styles.play]: isPaused })}
            onClick={() => setIsPaused(!isPaused)}>
            {isPaused ? <PlayButton /> : <PauseButton />}
          </button>
        </motion.div>
        <div className={styles.bottomButtonWrapper}>
          <TranscriptButton
            className={styles.transcriptButton}
            from="intro"
            onAction={() => {
              setIsPaused(true)
            }}
            offAction={() => {
              setIsPaused(false)
            }}
          />
          <Button
            className={styles.skipButton}
            onClick={() => {
              completeAnimation()
            }}>
            {t('intro.skip_intro')}
          </Button>
        </div>
      </div>
    </>
  )
}

export default IntroAnimation

IntroAnimation.propTypes = {
  interrupted: PropTypes.bool,
  reducedMotion: PropTypes.bool,
}
