/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types'
import styles from './index.module.scss'
import cn from 'classnames'
import Video from 'components/pieces/Video'
import Button from 'components/pieces/Button'
import Carousel from '../_Index'
import sectionHeaderShape from 'shapes/sectionHeader'
import { scrollToSection } from 'utils/scrollToSection'
import ResourceCTAs from '../CTAs'
import { useTranslation } from 'react-i18next'
import SectionHeader from 'components/pieces/SectionHeader'
import { parseHtml } from 'utils/parseHtml'

const CarouselModal = ({
  item,
  index,
  setNextPhaseId,
  showNavButtons = true,
  sectionHeaderCopy,
  sectionId,
  setSelectedIndex,
}) => {
  const { t } = useTranslation('common')
  const { image, topic, partner, logo, video, resources, ctas, description } =
    item

  return (
    <div className={cn(styles.modal, { [styles.video]: video })} key={index}>
      {!item?.resources?.length ? (
        <div className={cn(styles.inner, { [styles.video]: video })}>
          <div className={styles.header}>
            <SectionHeader eyebrow={sectionHeaderCopy?.eyebrow} title={topic} />
            {ctas && <ResourceCTAs parentTitle={topic} ctas={ctas} />}{' '}
          </div>

          <div className={styles.mediaWrapper}>
            <Video videoUrl={video} videoPoster={image} autoplay />
            {description && (
              <div className={styles.description}>{parseHtml(description)}</div>
            )}
          </div>
        </div>
      ) : (
        <div className={styles.inner}>
          <Carousel
            items={resources}
            type="resources"
            partner={partner}
            logo={logo}
            sectionHeaderCopy={sectionHeaderCopy}
            ctas={ctas}
          />
        </div>
      )}

      {showNavButtons && (
        <div className={styles.buttons}>
          <Button
            onClick={() => {
              setNextPhaseId(-1)
              setSelectedIndex(-1)
              scrollToSection(sectionId)
            }}>
            {t('back')}
          </Button>
        </div>
      )}
    </div>
  )
}

CarouselModal.propTypes = {
  item: PropTypes.shape({
    image: PropTypes.string,
    topic: PropTypes.string,
    video: PropTypes.string,
    partner: PropTypes.string,
    logo: PropTypes.string,
    resources: PropTypes.array,
    ctas: PropTypes.array,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
  index: PropTypes.number,
  setNextPhaseId: PropTypes.func,
  setSelectedIndex: PropTypes.func,
  total: PropTypes.number,
  showNavButtons: PropTypes.bool,
  sectionHeaderCopy: sectionHeaderShape,
  sectionId: PropTypes.string,
}
export default CarouselModal
