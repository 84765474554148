import { ReactComponent as Email } from 'assets/images/email.svg'
import { ReactComponent as FacebookShare } from 'assets/images/facebook-outline.svg'
import { ReactComponent as TwitterShare } from 'assets/images/twitter-outline.svg'
import { ReactComponent as Link } from 'assets/images/link.svg'
import { ReactComponent as Copy } from 'assets/images/copy.svg'
import { ReactComponent as LinkedinShare } from 'assets/images/linkedin-outline.svg'
import { handleSocialShare } from 'utils/handleSocialShare'

export const SOCIAL_SHARE = {
  copy: {
    icon: <Copy />,
  },
  link: {
    icon: <Link />,
  },
  email: {
    icon: <Email />,
    onAction: ({ subject, body }) => {
      handleSocialShare('email', { email: { subject, body } })
    },
  },
  facebook: {
    icon: <FacebookShare />,
    onAction: () => {
      handleSocialShare('facebook')
    },
  },
  twitter: {
    icon: <TwitterShare />,
    onAction: ({ text, via, hashtag }) => {
      handleSocialShare('twitter', { twitter: { text, via, hashtag } })
    },
  },
  linkedin: {
    icon: <LinkedinShare />,
    onAction: () => {
      handleSocialShare('linkedin')
    },
  },
}

export const FREE_EVENTS_SOCIAL_SHARE = ['twitter', 'facebook', 'email', 'link']

export const HEADER_SHARES = [
  'copy',
  'email',
  'facebook',
  'twitter',
  'linkedin',
]
