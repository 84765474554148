import PropTypes from 'prop-types'
import styles from './index.module.scss'
import cn from 'classnames'
import Image from 'components/pieces/Image'
import Button from 'components/pieces/Button'
import { parseHtml } from 'utils/parseHtml'
import { forwardRef } from 'react'

const IconCard = forwardRef(
  (
    {
      item,
      index,
      type,
      onClick,
      inView,
      children,
      Bg,
      iconElement,
      className,
      titleClass,
    },
    ref
  ) => {
    const { logo, title, description, cta_text, cta_link, logoLayout } =
      item || {}

    return (
      <div
        ref={ref}
        className={cn(styles[type], { [styles.inview]: inView }, className)}
        key={index}>
        <div className={styles.inner}>
          <div className={styles.top}>
            {logo && (
              <div className={styles.imageWrapper}>
                <Image
                  image={{ url: logo }}
                  alt={item.name}
                  width={1280}
                  height={907}
                  layout={logoLayout ?? 'intrinsic'}
                />
              </div>
            )}

            {iconElement && iconElement}

            <div className={styles.content}>
              {title && (
                <div className={cn(styles.title, titleClass)}>{title}</div>
              )}
              {description && (
                <div className={styles.description}>
                  {typeof description === 'object'
                    ? description
                    : parseHtml(description)}
                </div>
              )}
            </div>
          </div>

          {(children || (cta_text && (cta_link || onClick))) && (
            <div className={styles.bottom}>
              {children}
              {cta_text && (cta_link || onClick) && (
                <Button
                  type="whitebg"
                  className={styles.button}
                  link={cta_link}
                  fullWidth
                  tabIndex={inView ? '0' : '-1'}
                  aria-hidden={inView ? 'false' : 'true'}
                  onClick={onClick}
                  thin>
                  {cta_text}
                </Button>
              )}
            </div>
          )}

          <div className={styles.bg} aria-hidden="true">
            {Bg}
          </div>
        </div>
      </div>
    )
  }
)

IconCard.displayName = 'IconCard'

IconCard.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    logo: PropTypes.string,
    logoLayout: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    cta_text: PropTypes.string,
    cta_link: PropTypes.string,
  }),
  index: PropTypes.number,
  selectedIndex: PropTypes.number,
  type: PropTypes.string,
  onClick: PropTypes.func,
  inView: PropTypes.bool,
  children: PropTypes.node,
  Bg: PropTypes.node,
  iconElement: PropTypes.node,
  className: PropTypes.string,
  titleClass: PropTypes.string,
}
export default IconCard
