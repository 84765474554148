import { DismissButton, FocusScope, useOverlay } from 'react-aria'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './index.module.scss'

const Popover = ({ className = '', popoverRef, isOpen, onClose, children }) => {
  const { overlayProps } = useOverlay(
    {
      isOpen,
      onClose,
      shouldCloseOnBlur: true,
      isDismissable: true,
    },
    popoverRef
  )

  return (
    <FocusScope restoreFocus>
      <div
        {...overlayProps}
        ref={popoverRef}
        className={cn(className, styles.container)}>
        {children}
        <DismissButton onDismiss={onClose} />
      </div>
    </FocusScope>
  )
}

export default Popover

Popover.propTypes = {
  className: PropTypes.string,
  popoverRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
}
