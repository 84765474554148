import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export default function ScrollToTop() {
  const { pathname, hash } = useLocation() || {}

  useEffect(() => {
    if (!hash || hash === '') {
      setTimeout(() => {
        window?.scrollTo(0, 0)
      }, 300)
    }
  }, [pathname, hash])

  return null
}
