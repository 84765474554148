import PropTypes from 'prop-types'
import styles from './index.module.scss'
import cn from 'classnames'
import ReactTooltip from 'react-tooltip'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { parseHtml } from 'utils/parseHtml'

export default function TranscriptButton({
  onAction,
  offAction,
  from,
  className,
}) {
  const [hideTranscript, setHideTranscript] = useState(false)

  const skipIntro =
    process.env.REACT_APP_SKIP_INTRO === 'true' ||
    process.env.REACT_APP_SKIP_INTRO === true

  const { t } = useTranslation('global')
  const fullTextCopy = `<p><span>${t('intro.1')}</span>&ensp;<span>${t(
    'intro.2'
  )}</span>&ensp;<span>${t('intro.3')}</span></p>`

  useEffect(() => {
    const toggleTranscript = () => {
      if (window?.scrollY > 20) {
        !hideTranscript && setHideTranscript(true)
      } else {
        hideTranscript && setHideTranscript(false)
      }
    }

    window.addEventListener('scroll', toggleTranscript)

    return () => {
      window.removeEventListener('scroll', toggleTranscript)
    }
  }, [hideTranscript])

  return (
    <>
      {!skipIntro && (
        <AnimatePresence>
          {!hideTranscript && (
            <motion.div
              className={cn(styles.container, className, styles[from])}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}>
              <button
                onMouseEnter={onAction}
                onClick={onAction}
                onMouseLeave={offAction}
                onBlur={offAction}
                data-for={`intro-transcript-${from}`}
                data-tip
                data-iscapture="true"
                className={cn(styles.introTranscriptBtn)}>
                {from === 'intro'
                  ? t('intro.transcript')
                  : t('intro.intro_transcript')}
              </button>

              <ReactTooltip
                overridePosition={() => ({
                  left: window?.innerWidth > 768 ? 40 : 16,
                  top: window?.innerHeight - 210,
                })}
                id={`intro-transcript-${from}`}
                event="mouseenter focus click"
                eventOff="mouseleave blur"
                multiline={true}>
                {parseHtml(fullTextCopy)}
              </ReactTooltip>
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </>
  )
}

TranscriptButton.propTypes = {
  onAction: PropTypes.func,
  offAction: PropTypes.func,
  from: PropTypes.oneOf(['intro', 'home']),
  className: PropTypes.string,
}
