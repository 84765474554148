import { ReactComponent as Facebook } from 'assets/images/facebook.svg'
import { ReactComponent as Twitter } from 'assets/images/twitter.svg'
import { ReactComponent as Instagram } from 'assets/images/instagram.svg'
import { ReactComponent as Youtube } from 'assets/images/youtube.svg'
// import { ReactComponent as CareBg } from 'assets/images/care-bg.svg'
import { ReactComponent as LyftBg } from 'assets/images/lyft-bg.svg'
import { ReactComponent as DunkinBg } from 'assets/images/dunkin-bg.svg'

export const GLOBAL_SOCIALS = [
  { Icon: Twitter, link: 'https://twitter.com/indeed', label: 'twitter' },
  {
    Icon: Facebook,
    link: 'https://www.facebook.com/Indeed/',
    label: 'facebook',
  },
  {
    Icon: Instagram,
    link: 'https://www.instagram.com/indeedworks/',
    label: 'instagram',
  },
  {
    Icon: Youtube,
    link: 'https://www.youtube.com/c/Indeed/videos',
    label: 'youtube',
  },
]

export const WORK_ESSENTIAL_RESOURCES = [
  // {
  //   name: 'Care.com',
  //   logo: care,
  //   color: '#1F5F7B',
  //   cta_text: 'Learn more',
  //   cta_link: 'https://care.com',
  //   background_svg: <CareBg />,
  //   background_svg_bottom: 0,
  //   background_svg_right: 0,
  // },
  {
    name: 'Lyft',
    color: '#C74289',
    background_svg: <LyftBg />,
    background_svg_bottom: '20%',
    background_svg_right: 0,
  },
  {
    name: 'Dunkin',
    color: '#A54411',
    background_svg: <DunkinBg />,
    background_svg_bottom: 0,
    background_svg_right: 0,
  },
]
