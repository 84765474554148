import PropTypes from 'prop-types'
import styles from './index.module.scss'
import cn from 'classnames'

const RichTextField = ({ text, className }) => {
  const classes = [styles.description]
  if (className) {
    classes.push(className)
  }

  return (
    <div className={cn(classes)}>
      {text && typeof text === 'string' ? <p>{text}</p> : text}
    </div>
  )
}

RichTextField.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
}
export default RichTextField
