import { useRef } from 'react'
import { useComboBox, useFilter } from 'react-aria'
import { useComboBoxState } from 'react-stately'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './index.module.scss'
import Popover from '../Popover'
import ListBox from '../ListBox'

const ComboBox = ({
  className = '',
  labelClassName = '',
  inputWrapperClassName = '',
  inputClassName = '',
  popoverClassName = '',
  listboxClassName = '',
  itemClassName = '',
  icon,
  ...props
}) => {
  const { contains } = useFilter({ sensitivity: 'base' })
  const state = useComboBoxState({ ...props, defaultFilter: contains })

  const inputRef = useRef(null)
  const listBoxRef = useRef(null)
  const popoverRef = useRef(null)

  const { inputProps, labelProps, listBoxProps } = useComboBox(
    {
      ...props,
      inputRef,
      listBoxRef,
      popoverRef,
    },
    state
  )

  return (
    <div className={cn(className, styles.container)}>
      <label className={labelClassName} {...labelProps}>
        {props.label}
      </label>
      <div className={inputWrapperClassName}>
        {icon}
        <input
          {...inputProps}
          ref={inputRef}
          className={cn(inputClassName, styles.input)}
        />
        {state.isOpen && (
          <Popover
            className={popoverClassName}
            popoverRef={popoverRef}
            isOpen={state.isOpen}
            onClose={state.close}>
            <ListBox
              {...listBoxProps}
              listBoxRef={listBoxRef}
              state={state}
              className={listboxClassName}
              itemClassName={itemClassName}
            />
          </Popover>
        )}
      </div>
    </div>
  )
}

export default ComboBox

ComboBox.propTypes = {
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  inputWrapperClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  popoverClassName: PropTypes.string,
  listboxClassName: PropTypes.string,
  itemClassName: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.node,
}
