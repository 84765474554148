export const MODULES = [
  { name: 'coalition', handle: 'spotlight' },
  {
    name: 'job board',
    handle: 'jobs',
  },
  {
    name: 'impact',
    handle: 'impact',
  },
  {
    name: 'IRL events',
    handle: 'events',
  },
  {
    name: 'virtual events',
    handle: 'virtual',
  },
  {
    name: 'interview skills',
    handle: 'tips',
  },
  {
    name: 'ask the right questions',
    handle: 'questions',
  },
  {
    name: 'sweepstakes',
    handle: 'sweepstakes',
  },
  {
    name: 'employer hub',
    handle: 'employers',
  },
  {
    name: 'wellbeing',
    handle: 'wellbeing',
  },
  {
    name: 'work essentials',
    handle: 'essentials',
  },
  {
    name: 'job progression',
    handle: 'progression',
  },
  { name: 'see more', handle: 'see-more' },
  { name: 'FAQ', handle: 'FAQ' },
  { name: 'impact', handle: 'impact' },
  { name: 'partners', handle: 'partners' },
]

export const VIEWS = [
  {
    view: '',
    modules: [
      'spotlight',
      'impact',
      'jobs',
      'events',
      'virtual',
      'tips',
      'questions',
      'essentials',
      'wellbeing',
      'sweepstakes',
      'employers',
      'progression',
      'partners',
      'FAQ',
    ],
  },
  {
    view: 'start interviewing',
    modules: [
      'spotlight',
      'impact',
      'jobs',
      'events',
      'virtual',
      'tips',
      'questions',
      'essentials',
      'sweepstakes',
      'employers',
      'progression',
      'see-more',
      'FAQ',
    ],
  },
  {
    view: 'start my career',
    modules: [
      'spotlight',
      'jobs',
      'events',
      'virtual',
      'wellbeing',
      'tips',
      'progression',
      'essentials',
      'sweepstakes',
      'employers',
      'see-more',
      'FAQ',
    ],
  },
  {
    view: 'pivot my career',
    modules: [
      'spotlight',
      'jobs',
      'events',
      'virtual',
      'progression',
      'wellbeing',
      'essentials',
      'sweepstakes',
      'employers',
      'see-more',
      'FAQ',
    ],
  },
  {
    view: 're-enter the workforce',
    modules: [
      'spotlight',
      'jobs',
      'events',
      'virtual',
      'tips',
      'wellbeing',
      'essentials',
      'sweepstakes',
      'employers',
      'progression',
      'see-more',
      'FAQ',
    ],
  },
  {
    view: 'get inspired',
    modules: [
      'spotlight',
      'events',
      'wellbeing',
      'tips',
      'questions',
      'essentials',
      'sweepstakes',
      'employers',
      'see-more',
      'FAQ',
    ],
  },
  {
    view: 'events over',
    modules: [
      // 'impact',
      'jobs',
      'virtual',
      'tips',
      'wellbeing',
      'events',
      'progression',
      'FAQ',
    ],
  },
]
