import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import Container from '../../layout/Container'
import Grid from '../../layout/Grid'
import SectionHeader from 'components/pieces/SectionHeader'
// import { useEffect, useRef } from 'react'
// import { useThemeContext } from 'components/layout/_Layout/themeContext'
import { parseHtml } from 'utils/parseHtml'
import Button from 'components/pieces/Button'

// import pym from 'pym.js'

const JobList = () => {
  const isPostEvents = process.env.REACT_APP_STATE === 'events_over'
  // const iframeRef = useRef()
  const { t } = useTranslation('jobList')
  // const { i18n } = useTranslation()
  // const { theme } = useThemeContext() || {}

  // let url =
  //   theme === 'dark'
  //     ? 'https://r2w-dark.yourjobboard.io?'
  //     : 'https://r2w-light.yourjobboard.io?'

  // useEffect(() => {
  //   // console.log(pym, iframeRef.current)

  //   const urlParamsString = document.location.search.substring(1)
  //   if (urlParamsString !== '') {
  //     const urlParams = JSON.parse(
  //       '{"' +
  //         decodeURI(urlParamsString)
  //           .replace(/"/g, '\\"')
  //           .replace(/&/g, '","')
  //           .replace(/=/g, '":"') +
  //         '"}'
  //     )
  //     const expectedParams = [
  //       'utm_source',
  //       'utm_medium',
  //       'utm_campaign',
  //       'from',
  //       'ikw',
  //       'isid',
  //     ]

  //     expectedParams.forEach((param) => {
  //       if (param in urlParams) {
  //         // eslint-disable-next-line react-hooks/exhaustive-deps
  //         url += '&' + param + '=' + urlParams[param]
  //       }
  //     })
  //   }
  //   if (i18n.language === 'es') {
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //     url += '&bilingual=true'
  //   }

  //   new pym.Parent('job_board_parent', url, {})
  // }, [url, i18n.language])

  return (
    <Container className={cn(styles.container)}>
      <Grid className={styles.grid}>
        <div className={styles.header}>
          <SectionHeader
            eyebrow={isPostEvents ? t('post.eyebrow') : t('eyebrow')}
            title={parseHtml(isPostEvents ? t('post.title') : t('title'))}
            description={
              isPostEvents ? t('post.description') : t('description')
            }
          />

          {t('post.cta_text') && t('post.cta_link') && (
            <Button link={t('post.cta_link')}> {t('post.cta_text')}</Button>
          )}
          {/* <div id="job_board_parent" ref={iframeRef}></div> */}
        </div>
      </Grid>
    </Container>
  )
}

export default JobList

JobList.propTypes = {
  events: PropTypes.array,
}
