import Button from 'components/pieces/Button'
import { useSeekerStatusContext } from 'components/layout/_Layout/seekerStatusContext'
import gaEvent from 'utils/analytics'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'

const SeeMore = () => {
  const { setStatus } = useSeekerStatusContext() || {}
  const { t } = useTranslation('common')
  return (
    <div className={styles.cta}>
      <Button
        onClick={() => {
          setStatus('')
          gaEvent('IRL Events', 'Click', 'View All Resources')
        }}>
        {t('common:view_all_resources')}
      </Button>
    </div>
  )
}

export default SeeMore
