import PropTypes from 'prop-types'
import Container from 'components/layout/Container'
import Grid from 'components/layout/Grid'
import styles from './index.module.scss'
import InViewReveal from 'components/pieces/InViewReveal'
import { WORK_ESSENTIAL_RESOURCES } from 'constant'
import SectionHeader from 'components/pieces/SectionHeader'
import { useLyft } from '../lib'
import LocationCards from '../LocationCards'
import BrandCards from '../BrandCards'
import Loader from 'components/pieces/Loader'
import { useTranslation } from 'react-i18next'
import { useLayoutEffect, useRef, useState } from 'react'
import Modal from 'components/pieces/Modal'
import FAQ from 'components/slices/FAQ'

const WorkEssentials = () => {
  const { t } = useTranslation('essentials')
  const items = t('brands', { returnObjects: true })
  const _items = items.map((item, index) => ({
    ...item,
    ...WORK_ESSENTIAL_RESOURCES[index],
  }))
  const { locations, setLocations, loading, fetchLocations } = useLyft() || {}
  const [modalOpen, setModalOpen] = useState(false)
  // active brand item
  const [active, setActive] = useState()
  const questions = active?.faq
  const locationCardRef = useRef(null)

  useLayoutEffect(() => {
    if (!loading && active && locations.length > 0 && locationCardRef.current) {
      setTimeout(() => {
        locationCardRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        })
      }, 150)
      // locationCardRef.current.querySelector('a, button')?.focus()
    }
  }, [loading, active, locations])

  return (
    <Container>
      <InViewReveal>
        <div>
          <Grid className={styles.grid}>
            <div className={styles.inner}>
              <SectionHeader
                eyebrow={t('eyebrow')}
                title={t('title')}
                description={t('description')}
              />

              {loading ? (
                <div className={styles.loading}>
                  <Loader />
                </div>
              ) : locations && locations?.length ? (
                <LocationCards
                  focusRef={locationCardRef}
                  active={active}
                  results={locations}
                  back={() => setLocations([])}
                />
              ) : (
                <BrandCards
                  items={_items}
                  onAction={(item, payload) => {
                    fetchLocations(payload)
                    setActive(item)
                  }}
                  setActive={setActive}
                  openModal={(item) => {
                    if (!modalOpen) {
                      setModalOpen(true)
                    }
                    setActive(item)
                  }}
                />
              )}
            </div>
          </Grid>
        </div>
      </InViewReveal>
      <Modal open={modalOpen} setOpen={setModalOpen}>
        <FAQ
          questions={questions}
          title={null}
          eyebrow={null}
          initActive={-1}
        />
      </Modal>
    </Container>
  )
}

WorkEssentials.propTypes = {
  items: PropTypes.array,
}
export default WorkEssentials
