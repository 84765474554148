import PropTypes from 'prop-types'

const imageShape = PropTypes.shape({
  width: PropTypes.number,
  height: PropTypes.number,
  url: PropTypes.string,
  alt: PropTypes.string,
})

export default imageShape
