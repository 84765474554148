/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types'
import styles from './index.module.scss'
import cn from 'classnames'
import Image from 'components/pieces/Image'
import imageShape from 'shapes/image'

const GalleryCard = ({ item, index, activeSlide = 0 }) => {
  return (
    <div
      className={cn(styles.slide, {
        [styles.selected]: activeSlide === index,
      })}
      key={index}>
      <div className={styles.image}>
        <Image layout="fill" image={item?.image} />
        {/* {subtitle && <div className={styles.subtitle}>{subtitle}</div>} */}
      </div>
    </div>
  )
}

GalleryCard.propTypes = {
  item: PropTypes.shape({
    image: imageShape,
  }),
  index: PropTypes.number,
  activeSlide: PropTypes.number,
  type: PropTypes.string,
}
export default GalleryCard
