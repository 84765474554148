import PropTypes from 'prop-types'
import styles from './index.module.scss'
import cn from 'classnames'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import imageShape from 'shapes/image'

const Image = ({
  image,
  alt,
  className,
  layout = 'intrinsic',
  round,
  lazy = true,
}) => {
  let ar = 1
  if (layout === 'responsive' && image?.width && image?.height) {
    ar = image?.width / image?.height
  }

  return (
    <div
      className={cn(styles.image, styles[layout], {
        [styles.round]: round,
      })}
      style={{
        '--pt': `${100 / ar}%`,
      }}>
      {lazy ? (
        <LazyLoadImage
          alt={alt || image.alt || ''}
          height={layout === 'intrinsic' ? image.height : undefined}
          src={image.url}
          width={layout === 'intrinsic' ? image.width : undefined}
          wrapperClassName={className}
          effect="blur"
        />
      ) : (
        <img alt={image.alt || ''} src={image.url} />
      )}
    </div>
  )
}

Image.propTypes = {
  image: imageShape,
  alt: PropTypes.string,
  className: PropTypes.string,
  layout: PropTypes.oneOf(['fill', 'intrinsic', 'responsive']),
  round: PropTypes.bool,
  objectFit: PropTypes.string,
  objectPosition: PropTypes.string,
  lazy: PropTypes.bool,
}
export default Image
