import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './index.module.scss'
import { useMenuItem } from '@react-aria/menu'
import { useRef, useState } from 'react'
import { mergeProps } from '@react-aria/utils'
import { useFocus } from '@react-aria/interactions'
import { useTranslation } from 'react-i18next'

const MenuItem = ({ item, state, onAction, onClose, active, index, total }) => {
  // Get props for the menu item element
  let ref = useRef()

  let { menuItemProps } = useMenuItem(
    {
      key: item.key,
      isDisabled: item.isDisabled,
      onAction,
      onClose,
    },
    state,
    ref
  )

  // Handle focus events so we can apply highlighted
  // style to the focused menu item
  let [isFocused, setFocused] = useState(false)
  let { focusProps } = useFocus({ onFocusChange: setFocused })
  const { t } = useTranslation('common')

  return (
    <li
      {...mergeProps(menuItemProps, focusProps)}
      ref={ref}
      aria-label={`${item.rendered}, ${
        active?.toLowerCase() === item.rendered.toLowerCase()
          ? t('selected')
          : t('not_selected')
      }, ${index + 1} of ${total}`}
      className={cn(styles.item, {
        [styles.focused]: isFocused,
        [styles.active]: item.key === active,
        [styles.hide]: item.key === '', // hide the 'all' option for hero job seeker status dropdown
      })}>
      {item.rendered}
    </li>
  )
}

MenuItem.propTypes = {
  item: PropTypes.any,
  state: PropTypes.object,
  onAction: PropTypes.func,
  onClose: PropTypes.func,
  active: PropTypes.string,
  index: PropTypes.number,
  total: PropTypes.number,
}
export default MenuItem
