import cn from 'classnames'
import { useThemeContext } from 'components/layout/_Layout/themeContext'
import PropTypes from 'prop-types'
import styles from './index.module.scss'
import { Link } from 'react-router-dom'
import { forwardRef, useRef } from 'react'
import gaEvent from 'utils/analytics'

const Button = forwardRef(
  (
    {
      aria,
      className,
      link,
      children,
      type = 'primary',
      round,
      fullWidth,
      onClick,
      action,
      linkTarget = '_blank',
      tabIndex = 0,
      thin,
      disabled,
      linkQueryString,
      styleOnly,
      gaEventData,
      ...args
    },
    ref
  ) => {
    const { theme } = useThemeContext() || {}
    const _buttonRef = useRef()
    let buttonRef = ref || _buttonRef

    const typeClass = Array.isArray(type)
      ? type.map((t) => styles[t])
      : [styles[type]]

    const classNames = cn(
      styles.button,
      styles[theme],
      ...typeClass,
      {
        [styles.fullWidth]: fullWidth,
        [styles.round]: round,
        [styles.thin]: thin,
      },
      className
    )

    let linkType = styleOnly ? 'none' : 'button'
    if (link) {
      linkType = 'internal'
    }
    if (link && link.indexOf('http') === 0) {
      linkType = 'external'
    }

    let parsedLink = link
    if (parsedLink && linkQueryString && linkQueryString !== '') {
      parsedLink = parsedLink.replace('[QUERY_PARAM]', linkQueryString)
    }

    return (
      <>
        {linkType === 'none' && (
          <div className={classNames} {...args}>
            {children}
          </div>
        )}

        {linkType === 'button' && (
          <button
            ref={buttonRef}
            tabIndex={tabIndex}
            aria-label={aria}
            className={classNames}
            onClick={(event) => {
              const click = action || onClick
              click && click(event)
              if (gaEventData) {
                gaEvent(...gaEventData)
              }

              buttonRef.current.blur()
            }}
            disabled={disabled}
            {...args}>
            {children}
          </button>
        )}

        {linkType === 'internal' && (
          <Link
            tabIndex={tabIndex}
            to={link}
            aria-label={aria}
            onClick={() => {
              if (gaEventData) {
                gaEvent(...gaEventData)
              }
            }}
            className={classNames}>
            {children}
          </Link>
        )}

        {linkType === 'external' && (
          <a
            ref={ref}
            tabIndex={tabIndex}
            href={parsedLink}
            onClick={(event) => {
              const click = action || onClick
              click && click(event)
              if (gaEventData) {
                gaEvent(...gaEventData)
              }
            }}
            target={linkTarget}
            aria-label={aria}
            className={classNames}
            {...args}>
            {children}
          </a>
        )}
      </>
    )
  }
)

Button.displayName = 'Button'

Button.propTypes = {
  aria: PropTypes.string,
  className: PropTypes.string,
  link: PropTypes.string,
  children: PropTypes.node,
  tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.oneOf([
    'primary',
    'whitebg',
    'bluebg',
    'mono',
    'reversed',
    'outline',
    'outlineReversed',
    'outlineReversedWhite',
  ]),
  fullWidth: PropTypes.bool,
  round: PropTypes.bool,
  thin: PropTypes.bool,
  onClick: PropTypes.func,
  linkTarget: PropTypes.oneOf(['_blank', '_self']),
  disabled: PropTypes.bool,
  linkQueryString: PropTypes.string,
  action: PropTypes.func,
  styleOnly: PropTypes.bool,
  gaEventData: PropTypes.array,
}
export default Button
