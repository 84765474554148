import { useState, useEffect } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

export const useInitSeekerStatus = () => {
  const { t } = useTranslation('hero')
  const options = t('job_seeking_statuses.options', { returnObjects: true })
  const [status, setStatus] = useState(null)

  // get theme from user setting or local storage
  useEffect(() => {
    const storedSearchStatus = localStorage.getItem('jobSeekingStatus')

    let storedStatus

    if (storedSearchStatus) {
      storedStatus = storedSearchStatus
    }

    setStatus(!_.isEmpty(storedStatus) ? storedStatus : options[0]?.value ?? '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // update the localStorage when job seeking status changes
  useEffect(() => {
    status && localStorage.setItem('jobSeekingStatus', status)
  }, [status])

  return [status, setStatus]
}
