import IconWrapper from 'components/pieces/IconWrapper'
import PropTypes from 'prop-types'
import styles from './index.module.scss'
import cn from 'classnames'
import { ReactComponent as Arrow } from 'assets/images/arrow-long.svg'
import { useTranslation } from 'react-i18next'

const CarouselButton = ({ type, enabled, contentType, onClick }) => {
  const { t } = useTranslation('ada')
  return (
    <div
      className={cn(
        styles.button,
        { [styles.disabled]: !enabled },
        styles[type],
        styles[contentType]
      )}
      aria-hidden={enabled ? 'false' : 'true'}>
      <IconWrapper
        type="outline"
        onClick={onClick}
        disabled={!enabled}
        aria={t('carousel.button', {
          direction:
            type === 'next' ? t('carousel.next') : t('carousel.previous'),
        })}>
        <Arrow />
      </IconWrapper>
    </div>
  )
}

CarouselButton.propTypes = {
  type: PropTypes.oneOf(['prev', 'next']),
  enabled: PropTypes.bool,
  contentType: PropTypes.string,
  onClick: PropTypes.func,
}
export default CarouselButton
