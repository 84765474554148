import styles from './index.module.scss'
import { ReactComponent as ConfettiSvg } from 'assets/images/confetti.svg'
import cn from 'classnames'

const Confetti = () => {
  return (
    <div className={cn(styles.container)}>
      <ConfettiSvg />
    </div>
  )
}

export default Confetti
