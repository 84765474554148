/* eslint-disable jsx-a11y/no-autofocus */
import cn from 'classnames'
import PropTypes from 'prop-types'
import styles from './index.module.scss'
import { useRef, useEffect } from 'react'
import { useMenuTriggerState } from '@react-stately/menu'
import { useMenuTrigger } from '@react-aria/menu'
import { useButton } from '@react-aria/button'
import { useFocusVisible } from '@react-aria/interactions'
import Menu from '../Menu'
import { ReactComponent as Triangle } from 'assets/images/triangle.svg'
import Popover from '../Popover'

const MenuButton = ({
  hideBorder,
  items,
  label,
  aria,
  onAction,
  active,
  id,
  type,
  parentId,
  hide,
  ...props
}) => {
  // Create state based on the incoming props
  let state = useMenuTriggerState(props)
  let { isFocusVisible } = useFocusVisible({ isTextInput: false })

  // Get props for the menu trigger and menu elements
  let ref = useRef()
  let { menuTriggerProps, menuProps } = useMenuTrigger({}, state, ref)

  // Get props for the button based on the trigger props from useMenuTrigger
  let { buttonProps } = useButton(menuTriggerProps, ref)

  useEffect(() => {
    const main = document.getElementById('main')
    const liftElement = parentId
      ? document.querySelector(`#${parentId} div`)
      : main
    if (!liftElement) return
    if (state.isOpen) {
      // keep parent component above the component below so the dropdown menu is above as well
      liftElement.style.zIndex = 2
    } else {
      liftElement.style.zIndex = 1
    }
  }, [state?.isOpen, parentId])

  useEffect(() => {
    if (hide) {
      state.close()
    }
  }, [hide, state])

  return (
    <div
      className={cn(styles.wrapper, {
        [styles.menuOpen]: state.isOpen,
      })}>
      <button
        {...buttonProps}
        ref={ref}
        aria-label={
          type === 'locale' ? aria : active ? `${label}${active}` : aria
        }
        className={cn(styles.button, {
          [styles.focusVisible]: isFocusVisible,
        })}
        id={id}
        aria-haspopup="listbox"
        aria-expanded={state?.isOpen ? 'true' : 'false'}>
        {label && (
          <label className={styles.prefix} htmlFor={id}>
            {label}
          </label>
        )}

        <span className={styles.text}>{`${
          active
            ? type === 'jobSeekerStatus'
              ? active?.toLowerCase()
              : active
            : ''
        }`}</span>
        <span className={styles.icon} aria-hidden="true">
          <Triangle />
        </span>
      </button>

      {state.isOpen && (
        <Popover
          isOpen={state.isOpen}
          onClose={() => {
            state.close()
          }}>
          <Menu
            className={cn(styles.menu)}
            onAction={(value) => {
              // close menu on selection
              state.close()
              onAction(value)
            }}
            active={active}
            onClose={() => {
              state.close()
            }}
            autoFocus={state.focusStrategy}
            {...props}
            {...menuProps}
          />
        </Popover>
      )}
    </div>
  )
}

MenuButton.propTypes = {
  status: PropTypes.object,
  hideBorder: PropTypes.bool,
  items: PropTypes.array,
  label: PropTypes.string,
  aria: PropTypes.string,
  onAction: PropTypes.func,
  id: PropTypes.string,
  active: PropTypes.string,
  type: PropTypes.string,
  parentId: PropTypes.string,
  hide: PropTypes.bool,
}
export default MenuButton
