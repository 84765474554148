import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import Container from '../../../layout/Container'
import Grid from '../../../layout/Grid'
import { MODIFIED_COMPANIES } from 'constant/companies'
import Marquee from 'react-fast-marquee'
import { parseHtml } from 'utils/parseHtml'
import CoalitionItem from '../Item'
import { getMarqueeItems } from './lib'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import useStore from 'components/layout/_Layout/introStore'
import { mergeRefs } from 'utils/mergeRef'
import { useReducedMotionQuery } from 'utils/useReducedMotionQuery'
import { useIntroHasPlayed } from 'utils/useIntroHasPlayed'

const Coalition = ({
  items = MODIFIED_COMPANIES,
  direction = 'right',
  delay = 0,
}) => {
  const marqueeRef = useRef()
  const { t } = useTranslation('coalition')
  const reducedMotion = useReducedMotionQuery()

  const companies = t('companies', { returnObjects: true })
  const newItems = items.map((item, k) => ({
    ...item,
    ...companies[k],
  }))
  const introComplete = useStore((state) => state.introComplete)
  const controls = useAnimation()

  const { ref, entry } = useInView({ threshold: [0, 0.1, 0.9, 1] })
  // append to the original items another copy of items with 'top' 'bottom' position switched (only if there are an odd number of items) for seamless marquee animation
  /**
   *
   *  * a1,b1 is the first copy
   *  * a2,b2 is the second copy
   *    a1 a1 a1 a1 b2 b2 b2 b2 b2
   *
   *   b1 b1 b1 b1 b1 a2 a2 a2 a2
   *
   */
  const marqueeItems = getMarqueeItems(newItems, reducedMotion)
  const inView = entry?.intersectionRatio >= 0.25
  const introHasPlayed = useIntroHasPlayed()

  useEffect(() => {
    if (introComplete) {
      controls.start({
        opacity: 1,
        y: 0,
        transition: { duration: 0.5, delay: 1 },
      })
      return
    }
    if (introHasPlayed) {
      controls.start({
        opacity: 1,
        y: 0,
        transition: { duration: 0.5, delay: 1.25 },
      })
    }
  }, [introComplete, controls, introHasPlayed])

  // manually fix the id for all the marquee item an tooltip since marquee create two copies of the items (ada fix)
  useEffect(() => {
    const marqueeContainer = marqueeRef?.current
    if (!marqueeContainer) return

    const ammendIdForGroup = (parent, cssSelector, attribute, suffix) => {
      const items = parent.querySelectorAll(cssSelector)
      items.forEach((item) => {
        const originalAtt = item.getAttribute(attribute)
        const newAtt = originalAtt + '-' + suffix
        item.setAttribute(attribute, newAtt)
      })
    }

    // delay 300ms to allow marquee scrip to run
    setTimeout(() => {
      const marqueeGroups = marqueeContainer.querySelectorAll('.marquee')
      marqueeGroups.forEach((group, key) => {
        group.ariaHidden = 'false'
        ammendIdForGroup(group, 'span', 'data-for', key)
        ammendIdForGroup(group, '.__react_component_tooltip', 'id', key)
      })
    }, 300)
  }, [marqueeRef])

  return (
    <Container
      fullbleed
      className={cn(styles.container, {
        [styles.reducedMotion]: reducedMotion,
      })}>
      <Grid className={styles.grid}>
        <motion.div
          initial={{ opacity: 0, y: '20px' }}
          animate={controls}
          className={styles.header}>
          {parseHtml(t('headline'))}
        </motion.div>
      </Grid>

      <div className={cn(styles.icons, styles.iconsDesktop)}>
        <div className={styles.marqueeWrapper} ref={mergeRefs(ref, marqueeRef)}>
          <Marquee
            className={styles.marquee}
            pauseOnHover
            direction={direction}
            play={inView && !reducedMotion}
            delay={delay}
            gradient={false}>
            {marqueeItems.map((item, i) => {
              return (
                <CoalitionItem
                  key={i}
                  index={i}
                  item={item}
                  playing={inView}
                  type="marquee"
                />
              )
            })}
          </Marquee>
        </div>
      </div>

      <div className={cn(styles.icons, styles.iconsMobile)}>
        {newItems.map((item, j) => (
          <CoalitionItem item={item} index={j} key={j} type="grid" />
        ))}
      </div>
    </Container>
  )
}

export default Coalition

Coalition.propTypes = {
  items: PropTypes.array,
  direction: PropTypes.oneOf(['left', 'right']),
  delay: PropTypes.number,
}
