// import cn from 'classnames'
import Container from 'components/layout/Container'
import { motion, useAnimation, useReducedMotion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
// import Selector from 'components/pieces/Selector'
// import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './index.module.scss'
import Selector from '../Selector'
import { PageLineCheckpoint } from 'components/pieces/PageLine'
import { parseHtml } from 'utils/parseHtml'
import { useInView } from 'react-intersection-observer'
import useStore from 'components/layout/_Layout/introStore'
import { useEffect, useRef } from 'react'
import { useSeekerStatusContext } from 'components/layout/_Layout/seekerStatusContext'
import { wait } from 'utils/wait'
import { mergeRefs } from '@react-aria/utils'
import gaEvent from 'utils/analytics'
import { useIntroHasPlayed } from 'utils/useIntroHasPlayed'
import TranscriptButton from 'components/pieces/TranscriptButton'
import { useDocumentSize } from 'utils/useDocumentSize'

const Hero = () => {
  const { t } = useTranslation('hero')
  const isPostEvents = process.env.REACT_APP_STATE === 'events_over'
  const controls = useAnimation()
  const reducedMotion = useReducedMotion()
  const { updateStatus } = useSeekerStatusContext() || {}
  const cpRef = useRef()
  const local = useRef()
  const textRef = useRef()
  const selectorRef = useRef()
  const documentSize = useDocumentSize()

  const introComplete = useStore((state) => state.introComplete)
  const introHasPlayed = useIntroHasPlayed()
  const bg = document.getElementById('page-bg')

  const { inView, ref, entry } = useInView({
    threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
  })
  const textInViewObj = useInView({
    threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
  })

  const heroPercent = parseInt(entry?.intersectionRatio * 10, 10) / 10

  const onAction = (value) => {
    // update the job seeker status in context
    updateStatus && updateStatus(value)
    gaEvent('Intro', 'Select', `I'm Ready To`, value)

    if (reducedMotion) return
    // scroll to the first slice
    wait(500, () => {
      const firstSlice = document.querySelector('#spotlight+section')
      if (!firstSlice) return
      firstSlice.scrollIntoView({
        block: 'start',
      })
    })
  }

  // fade the background image based on the hero scroll progress
  useEffect(() => {
    if (inView) {
      const opacity = 0.6 - heroPercent * -0.4
      if (bg) {
        bg.style.opacity = opacity
      }
    }
  }, [bg, inView, heroPercent, ref])

  // fade hero text based on scroll percent
  useEffect(() => {
    if (reducedMotion) return
    if (textInViewObj?.inView) {
      textRef.current.style.opacity =
        parseInt(textInViewObj?.entry?.intersectionRatio * 10, 10) / 10
    }

    if (documentSize.width < 480) {
      textRef.current.style.opacity = 1
    }
  }, [textInViewObj, inView, heroPercent, reducedMotion, documentSize.width])

  useEffect(() => {
    if (introComplete) {
      controls.start({
        opacity: 1,
        y: 0,
        transition: { duration: 1, delay: 0 },
      })
      return
    }
    if (introHasPlayed) {
      controls.start({
        opacity: 1,
        y: 0,
        transition: { duration: 1, delay: 0.25 },
      })
    }
  }, [introComplete, controls, introHasPlayed])

  useEffect(() => {
    if (introComplete || introHasPlayed) return
    // Set body styling to hide scroll
    document.body.style.overflow = 'hidden'
    document.body.style.height = '100vh'
  }, [introComplete, introHasPlayed])

  return (
    <section
      className={cn(styles.wrapper)}
      ref={mergeRefs(local, ref)}
      style={{ '--opacity': local?.current?.opacity }}
      id="hero">
      <Container className={styles.container}>
        <motion.div
          className={styles.content}
          initial={{ opacity: 0, y: `${'100px'}` }}
          animate={controls}>
          <div
            className={styles.text}
            ref={mergeRefs(textInViewObj?.ref, textRef)}>
            <h1 className={styles.title}>
              {parseHtml(isPostEvents ? t('post.title') : t('title'))}
            </h1>
            <div className={styles.description}>
              {parseHtml(
                isPostEvents ? t('post.description') : t('description')
              )}
            </div>
          </div>

          <div className={styles.selectorWrapper}>
            <div className={styles.selector} ref={selectorRef}>
              {!isPostEvents && <Selector onAction={onAction} />}
            </div>

            <span ref={cpRef}>
              <PageLineCheckpoint className={styles.checkpoint} />
            </span>
          </div>
        </motion.div>
      </Container>

      <TranscriptButton from="home" onAction={() => {}} offAction={() => {}} />
    </section>
  )
}

Hero.propTypes = {}
export default Hero
