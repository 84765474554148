import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styles from './index.module.scss'
import { imageResolver } from 'utils/imageResolver'

const ValueCard = ({ value }) => {
  const { t } = useTranslation('wellbeing')
  const values = t('values', { returnObjects: true })
  const valInfo = useMemo(() => {
    return values.filter((i) => i.title === value)[0]
  }, [value, values])

  return (
    <div
      className={styles.wrapper}
      style={{
        background: `url(${imageResolver(valInfo.bg_svg)}) center / cover`,
      }}>
      <h2 className={styles.title}>{valInfo.title}</h2>
      <ul className={styles.listWrapper}>
        {valInfo.points.map((point, i) => {
          return (
            <li className={styles.listItem} key={i}>
              {point}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

ValueCard.propTypes = {
  value: PropTypes.string.isRequired,
}

export default ValueCard
