import Container from 'components/layout/Container'
import Grid from 'components/layout/Grid'
import { useTranslation } from 'react-i18next'
import { parseHtml } from 'utils/parseHtml'
import { svgResolver } from 'utils/svgResolvers'
import styles from './index.module.scss'

const Impact = () => {
  const { t } = useTranslation('impact')
  const impactData = t('data', { returnObjects: true })

  return (
    <Container className={styles.wrapper}>
      <Grid className={styles.grid}>
        <div className={styles.headline}>
          <h2>{parseHtml(t('title'))} </h2>
        </div>
        <div className={styles.infoWrapper}>
          {impactData.map((data, i) => (
            <div key={i} className={styles.statWrapper}>
              <div className={styles.numberContainer}>
                <div className={styles.iconContainer} aria-hidden="true">
                  {svgResolver(data.svg)}
                </div>
                <div className={styles.number}>{data.number}</div>
              </div>
              <div className={styles.description}>{data.description}</div>
            </div>
          ))}
        </div>
      </Grid>
    </Container>
  )
}

export default Impact
