import { useState, useEffect } from 'react'

function appendScript(baseUrl, setScriptLoaded) {
  if (window.MktoForms2) return setScriptLoaded(true)
  const script = document.createElement('script')
  script.src = `${baseUrl}/js/forms2/js/forms2.min.js`
  script.onload = () => (window.MktoForms2 ? setScriptLoaded(true) : null)
  document.body.appendChild(script)
}

const baseUrl = 'https://js.indeed.com'
const munchkinId = '093-HWU-597'

function useMarketo({ formId, callback }) {
  const [scriptLoaded, setScriptLoaded] = useState(false)

  useEffect(() => {
    appendScript(baseUrl, setScriptLoaded)

    const form = document.getElementById(`mktoForm_${formId}`)
    const content = form?.querySelector('div')
    if (scriptLoaded && form && !content) {
      window.MktoForms2.loadForm(baseUrl, munchkinId, formId, callback)
      return
    }
  }, [scriptLoaded, formId, callback])

  return <form id={`mktoForm_${formId}`} />
}

export default useMarketo
