/* eslint-disable react/jsx-no-target-blank */
import styles from './index.module.scss'
import cn from 'classnames'
import Grid from '../Grid'
import { ReactComponent as Logo } from 'assets/images/logo-footer.svg'
import Container from '../Container'
import { GLOBAL_SOCIALS } from 'constant'
import { PageLineCheckpoint } from 'components/pieces/PageLine'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { parseHtml } from 'utils/parseHtml'
import gaEvent from 'utils/analytics'
import Dropdown from 'components/pieces/Dropdown/_Index'
import { useLocale } from '../_Layout/localeContext'

export default function Footer() {
  const { t } = useTranslation('global', 'common')
  const exploreLinks = t('footer.explore.links', { returnObjects: true })
  const logo = t('footer.logo', { returnObjects: true })
  const resourceLinks = t('footer.resources.links', { returnObjects: true })
  const disclaimerLinks = t('footer.disclaimer.links', { returnObjects: true })
  const { changeLocale } = useLocale()
  const lang = document?.documentElement.getAttribute('lang')
  const locales = [t('common:english'), t('common:spanish')]
  const nextActive = lang === 'es' ? t('common:english') : t('common:spanish')

  return (
    <footer className={cn(styles.footer)}>
      <PageLineCheckpoint invisible className={styles.checkpoint} />
      <Container className={cn(styles.container)}>
        <Grid className={styles.topRow}>
          <div className={styles.logoWrapper}>
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a
              className={styles.logo}
              href={logo.link}
              target="_blank"
              onClick={() => gaEvent('Footer', 'Click', 'Indeed Logo')}
              aria-label={t('footer.logo.title')}>
              <span aria-hidden="true">
                <Logo alt="Indeed" className={styles.logo} />
              </span>
            </a>

            <div className={cn(styles.localeDropdown, 'light')}>
              <Dropdown
                items={locales}
                aria={t('footer.locale.aria', { nextActive: nextActive })}
                id="locale-dropdown"
                onAction={(value) => {
                  // manually disable link pointer events on footer when locale selector is active
                  const allLinks = document.querySelectorAll('footer a[href]')
                  allLinks?.forEach((link) => {
                    if (!link) return
                    link.style.pointerEvents = 'none'
                  })
                  changeLocale(value === t('common:english') ? 'en' : 'es')

                  setTimeout(() => {
                    allLinks?.forEach((link) => {
                      if (!link) return
                      link.style.pointerEvents = 'auto'
                    })
                  }, 300)
                }}
                active={
                  lang === 'en' ? t('common:english') : t('common:spanish')
                }
                type={`locale`}></Dropdown>
            </div>

            {/* <div className={styles.locale}>
              <button
                className={cn(styles.localeBtn, {
                  [styles.active]: lang === 'en',
                })}
                onClick={() => {
                  changeLocale('en')
                }}>
                en
              </button>

              <button
                className={cn(styles.localeBtn, {
                  [styles.active]: lang === 'es',
                })}
                onClick={() => {
                  changeLocale('es')
                }}>
                es
              </button>
            </div> */}
          </div>

          <div className={cn(styles.list, styles.exploreList)}>
            <h4 className={styles.listTitle}>{t('footer.explore.label')}</h4>
            <ul className={styles.linkWrapper}>
              {exploreLinks?.map((link, i) => (
                <li className={styles.link} key={`explore-link-${i}`}>
                  {/* eslint-disable-next-line react/jsx-no-target-blank */}
                  <a
                    href={link.link}
                    onClick={() => gaEvent('Footer', 'Click', link?.title)}
                    target="_blank">
                    {link?.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className={cn(styles.list, styles.resourceList)}>
            <h4 className={styles.listTitle}> {t('footer.resources.label')}</h4>
            <ul className={styles.linkWrapper}>
              {resourceLinks?.map((link, j) => (
                <li className={styles.link} key={`resource-link-${j}`}>
                  {/* eslint-disable-next-line react/jsx-no-target-blank */}
                  <a
                    href={link.link}
                    onClick={() => gaEvent('Footer', 'Click', link?.title)}
                    target="_blank">
                    {link?.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className={cn(styles.list, styles.socialList)}>
            <h4 className={styles.listTitle}>{t('footer.share_label')}</h4>
            <div className={styles.iconWrapper}>
              {GLOBAL_SOCIALS.map((item, k) => (
                <a
                  key={`footer-social-share-${k}`}
                  aria-label={item?.label}
                  href={item.link}
                  onClick={() => gaEvent('Footer', 'Click', item?.label)}
                  target="_blank">
                  <span aria-hidden="true"> {<item.Icon />} </span>
                </a>
              ))}
            </div>
          </div>
        </Grid>
        <Grid className={styles.bottomRow}>
          <h4 className={styles.infoTextLeft}>
            {disclaimerLinks?.map((link, l) => (
              <Fragment key={`disclaimer-${l}`}>
                <>
                  {link.link && (
                    <a
                      target="_blank"
                      onClick={() => gaEvent('Footer', 'Click', link?.title)}
                      href={link.link}>
                      {link.title}
                    </a>
                  )}
                </>
                {l === 0 && (
                  <>
                    {link.title !== '' && <>| </>}© {new Date().getFullYear()}{' '}
                    Indeed <br />
                  </>
                )}
              </Fragment>
            ))}
          </h4>
          <h4 className={styles.infoTextRight}>
            {parseHtml(t('footer.disclaimer.description'))}
          </h4>
        </Grid>
      </Container>
    </footer>
  )
}
