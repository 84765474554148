import care from 'assets/images/care.png'
import rework from 'assets/images/raa.svg'
import glassdoor from 'assets/images/glassdoor.png'
import lyft from 'assets/images/lyft.png'
import dunkin from 'assets/images/dunkin.png'
import goodwill from 'assets/images/goodwill.png'
import chicagoBull from 'assets/images/chicago-bull.png'
import detroitP from 'assets/images/detroit-pistons.png'
import skimm from 'assets/images/the-skimm.png'
import _ from 'lodash'

export const COMPANIES = [
  {
    name: 'Care.com',
    logo: { url: care, width: 429, height: 120 },
    link: 'https://www.care.com/p1523-qxid%7Cbabysitter_1007_02%7C1-rxonline_affiliates%7Cseeker_childcare%7CIndeed%7CRTWHub%7C.html',
  },
  {
    name: 'Rework America Alliance',
    logo: { url: rework, width: 4099, height: 1335 },
    link: 'https://www.markle.org/alliance-indeed_ready/',
  },
  {
    name: 'Glassdoor',
    logo: { url: glassdoor, width: 188, height: 148 },
    link: 'https://www.glassdoor.com/Career/index.htm',
  },
  {
    name: 'Lyft',
    logo: { url: lyft, width: 177, height: 125 },
    link: 'https://www.lyft.com/lyftup/jobs-access',
  },
  {
    name: `Dunkin'`,
    logo: { url: dunkin, width: 366, height: 72 },
    link: 'https://careers.dunkindonuts.com/us/en?utm_source=Hub&utm_medium=Website&utm_campaign=Indeed%20Event',
  },
  {
    name: 'Goodwill',
    logo: { url: goodwill, width: 237, height: 300 },
    link: 'https://www.goodwill.org',
  },
  {
    name: 'Detroit Pistons',
    logo: { url: detroitP, width: 255, height: 239 },
    link: 'https://www.nba.com/pistons',
  },
  {
    name: 'Chicago Bulls',
    logo: { url: chicagoBull, width: 186, height: 186 },
    link: 'https://www.nba.com/bulls',
  },
  {
    name: 'The Skimm',
    logo: { url: skimm, width: 186, height: 186 },
    size: 130,
    link: 'https://www.theskimm.com/careers?utm_source=indeed&utm_medium=partnership&utm_campaign=indeed_sales_partnership_2022',
  },
]

let OFFSETS = []

Array(COMPANIES?.length)
  .fill(0)
  .forEach((item) => OFFSETS.push(_.random(-4, 28)))

export const MODIFIED_COMPANIES = COMPANIES.map((company, index) => {
  return {
    ...company,
    top: index % 2 === 1 ? OFFSETS[index] : 0,
    bottom: index % 2 === 0 ? OFFSETS[index] : 0,
    position: index % 2 === 0 ? 'bottom' : 'top',
  }
})
