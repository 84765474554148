import PropTypes from 'prop-types'
import styles from './index.module.scss'
import cn from 'classnames'

const CarouselDot = ({ active, onClick }) => {
  return (
    <button
      className={cn(styles.button, { [styles.active]: active })}
      onClick={onClick}>
      <div className={styles.dot}></div>
    </button>
  )
}

CarouselDot.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func,
}
export default CarouselDot
