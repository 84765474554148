import PropTypes from 'prop-types'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import Container from '../../layout/Container'
import styles from './index.module.scss'
import { compareAsc } from 'date-fns'
import Grid from '../../layout/Grid'
import { useState, useEffect } from 'react'
import Card from 'components/pieces/Card/Base'
import SectionHeader from 'components/pieces/SectionHeader'
import { AnimatePresence, motion } from 'framer-motion'
import { getDate } from 'utils/getDateinTimezone'
import { isPastEvent, resolveDate } from './lib'
import { parseHtml } from 'utils/parseHtml'
import i18n from 'i18n/i18n'

const VirtualEvents = ({ testEvent }) => {
  const isPostEvents = process.env.REACT_APP_STATE === 'events_over'
  const [upcomingEvents, setUpcomingEvents] = useState([])
  const [pastEvents, setPastEvents] = useState([])
  const [currentView, setCurrentView] = useState('upcoming')
  const { t } = useTranslation('virtual', 'ada', 'common')
  const _events = t('events', { returnObjects: true })
  let events = _events
  if (testEvent) {
    events = [..._events, testEvent]
  }

  // regroup all events by date
  useEffect(() => {
    if (!_events || _events?.length < 1) return

    const _upcoming = []
    const _past = []

    events.forEach((event) => {
      const { title, description, cta_link, datetime } = event || {}
      const _isPast = isPastEvent(datetime)
      const formattedDate = getDate(datetime)

      const _event = {
        eyebrow: formattedDate,
        title,
        description,
        ctaLink: cta_link,
        ctaText: event?.cta_text || t('common:register'),
        // keep datetime for sorting
        datetime: datetime,
      }

      if (_isPast) {
        _past.push(_event)
      } else {
        _upcoming.push(_event)
      }

      // sort date by ascend order
      _past.sort((a, b) => {
        const aDate = resolveDate(a)
        const bDate = resolveDate(b)

        return compareAsc(aDate, bDate)
      })
      _upcoming.sort((a, b) => {
        const aDate = resolveDate(a)
        const bDate = resolveDate(b)
        return compareAsc(aDate, bDate)
      })
    })

    setUpcomingEvents(_upcoming)
    setPastEvents(_past)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events?.length, i18n.language])

  useEffect(() => {
    //if there is only past events, set 'past' to be default active tab
    if (upcomingEvents?.length < 1) {
      setCurrentView('past')
    } else {
      setCurrentView('upcoming')
    }
  }, [upcomingEvents?.length])

  const categories = t('categories', { returnObjects: true }).filter(
    (category) => {
      let keep = false
      switch (category.value) {
        case 'upcoming':
          keep = upcomingEvents?.length > 0
          break
        case 'past':
          keep = pastEvents?.length > 0
          break
        default:
          keep = false
      }
      return keep
    }
  )

  return (
    <Container className={cn(styles.container, styles[currentView])}>
      <Grid className={styles.grid}>
        <div className={styles.header}>
          <SectionHeader
            eyebrow={isPostEvents ? t('post.eyebrow') : t('eyebrow')}
            title={isPostEvents ? t('post.title') : t('title')}
            description={parseHtml(
              isPostEvents ? t('post.description') : t('description')
            )}
          />
        </div>

        <div className={styles.inner}>
          {!isPostEvents && (
            <div className={styles.tabs}>
              {categories?.map((tab) => (
                <button
                  className={cn(styles.tab, {
                    [styles.active]: currentView === tab.value,
                  })}
                  key={`tab-${tab?.value}`}
                  onClick={() => void setCurrentView(tab.value)}>
                  {tab?.label}
                </button>
              ))}
            </div>
          )}

          <div className={styles.content}>
            <AnimatePresence exitBeforeEnter>
              {currentView === 'upcoming' &&
                upcomingEvents?.length &&
                !isPostEvents && (
                  <motion.div
                    key="upcoming"
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity: 1,
                      transition: {
                        duration: 1,
                        delay: 0.1,
                      },
                    }}
                    className={cn(styles.events, {
                      [styles.visible]: currentView === 'upcoming',
                    })}>
                    {upcomingEvents.map((event, i) => (
                      <Card
                        key={`upcoming-event-${i}`}
                        className={styles.card}
                        titleClass={styles.cardTitle}
                        buttonFullWidth={true}
                        aria={t('ada:global.learn_more', {
                          title: event?.title,
                        })}
                        thinCTA
                        {...event}
                      />
                    ))}
                  </motion.div>
                )}

              {currentView === 'past' && pastEvents?.length && !isPostEvents && (
                <motion.div
                  key="past"
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: 1,
                    transition: {
                      duration: 1,
                      delay: 0.1,
                    },
                  }}
                  className={cn(styles.events, {
                    [styles.visible]: currentView === 'past',
                  })}>
                  {pastEvents.map((event, i) => (
                    <Card
                      key={`past-event-${i}`}
                      className={styles.card}
                      titleClass={styles.cardTitle}
                      buttonFullWidth={true}
                      aria={t('ada:global.learn_more', { title: event?.title })}
                      thinCTA
                      {...{
                        ...event,
                        eyebrow: undefined,
                        ctaLink:
                          event.ctaText === t('common:register')
                            ? undefined
                            : event.ctaLink,
                        ctaText:
                          event.ctaText === t('common:register')
                            ? undefined
                            : event.ctaText,
                      }}></Card>
                  ))}
                </motion.div>
              )}

              {isPostEvents && (
                <motion.div
                  key="postEvents"
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: 1,
                    transition: {
                      duration: 1,
                      delay: 0.1,
                    },
                  }}
                  className={cn(styles.events, {
                    [styles.visible]: true,
                  })}>
                  {events.map((event, i) => (
                    <Card
                      key={`event-${i}`}
                      className={styles.card}
                      titleClass={styles.cardTitle}
                      buttonFullWidth={true}
                      aria={t('ada:global.learn_more', { title: event?.title })}
                      thinCTA
                      {...{
                        ...event,
                        ctaLink: event.cta_link,
                        ctaText: event.cta_text,
                      }}></Card>
                  ))}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </Grid>
    </Container>
  )
}

export default VirtualEvents

VirtualEvents.propTypes = {
  testEvent: PropTypes.object,
}
