import { useRef } from 'react'
import { useTooltipTrigger } from 'react-aria'
import { useTooltipTriggerState } from 'react-stately'

export const useTooltip = (content) => {
  const focusableRef = useRef()
  const tooltipRef = useRef(null)
  const tooltipTriggerProps = {
    tooltip: content,
    delay: 500,
  }
  const tooltipState = useTooltipTriggerState(tooltipTriggerProps)

  const { triggerProps, tooltipProps } = useTooltipTrigger(
    tooltipTriggerProps,
    tooltipState,
    tooltipRef
  )

  const updateFocusables = (e) => {
    // get tooltip button and focusable elements within tooltip
    const target = e.currentTarget
    if (!target) return

    setTimeout(() => {
      const tooltipId = target.getAttribute('aria-describedby')
      const tooltip = document.getElementById(tooltipId)
      if (!tooltip) return
      let focusableEles = [target, tooltip]
      const tooltipFocusableEles = tooltip.querySelectorAll(
        'a[href]:not([disabled]), button:not([disabled]), div[tabindex="0"]'
      )
      if (tooltipFocusableEles?.length) {
        focusableEles = [...tooltipFocusableEles, ...focusableEles]
      }

      focusableRef.current = focusableEles
    }, 200) // wait for tooltip to open
  }

  const openTooltip = (e) => {
    tooltipState.open(true)
    updateFocusables(e)
  }

  const closeTooltip = (eleToCheck) => {
    const focusableEles = focusableRef.current
    // check to see if current focus element is in the array
    if (!focusableEles || focusableEles?.indexOf(eleToCheck) < 0) {
      // pass true to close immediately
      tooltipState.close(true)
    }
    // reset ref
    focusableRef.current = []
  }

  const updatedTriggerProps = {
    ...triggerProps,
    onFocus: (e) => openTooltip(e, false),
    onBlur: () => {
      setTimeout(() => {
        closeTooltip(document.activeElement)
      }, 300)
    },
    onClick: openTooltip,
  }

  const updatedTooltipProps = {
    ...tooltipProps,
    onFocus: (e) => openTooltip(e, true),
    onBlur: () => {
      setTimeout(() => {
        closeTooltip(document.activeElement)
      }, 300)
    },
  }

  return {
    tooltipRef,
    tooltipState,
    tooltipProps: updatedTooltipProps,
    triggerProps: updatedTriggerProps,
  }
}
