/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types'
import styles from './index.module.scss'
import cn from 'classnames'
import Image from 'components/pieces/Image'
import { imageResolver } from 'utils/imageResolver'

const ImageCard = ({
  item,
  selectedIndex,
  hasSelected,
  index,
  type,
  onClick,
  inView,
}) => {
  const { image, topic, partner, logo } = item

  return (
    <div
      className={cn(styles.slide, styles[type], {
        [styles.selected]: selectedIndex === index,
        [styles.hasSelected]: hasSelected,
        [styles.inview]: inView,
      })}
      key={index}
      onClick={onClick}>
      <div>
        <button
          className={styles.slideInner}
          tabIndex={inView ? '0' : '-1'}
          aria-hidden={inView ? 'false' : 'true'}>
          <div className={styles.imageWrapper}>
            <Image
              layout="fill"
              lazy={false}
              image={{ url: imageResolver(image) }}
            />
          </div>

          <div className={styles.content}>
            {topic && <div className={styles.title}>{topic}</div>}
            {logo && (
              <div className={styles.byline}>
                <span>provided by </span>
                <span>
                  <Image image={{ url: logo }} alt={`${partner}`} />
                </span>
              </div>
            )}
          </div>
        </button>
      </div>
    </div>
  )
}

ImageCard.propTypes = {
  item: PropTypes.shape({
    image: PropTypes.string,
    topic: PropTypes.string,
    logo: PropTypes.string,
    partner: PropTypes.string,
  }),
  index: PropTypes.number,
  selectedIndex: PropTypes.number,
  hasSelected: PropTypes.bool,
  type: PropTypes.string,
  onClick: PropTypes.func,
  inView: PropTypes.bool,
}
export default ImageCard
