import { useState, useEffect, useCallback } from 'react'
import { useDocumentSize } from './useDocumentSize'

const getDefaultTheme = () => {
  const storedTheme = localStorage.getItem('theme')
  if (storedTheme) {
    return storedTheme
  }

  return 'dark'
}

export const useTheme = () => {
  const [theme, setTheme] = useState(getDefaultTheme)
  const documentSize = useDocumentSize()

  const setSavedTheme = useCallback(
    (newTheme) => {
      localStorage.setItem('theme', newTheme)
      setTheme(newTheme)
    },
    [setTheme]
  )

  useEffect(() => {
    setTheme(getDefaultTheme(documentSize))
  }, [documentSize])

  // update the localStorage when theme changes
  useEffect(() => {
    document.documentElement.classList.toggle('light', theme === 'light')
  }, [theme])

  return [theme, setSavedTheme]
}
