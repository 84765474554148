import { ReactComponent as Briefcase } from 'assets/images/briefcase.svg'
import { ReactComponent as Building } from 'assets/images/building.svg'
import { ReactComponent as IdCard } from 'assets/images/id-card.svg'
import { ReactComponent as Conversation } from 'assets/images/conversation.svg'

export const svgResolver = (name) => {
  switch (name) {
    case 'Briefcase':
      return <Briefcase />
    case 'Building':
      return <Building />
    case 'IdCard':
      return <IdCard />
    case 'Conversation':
      return <Conversation />

    default:
      return <name />
  }
}
