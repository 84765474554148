import { useState } from 'react'
import { Box, Button, TextField } from '@mui/material'

const FeedbackTextInput = (props) => {
  // eslint-disable-next-line react/prop-types
  const { feedback, setFeedback, handleSubmit } = props
  const [charCount, setCharCount] = useState(0)

  return (
    <Box
      sx={{
        maxWidth: '897px',
        mx: ['auto', null],
        mr: 'auto',
        padding: '24px 32px',
      }}>
      <Box component="h2">Thank you for your feedback!</Box>

      <Box
        as={'p'}
        sx={{ color: `neutral.1000`, mb: '12px', fontWeight: 'bolder' }}>
        Would you like to provide any additional comments?
      </Box>
      <Box as={'span'} sx={{ color: `neutral.1000` }}>
        Additional details (optional)
      </Box>
      <Box>
        <TextField
          fullWidth
          inputProps={{ maxLength: 500 }}
          sx={{ background: 'white', mt: '12px', borderRadius: '16px' }}
          multiline
          rows={4}
          value={feedback}
          onChange={({ target: { value } }) => {
            setFeedback(value)
            setCharCount(value.length)
          }}
        />
        <Box as={'span'} sx={{ color: `neutral.1000` }}>
          {`${charCount}/500`}
        </Box>
        <Box sx={{ textAlign: 'right', py: 3 }}>
          <Button
            sx={{
              width: '100%',
              background: '#0f46a1',
              border: '1px solid #ffffff',
              borderRadius: '8px',
            }}
            variant="primary"
            onClick={() => handleSubmit()}>
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default FeedbackTextInput
