import PropTypes from 'prop-types'
import styles from './index.module.scss'
import RichTextField from '../../RichTextField'
import cn from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'
import Button from '../../Button'

const Card = ({
  eyebrow,
  title,
  description,
  children,
  ctaText,
  ctaLink,
  backgroundImage,
  collapsed = false,
  collapsable = false,
  ctaAction,
  buttonType = 'outlineReversed',
  buttonFullWidth = false,
  titleClass,
  className,
  aria,
  thinCTA,
}) => {
  return (
    <AnimatePresence initial={false}>
      <div
        // interactive={collapsable.toString()}
        className={cn(
          styles.card,
          collapsed ? styles.collapsed : undefined,
          backgroundImage ? styles.backgroundImage : undefined,
          className
        )}
        style={{
          backgroundImage: backgroundImage ? `url('${backgroundImage}')` : '',
        }}>
        {!collapsed && (
          <motion.div className={styles.inner}>
            <div className={styles.content}>
              {eyebrow && <div className={styles.eyebrow}>{eyebrow}</div>}
              {title && (
                <div className={cn(styles.title, titleClass)}>{title}</div>
              )}
              {description && (
                <RichTextField
                  className={styles.description}
                  text={description}
                />
              )}
              <>{children}</>
            </div>

            {ctaText && (ctaLink || ctaAction) && (
              <Button
                type={buttonType}
                action={ctaAction}
                link={ctaLink}
                fullWidth={buttonFullWidth}
                thin={thinCTA}
                aria={aria}
                className={styles.cta}>
                {ctaText}
              </Button>
            )}
          </motion.div>
        )}

        {collapsed && (
          <motion.div>
            <div className={styles.rotationOuter}>
              <div className={styles.rotationInner}>
                {title && (
                  <div className={cn(styles.title, styles.collapsedTitle)}>
                    {title}
                  </div>
                )}
              </div>
            </div>
          </motion.div>
        )}
      </div>
    </AnimatePresence>
  )
}

Card.propTypes = {
  eyebrow: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  ctaText: PropTypes.string,
  ctaLink: PropTypes.string,
  backgroundImage: PropTypes.string,
  collapsed: PropTypes.bool,
  collapsable: PropTypes.bool,
  ctaAction: PropTypes.func,
  buttonType: PropTypes.string,
  buttonFullWidth: PropTypes.bool,
  titleClass: PropTypes.string,
  className: PropTypes.string,
  aria: PropTypes.string,
  thinCTA: PropTypes.bool,
}
export default Card
