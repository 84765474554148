import PropTypes from 'prop-types'
import { motion, useAnimation } from 'framer-motion'
import { Children, useEffect, useRef, useCallback, useState } from 'react'
import { observe } from 'react-intersection-observer'
import classNames from 'classnames'
import gaEvent from 'utils/analytics'
import { useReducedMotionQuery } from 'utils/useReducedMotionQuery'

export default function InViewReveal({
  children,
  once = true,
  delay = 0,
  threshold = 0.1,
  className,
  disabled,
  duration = 1,
  gaEventData,
  ...props
}) {
  const ref = useRef()
  const controls = useAnimation()
  const [appeared, setAppeared] = useState(false)
  const reducedMotion = useReducedMotionQuery()

  const variants = {
    initial: {
      opacity: 1,
    },
    animate: {
      opacity: 1,
      transition: {
        delay,
        delayChildren: delay,
        staggerChildren: 0.1,
      },
    },
  }

  const variantItem = {
    initial: {
      opacity: reducedMotion ? 1 : 0,
      y: reducedMotion ? 0 : 30,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        duration,
        ease: [0.25, 0.1, 0.25, 1],
      },
    },
  }

  const handleObserve = useCallback(
    (visible) => {
      if (reducedMotion) {
        controls.start('initial')
      } else {
        if (visible) {
          controls.start('animate')
        } else if (!once) {
          controls.start('initial')
        }
      }
    },
    [controls, once, reducedMotion]
  )

  useEffect(() => {
    const container = ref?.current

    if (container) {
      container.observer = observe(ref.current, handleObserve, {
        threshold,
      })
    }

    return () => {
      if (container?.observer) {
        container.observer()
      }
    }
  }, [handleObserve, disabled, threshold])

  if (disabled) {
    return (
      <div className={classNames(className, 'chromatic-ignore')}>
        {children}
      </div>
    )
  }

  return (
    <motion.section
      ref={ref}
      initial="initial"
      animate={controls}
      exit="initial"
      variants={variants}
      className={classNames(className, 'chromatic-ignore')}
      onAnimationComplete={() => {
        if (!appeared) {
          setAppeared(true)
          if (gaEventData) {
            gaEvent(...gaEventData)
          }
        }
      }}
      {...props}>
      {Children.map(Children.toArray(children), (child) => {
        const type = child?.type?.toLowerCase ? child.type.toLowerCase() : 'div'
        const ClonedComponent = motion[type]
        return (
          <ClonedComponent
            {...child.props}
            variants={variantItem}
            className={classNames(child.props?.className, 'chromatic-ignore')}
          />
        )
      })}
    </motion.section>
  )
}

InViewReveal.propTypes = {
  children: PropTypes.node,
  delay: PropTypes.number,
  once: PropTypes.bool,
  threshold: PropTypes.number,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  duration: PropTypes.number,
  gaEventData: PropTypes.array,
}
