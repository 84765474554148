import { debounce, random, sample } from 'lodash'
import styles from './index.module.scss'

export default class PageLine {
  active = false
  container = null
  svg = null

  markers = []
  markerCounter = 0
  checkpointPositions = []
  checkpointTriggers = []
  line = null
  lineControlPoints = new Map()
  lineCommands = []

  resizeObserver = null
  progress = 0
  startY = 0
  endY = 0
  tallestHeight = 0
  lastHeight = 0

  unmount() {
    this.removeEventListeners()
    this.lineCommands = []
    this.checkpointPositions = []
    this.checkpointTriggers = []
    this.svg = null
    this.line = null
    this.progress = 0
    this.startY = 0
    this.endY = 0
    this.tallestHeight = 0
    this.lastHeight = 0

    if (this.container) {
      this.container.innerHTML = ''
      this.container = null
    }
  }

  constructor(active) {
    this.active = active
  }

  setActive(active) {
    if (this.active === active) {
      return
    }

    this.active = active

    if (active) {
      if (this.container) {
        this.setup(this.container)
      }
    } else {
      this.unmount()
    }
  }

  addCheckpoint(marker) {
    this.markers.push(marker)
    marker.setAttribute('id', `pageline-checkpoint-${this.markerCounter++}`)
    this.setupPath()
  }

  removeCheckpoint(marker) {
    marker.removeAttribute('id')

    const index = this.markers.indexOf(marker)
    if (index > -1) {
      this.markers.splice(index, 1)
    }

    this.setupPath()
  }

  setup(container) {
    this.lastHeight = container.offsetHeight
    this.container = container
    this.setupEventListeners()
    this.setupPath()
  }

  setupPath(width, height) {
    window.requestAnimationFrame(() => {
      if (!this.active || !this.container) {
        return
      }

      const w = width ?? this.container.offsetWidth
      const h = height ?? this.container.offsetHeight

      if (Math.abs(h - this.lastHeight) > 200 && this.progress >= 0.01) {
        clearTimeout(this.resizingClassTimeout)
        this.line?.classList.add(styles.resizing)
      }
      this.lastHeight = h

      this.lineCommands = []
      this.checkpointTriggers = []
      this.checkpointPositions = []
      this.tallestHeight = Math.max(this.tallestHeight, h)

      let svgBuffer
      let lineCommandsBuffer = ''
      let firstPoint

      if (this.svg) {
        this.svg.setAttribute('height', `${this.tallestHeight}px`)
        this.svg.setAttribute('viewBox', `0 0 ${w} ${this.tallestHeight}`)
      } else {
        svgBuffer = `<svg role="presentation" xmlns="http://www.w3.org/2000/svg" width="100%" height="${this.tallestHeight}px" viewBox="0 0 ${w} ${this.tallestHeight}" class="${styles.svg}">`
      }

      const positions = new Map()

      /**
       * Sort markers by height when we generate
       * the path and also cache their positions
       * because we'll need them in the loop
       */
      this.markers.sort((a, b) => {
        const cachedAPosition = positions.get(a.getAttribute('id'))
        const cachedBPosition = positions.get(b.getAttribute('id'))
        const aPosition = cachedAPosition ?? this.getElementPosition(a)
        const bPosition = cachedBPosition ?? this.getElementPosition(b)

        if (!cachedAPosition) {
          positions.set(a.getAttribute('id'), aPosition)
        }

        if (!cachedBPosition) {
          positions.set(b.getAttribute('id'), bPosition)
        }

        return aPosition[1] - bPosition[1]
      })

      // Here we generate path commands from checkpoint positions
      for (let i = 0; i < this.markers.length; i++) {
        const element = this.markers[i]
        const [left, top] =
          positions.get(element.getAttribute('id')) ??
          this.getElementPosition(element)
        const x = left - 1
        const y = top
        const position = [x, y]
        let lineCommand = ''

        // https://developer.mozilla.org/en-US/docs/Web/SVG/Tutorial/Paths
        switch (i) {
          case 0: {
            this.startY = y
            firstPoint = position
            lineCommand += `M ${x} ${y}`

            break
          }
          case 1: {
            /**
             * To accomodate for all resolutions, we generate curve modifiers
             * based on a random multiplier and the distance between points
             * instead of absolute curve positions, we also cache these
             */

            const cachedPoints = this.lineControlPoints.get(i)
            const cachedA = cachedPoints?.[0]
            const a = cachedA ?? [0, random(2, 5)]
            const cachedB = cachedPoints?.[0]
            const b = cachedB ?? [
              this.getRandomWithNegative(0.5, 2),
              random(1, 3),
            ]

            if (!cachedPoints) {
              this.lineControlPoints.set(i, [a, b])
            }

            const xRange = window.innerWidth * 0.125
            const yRange = (y - firstPoint[1]) * 0.25
            const vectorAX = firstPoint[0] + xRange * a[0]
            const vectorAY = firstPoint[1] + yRange * a[1]
            const vectorBX = x + xRange * b[0]
            const vectorBY = y - yRange * b[1]

            /**
             * C command
             *
             *      * (this point is already set from first command)
             *     /
             *    /
             *   vectorA (curve control point 1)
             *   |
             *   |
             *   vectorB (curve control point 2)
             *    \
             *     \
             *      vectorC (our checkpoint)
             */
            lineCommand += `C
              ${vectorAX} ${vectorAY}
              ${vectorBX} ${vectorBY}
              ${x} ${y}`

            break
          }
          default: {
            const cachedPoints = this.lineControlPoints.get(i)
            const cachedA = cachedPoints?.[0]
            const a = cachedA ?? [random(-2, 0.5), random(1, 3)]
            if (!cachedPoints) {
              this.lineControlPoints.set(i, [a])
            }

            const xRange = window.innerWidth * 0.125
            const yRange = (y - this.checkpointPositions[i - 1][1]) * 0.25
            const vectorAX = x + xRange * a[0]
            const vectorAY = y - yRange * a[1]

            /**
             * S command
             *
             *        /
             *       * (this point is already set from the last command)
             *      /
             *     /
             *    * (curve is reflected from our last command's curve)
             *   |
             *   |
             *   vectorA (curve control point 1)
             *    \
             *     \
             *      vectorB (endpoint)
             */
            lineCommand += `S
              ${vectorAX} ${vectorAY}
              ${x} ${y}`

            if (i === this.markers.length - 1) {
              this.endY = y
            }

            break
          }
        }

        lineCommandsBuffer += lineCommand
        this.lineCommands[i] = lineCommand
        this.checkpointPositions[i] = [x, y]
        this.checkpointTriggers.push({ y, element })
      }

      if (this.svg) {
        this.line.setAttribute('d', lineCommandsBuffer)
      } else {
        svgBuffer += `<path d="${lineCommandsBuffer}" stroke="currentColor" fill="transparent" class="${styles.line}"/></svg>`
        this.container.innerHTML = svgBuffer
        this.svg = this.container.children[0]
        this.line = this.svg.querySelector(`.${styles.line}`)
      }

      setTimeout(() => {
        this.handleScroll()
      }, 250)
    })
  }

  setupEventListeners() {
    this.debouncedHandleResizeStart = debounce(this.handleResizeStart, 100, {
      leading: true,
      trailing: false,
    })
    this.debouncedHandleResizeEnd = debounce(this.handleResizeEnd, 100)
    this.resizeObserver = new ResizeObserver(this.handleResize)
    this.resizeObserver.observe(this.container)
    window.addEventListener('scroll', this.handleScroll)
  }

  removeEventListeners() {
    this.resizeObserver?.disconnect()
    this.resizeObserver = null
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    if (!this.active || !this.line) {
      return
    }

    /**
     * Offset path progress with the how far the
     * last point is from the end of the page
     */
    const progress = this.remap(
      window.scrollY + this.startY - 16,
      this.startY,
      this.endY,
      0,
      1
    )
    this.progress = progress

    const length = this.line.getTotalLength()
    const lengthCrop = length * progress
    this.line.style['stroke-dasharray'] = length
    this.line.style['stroke-dashoffset'] = length - lengthCrop

    // If our path crosses a checkpoint, animate it in
    const lengthY = this.line.getPointAtLength(lengthCrop).y - 8
    for (let i = 0; i < this.checkpointTriggers.length; i++) {
      const { y, element } = this.checkpointTriggers[i]
      const shouldShow = lengthY > y
      element.classList.toggle(styles.active, shouldShow)
    }
  }

  handleResize = (entries) => {
    this.debouncedHandleResizeStart()
    this.debouncedHandleResizeEnd()

    for (let entry of entries) {
      let width = this.container?.offsetWidth
      let height = this.container?.offsetHeight

      if (entry.contentBoxSize) {
        const box = Array.isArray(entry.contentBoxSize)
          ? entry.contentBoxSize[0]
          : entry.contentBoxSize

        width = box.inlineSize
        height = box.blockSize
      } else if (entry.contentRect) {
        width = entry.contentRect.width
        height = entry.contentRect.height
      }

      this.setupPath(width, height)
    }
  }

  handleResizeStart = () => {
    // clearTimeout(this.resizingClassTimeout)
    // this.line?.classList.add(styles.resizing)
  }

  handleResizeEnd = () => {
    this.resizingClassTimeout = setTimeout(() => {
      this.line?.classList.remove(styles.resizing)
      this.resizingClassTimeout = null
    }, 1000)
  }

  clamp = (value, min = 0, max = 1) => {
    return Math.min(Math.max(value, min), max)
  }

  remap = (value, minA, maxA, minB, maxB) => {
    const clampedValue = this.clamp(value, minA, maxA)

    return minB + ((maxB - minB) * (clampedValue - minA)) / (maxA - minA)
  }

  getElementPosition = (element) => {
    let currentElement = element
    let offsetLeft = 0
    let offsetTop = 0

    /**
     * We want to get the absolute position without
     * transforms so we have to use the loop method
     * instead of getBoundingClientRect()
     */
    do {
      offsetLeft += currentElement.offsetLeft
      offsetTop += currentElement.offsetTop
      currentElement = currentElement.offsetParent
    } while (currentElement)

    return [offsetLeft, offsetTop]
  }

  getRandomWithNegative = (min, max, mult = 1) => {
    return random(min * mult, max * mult) * sample([1, -1])
  }
}
