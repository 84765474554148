/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types'
import styles from './index.module.scss'
import cn from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { useState, useEffect, useRef } from 'react'
import { ReactComponent as IndeedLogo } from 'assets/images/logo-indeed.svg'
import { GLOBAL_SOCIALS } from 'constant'
import { useTrapFocus } from '../../../../utils/useTrapFocus'
import { HashLink } from 'react-router-hash-link'
import { useSeekerStatusContext } from 'components/layout/_Layout/seekerStatusContext'
import { useLocation } from 'react-router-dom'
import { parseHtml } from 'utils/parseHtml'
import gaEvent from 'utils/analytics'
import ThemeToggle from '../ThemeToggle'
import { useThemeContext } from 'components/layout/_Layout/themeContext'
import { VIEWS } from 'constant/views'

const Hamburger = ({ pageType, sticky }) => {
  const isPostEvents = process.env.REACT_APP_STATE === 'events_over'
  const { t } = useTranslation('global', 'ada')
  const links = t('header.links', { returnObjects: true })
  const ref = useRef()
  const location = useLocation()
  const [open, setOpen] = useState(false)
  const [opened, setOpened] = useState(false)
  const { setStatus } = useSeekerStatusContext() || {}
  const { theme, setTheme } = useThemeContext() || {}

  useTrapFocus(ref?.current, open)

  useEffect(() => {
    const body = document.documentElement
    if (body) {
      body.classList.toggle('disable-scroll', open)
    }
  }, [open])

  // only fade in once
  useEffect(() => {
    if (open && !opened) {
      setOpened(true)
      gaEvent('Global', 'Click', 'Main Navigation')
    }
  }, [open, opened])

  const handleHash = (handle) => {
    const module = document.getElementById(handle)
    if (!module) {
      // if this module is not in current view, go to 'all view' mode
      setStatus('')
    }
  }

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.split('?')[0].replace('#', '')
      setTimeout(() => {
        handleHash(id)
      }, 300)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.hash])

  return (
    <div
      ref={ref}
      className={cn(
        styles.wrapper,
        {
          [styles.open]: open,
        },
        styles[pageType]
      )}>
      <button
        onClick={() => {
          setOpen(!open)
        }}
        className={cn(styles.hamburger, {
          [styles.open]: open,
          [styles.sticky]: sticky,
        })}
        aria-label={t('ada:header.hamburger.aria', {
          toggle: open ? t('ada:global.close') : t('ada:global.open'),
        })}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : 'false'}>
        <div className={styles.linesWrapper}>
          <div className={styles.line1}> </div>
          <div className={styles.line2}> </div>
          <div className={styles.line3}> </div>
        </div>
      </button>
      <AnimatePresence exitBeforeEnter>
        {open && (
          <motion.div
            className={cn(styles.menu, { [styles.visible]: open })}
            initial={{ x: '100%' }}
            animate={{
              x: 0,
            }}
            exit={{
              x: '100%',
            }}
            transition={{
              duration: 0.6,
            }}>
            <div className={styles.inner}>
              <ul className={styles.socialLinks}>
                {[
                  GLOBAL_SOCIALS.map((item, k) => (
                    <li className={styles.social} key={`social-${k}`}>
                      <a
                        href={item.link}
                        tabIndex={open ? '0' : '-1'}
                        aria-label={item.label}
                        target="_blank"
                        onClick={() =>
                          gaEvent('Main Navigation', 'Click', item.label)
                        }
                        rel="noreferrer">
                        <span aria-hidden="true"> {<item.Icon />}</span>
                      </a>
                    </li>
                  )),
                ]}
              </ul>

              <ThemeToggle
                theme={theme}
                setTheme={setTheme}
                pageType={pageType}
                location="hamburger"
              />

              <div className={styles.linksWrapper}>
                <ul className={cn(styles.links, styles.internalLinks)}>
                  {links
                    .filter((view) => {
                      // for post events layout, only link to visible module
                      if (isPostEvents) {
                        const postEventView = VIEWS.find(
                          (v) => v?.view === 'events over'
                        )
                        const postEventsModules = postEventView?.modules
                        return (
                          view.handle &&
                          postEventsModules.indexOf(view.handle) > -1
                        )
                      }
                      return view.handle
                    })
                    .map((module, k) => (
                      <motion.li
                        className={styles.link}
                        key={`link-${k}`}
                        initial={{ opacity: 0, y: opened ? 0 : 15 }}
                        animate={{
                          opacity: 1,
                          y: 0,
                          transition: {
                            duration: 0.5,
                            delay: opened ? 0 : k * 0.06 + 0.15,
                          },
                        }}
                        onClick={() => {
                          handleHash(module?.handle)
                          setOpen(false)
                          gaEvent('Main Navigation', 'Click', module.label)
                        }}>
                        <HashLink
                          to={`/#${module?.handle}`}
                          tabIndex={open ? '0' : '-1'}>
                          {parseHtml(module.label, 'span')}
                        </HashLink>
                      </motion.li>
                    ))}
                </ul>

                <div className={styles.logoWrapper}>
                  <a
                    href="https://www.indeed.com?sid=us_cont_g_indeedlogo&kw=rtw&utm_medium=cont&utm_source=rtw&utm_content=indeedlogo"
                    target="_blank"
                    className={styles.logo}
                    rel="noreferrer"
                    onClick={() =>
                      gaEvent('Main Navigation', 'Click', 'Indeed Logo')
                    }
                    aria-label={t('ada:header.indeed_logo.aria')}>
                    <span aria-hidden="true">
                      <IndeedLogo alt="Indeed" />
                    </span>
                  </a>
                </div>
              </div>
            </div>

            <div
              className={styles.scrim}
              onClick={() => {
                setOpen(false)
              }}></div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default Hamburger

Hamburger.propTypes = {
  pageType: PropTypes.string,
  sticky: PropTypes.bool,
}
