import { useEffect, useState } from 'react'

export const useReducedMotionQuery = () => {
  const [reducedMotion, setReducedMotion] = useState(false)

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)')

    const update = (setting) => {
      setReducedMotion(setting)
    }

    mediaQuery.addEventListener('change', () => {
      update(mediaQuery.matches)
    })

    update(mediaQuery.matches)

    return () =>
      mediaQuery.removeEventListener('change', () => {
        update(mediaQuery.matches)
      })
  }, [])

  return reducedMotion
}
