import { useEffect, useState, useRef, useCallback } from 'react'
import styles from './index.module.scss'
import cn from 'classnames'
import PropTypes, { oneOfType } from 'prop-types'
import { ReactComponent as Arrow } from 'assets/images/chevron-up.svg'
import { useDocumentSize } from 'utils/useDocumentSize'
import { parseHtml } from 'utils/parseHtml'
import gaEvent from 'utils/analytics'

const Collapsible = ({
  title,
  children,
  active,
  id,
  setActiveFaq,
  isSubItem = false,
  disabled = false,
  className,
  gaEventData,
  headingLevel = 3,
  eventType,
}) => {
  const [containerHeight, setContainerHeight] = useState(0)
  const [buttonHeight, setButtonHeight] = useState(0)
  const [localActive, setLocalActive] = useState(active)
  const ref = useRef()
  const containerRef = useRef()
  const documentSize = useDocumentSize()

  // get content height for each sub item, add up to get total content height
  const getContentHeight = useCallback(() => {
    const allContent = containerRef.current.querySelectorAll(
      '.collapsible-content'
    )
    if (!allContent || allContent?.length < 1) return
    const allContentHeight = [...allContent]?.reduce(
      (sum, ele) => sum + ele?.getBoundingClientRect().height,
      buttonHeight + 40
    )

    return allContentHeight
  }, [containerRef, buttonHeight])

  useEffect(() => {
    if (localActive) {
      const currentEvent = containerRef?.current.querySelector('.current-event')
      if (!currentEvent) return
      setTimeout(() => {
        currentEvent.scrollIntoView()
      }, 300)
    }
  }, [localActive, containerRef])

  useEffect(() => {
    if (setActiveFaq) {
      setLocalActive(active)
    }
  }, [active, setActiveFaq])

  // use button height to set for original height
  useEffect(() => {
    if (ref.current) {
      setButtonHeight(ref?.current?.getBoundingClientRect().height)
    }

    if (containerRef.current) {
      const allContentHeight = getContentHeight()
      setContainerHeight(allContentHeight)
    }
  }, [localActive, documentSize, getContentHeight])

  function handleSetActiveFaq() {
    if (!localActive && gaEventData) {
      gaEvent(...gaEventData)
    }
    if (setActiveFaq) {
      setActiveFaq(localActive ? -1 : id)
      gaEvent('FAQ', 'Click', 'Expand Item', title)
    } else {
      setLocalActive(!localActive)
    }
  }

  const Tag = `h${headingLevel}`

  return (
    <div
      data-component="collapsible"
      className={cn(
        styles.wrapper,
        {
          [styles.active]: localActive,
          [styles.subItem]: isSubItem,
        },
        styles[eventType],
        className
      )}
      ref={containerRef}
      style={{
        '--container-height': `${containerHeight}px`,
        '--button-height': `${buttonHeight}px`,
        '--delay': 0.2 + id * 0.1 + 's',
      }}>
      <Tag
        className={cn(styles.headerWrapper, {
          [styles.active]: localActive,
        })}>
        <button
          disabled={disabled}
          className={styles.title}
          onClick={handleSetActiveFaq}
          aria-haspopup="true"
          aria-expanded={localActive ? 'true' : 'false'}
          ref={ref}>
          {isSubItem && !disabled && (
            <div className={styles.titlePrefix}>
              <div className={cn(styles.line, styles.lineHorizontal)}></div>
              <div className={cn(styles.line, styles.lineVertical)}></div>
            </div>
          )}
          <div className={styles.titleInner}>
            {title && parseHtml(title, 'span')}
          </div>

          {!isSubItem && (
            <div className={styles.titleCaret}>
              <Arrow />
            </div>
          )}
        </button>
      </Tag>
      <div
        className={cn(styles.contentWrapper, {
          [styles.active]: localActive,
        })}
        aria-hidden={localActive ? 'false' : 'true'}>
        <div className={cn(styles.content, 'collapsible-content')}>
          {children}
        </div>
      </div>
    </div>
  )
}

Collapsible.propTypes = {
  title: PropTypes.string,
  active: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  id: PropTypes.number,
  setActiveFaq: PropTypes.func,
  isSubItem: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  gaEventData: PropTypes.array,
  headingLevel: PropTypes.number,
  eventType: oneOfType([
    PropTypes.object,
    PropTypes.oneOf(['past', 'current']),
  ]),
}

export default Collapsible
