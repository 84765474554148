import PropTypes from 'prop-types'
import IconWrapper from 'components/pieces/IconWrapper'
import { useCopyToClipboard } from 'utils/useCopyToClipboard'
import { SOCIAL_SHARE } from 'constant/social'
import Toast from 'components/pieces/Toast'
import { useTranslation } from 'react-i18next'

const SocialShare = ({
  shareItems,
  content,
  hidden,
  twitterCopy,
  emailCopy,
  className,
}) => {
  const { isCopied, handleCopyClick } = useCopyToClipboard(content)
  const { t } = useTranslation('global')
  const socialShareTexts = t('social_share', { returnObjects: true })
  const items = shareItems.map((type) => ({
    ...SOCIAL_SHARE[type],
    ...socialShareTexts[type],
  }))

  return (
    <>
      {items.map((link, i) => {
        const { type, onAction } = link
        return (
          <IconWrapper
            className={className}
            type="outline"
            key={`icon-${i}`}
            outlineIcon
            aria={link?.label}
            aria-hidden={
              typeof hidden === 'undefined' || !hidden ? 'false' : 'true'
            }
            tabIndex={typeof hidden === 'undefined' || !hidden ? 0 : -1}
            onClick={
              type === 'link'
                ? handleCopyClick
                : () =>
                    onAction({
                      text: twitterCopy,
                      subject: emailCopy?.subject,
                      body: emailCopy?.body,
                    })
            }>
            <span aria-hidden="true"> {link.icon} </span>
          </IconWrapper>
        )
      })}

      <Toast show={isCopied} />
    </>
  )
}

SocialShare.propTypes = {
  shareItems: PropTypes.array,
  content: PropTypes.string,
  hidden: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  twitterCopy: PropTypes.string,
  emailCopy: PropTypes.shape({
    subject: PropTypes.string,
    body: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  }),
  className: PropTypes.string,
}

export default SocialShare
