import PropTypes from 'prop-types'
import { useState } from 'react'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import Container from 'components/layout/Container'
import Grid from 'components/layout/Grid'
import SectionHeader from '../../pieces/SectionHeader'
import Collapsible from 'components/pieces/Collapsible'
import { parseHtml } from 'utils/parseHtml'

const FAQ = ({ initActive, questions, eyebrow, title }) => {
  const isPostEvents = process.env.REACT_APP_STATE === 'events_over'
  const [activeFaq, setActiveFaq] = useState(initActive ?? 0)
  const { t } = useTranslation('faq')
  const defaultQuestions = t('questions', { returnObjects: true })
  const _questions = questions ?? defaultQuestions
  const _eyebrow = eyebrow ?? t('eyebrow')
  const _title = title ?? t('title')

  return (
    <Container>
      <Grid>
        <div className={styles.wrapper}>
          {_eyebrow && _title && (
            <SectionHeader eyebrow={_eyebrow} title={_title} />
          )}
          <div className={styles.list}>
            {_questions
              ?.filter((faq) => {
                if (isPostEvents) {
                  return faq?.hideOnPostEvents !== 'true'
                }
                return true
              })
              .map((faq, i) => {
                return (
                  <Collapsible
                    title={faq.title}
                    key={i}
                    id={i}
                    setActiveFaq={setActiveFaq}
                    active={i === activeFaq}
                    className={
                      i === activeFaq ? styles.active : styles.inactive
                    }>
                    {parseHtml(faq.description)}
                  </Collapsible>
                )
              })}
          </div>
        </div>
      </Grid>
    </Container>
  )
}

export default FAQ

FAQ.propTypes = {
  initActive: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  eyebrow: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  questions: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}
