import { createContext, useContext } from 'react'
import PropTypes from 'prop-types'

const IntroContext = createContext()

export function IntroContextWrapper({
  interruptIntro = false,
  setInterruptIntro,
  children,
}) {
  return (
    <IntroContext.Provider value={{ interruptIntro, setInterruptIntro }}>
      {children}
    </IntroContext.Provider>
  )
}

export function useIntroContext() {
  return useContext(IntroContext)
}

IntroContextWrapper.propTypes = {
  interruptIntro: PropTypes.bool,
  setInterruptIntro: PropTypes.func,
  children: PropTypes.node,
}
