import { Helmet } from 'react-helmet'
import { useEffect } from 'react'
import { gaPageview } from 'utils/analytics'
import Button from 'components/pieces/Button'

const NotFound = () => {
  useEffect(() => {
    gaPageview()
  }, [])

  return (
    <div>
      <Helmet>
        <title data-react-helmet="true">Not found</title>
        <meta
          property="og:title"
          content="Your journey to better work begins here."
          data-react-helmet="true"
        />
        <meta
          name="twitter:title"
          content="Your journey to better work begins here."
          data-react-helmet="true"
        />
        <meta
          name="description"
          content="Connect with actively hiring employers, get resume assistance, attend in-person events, access resources from our coalition and more."
          data-react-helmet="true"
        />
        <meta
          property="og:description"
          content="Connect with actively hiring employers, get resume assistance, attend in-person events, access resources from our coalition and more."
          data-react-helmet="true"
        />
        <meta
          name="twitter:description"
          content="Connect with actively hiring employers, get resume assistance, attend in-person events, access resources from our coalition and more."
          data-react-helmet="true"
        />
      </Helmet>
      <div className="slice basic-page">
        <h1>We can’t find this page</h1>
        <p>
          It looks like this page doesn’t exist or isn’t available right now.
          Check the URL for any typos or choose an option below.
        </p>

        <Button link={'/'}>
          Go <em>Ready to Work</em> home page
        </Button>
      </div>
    </div>
  )
}

export default NotFound
