export const scrollToSection = (id) => {
  const section = document.getElementById(id)
  section?.scrollIntoView()
}

export const scrollToHash = (hash) => {
  if (!hash || hash === '') return
  const _hash = hash.split('?')[0]
  const section = document.querySelector(_hash)
  if (!section) return
  section.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
    inline: 'center',
  })
}
