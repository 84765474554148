import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './index.module.scss'
import Button from 'components/pieces/Button'
import { ReactComponent as CloseIcon } from 'assets/images/close.svg'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const OccupationMeter = ({ rating }) => {
  return (
    <div
      className={cn(
        styles.meterContainer,
        rating.toLowerCase().replace(' ', '-')
      )}>
      <span className={styles.star}></span>
      <span className={styles.star}></span>
      <span className={styles.star}></span>
      <span className={styles.star}></span>
      <span className={styles.star}></span>
      <span className={styles.aria}>{rating}</span>
    </div>
  )
}

OccupationMeter.propTypes = {
  rating: PropTypes.string,
}

const OccupationDetails = ({
  className,
  occupation,
  setTargetOccupationId,
}) => {
  const { t } = useTranslation('progression')

  const handleClose = useCallback(() => {
    setTargetOccupationId(null)
  }, [setTargetOccupationId])

  const newSkills = useMemo(() => {
    return (
      occupation.next_skills?.filter(
        (skill) => !occupation.target_skills.includes(skill)
      ) || []
    )
  }, [occupation])

  return (
    <div className={cn(className, styles.container)}>
      <header className={styles.header}>
        <h3>{occupation?.role}</h3>
        <button
          aria-label="Close"
          onClick={handleClose}
          className={styles.close}>
          <CloseIcon className={styles.closeIcon} />
        </button>
      </header>
      <div className={styles.content}>
        <div className={styles.section}>
          <div className={styles.about}>
            <h4>{t('details.about_title')}</h4>
            <p>{occupation.job_description}</p>
          </div>
          <div className={styles.demand}>
            {occupation.medium_demand && occupation.medium_demand !== 'NA' && (
              <>
                <h4>{t('details.medium_demand_title')}</h4>
                <OccupationMeter rating={occupation.medium_demand} />
              </>
            )}
            {occupation.long_demand && occupation.long_demand !== 'NA' && (
              <>
                <h4>{t('details.long_demand_title')}</h4>
                <OccupationMeter rating={occupation.long_demand} />
              </>
            )}
            <h4>{t('details.pay_title')}</h4>
            <OccupationMeter rating={occupation.wage_group} />
            {!isNaN(parseInt(occupation.median_salary)) && (
              <p>
                <b>
                  ${parseInt(occupation.median_salary).toLocaleString('en-US')}
                </b>{' '}
                {t('details.pay_suffix')}
              </p>
            )}
          </div>
        </div>
        <div className={cn(styles.tags, styles.section)}>
          <div className={styles.fit}>
            <h4>{t('details.fit_title')}</h4>
            <ul>
              {!!parseInt(occupation.open_to_criminal_background) && (
                <li>{t('details.criminal')}</li>
              )}
              {!!parseInt(occupation.open_to_english_learners) && (
                <li>{t('details.english')}</li>
              )}
              {!!parseInt(occupation.certifications_needed) && (
                <li>{t('details.cert')}</li>
              )}
              {!!parseInt(occupation.flexible_hours) && (
                <li>{t('details.flexible')}</li>
              )}
            </ul>
          </div>
          <div className={styles.existingSkills}>
            <h4>{t('details.existing_skills')}</h4>
            <ul>
              {occupation.target_skills.map((skill) => (
                <li key={skill}>{skill}</li>
              ))}
            </ul>
          </div>
          {newSkills?.length > 0 && (
            <div className={styles.newSkills}>
              <h4>{t('details.new_skills')}</h4>
              <ul>
                {newSkills.map((skill) => (
                  <li key={skill}>{skill}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className={styles.ctaContainer}>
        <Button
          link={`https://www.indeed.com/jobs?q=${occupation.role}`}
          className={styles.cta}>
          {t('details.cta_label')}
        </Button>
      </div>
    </div>
  )
}

export default OccupationDetails

OccupationDetails.propTypes = {
  className: PropTypes.string,
  occupation: PropTypes.object,
  setTargetOccupationId: PropTypes.func,
}
