import PropTypes from 'prop-types'
import styles from './index.module.scss'
import cn from 'classnames'
import { motion } from 'framer-motion'
import { ReactComponent as Sun } from 'assets/images/sun.svg'
import { ReactComponent as Moon } from 'assets/images/moon.svg'
import gaEvent from 'utils/analytics'
import { useTranslation } from 'react-i18next'

const ThemeToggle = ({ theme, setTheme, location = 'header', pageType }) => {
  const { t } = useTranslation('ada')
  const updateTheme = () => {
    const newTheme = theme === 'dark' ? 'light' : 'dark'
    if (setTheme) {
      setTheme(newTheme)
    }
  }

  return (
    <motion.div
      className={cn(styles.container, styles[location], styles[pageType])}>
      <motion.div
        className={styles.circle}
        animate={{
          x: theme === 'dark' ? 50 : 0,
        }}
        transition={{
          type: 'spring',
          stiffness: 700,
          damping: 30,
        }}
        initial={false}></motion.div>

      <button
        onClick={() => {
          updateTheme()
          gaEvent(
            'Global',
            'Click',
            'Mode',
            theme === 'dark' ? 'light' : 'dark'
          )
        }}
        className={styles.button}
        aria-label={t('header.theme_toggle.aria', {
          theme:
            theme === 'dark'
              ? t('header.theme_toggle.light')
              : t('header.theme_toggle.dark'),
        })}>
        {['light', 'dark'].map((t, i) => (
          <span
            key={`button-${i}`}
            aria-hidden="true"
            className={cn(styles.icon, {
              [styles.active]: theme === t,
            })}>
            {t === 'dark' ? <Moon /> : <Sun />}
          </span>
        ))}
      </button>
    </motion.div>
  )
}

export default ThemeToggle

ThemeToggle.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark']),
  setTheme: PropTypes.func,
  location: PropTypes.oneOf(['location', 'hamburger']),
  pageType: PropTypes.string,
}
