export const getColor = (color) => {
  switch (color) {
    case 'white':
      return '#fff'
    case 'blue':
      return '#003a9b'
    case 'darkblue':
      return '#032677'
    case 'indigo':
      return '#001c40'
    case 'magenta':
      return '#c74289'
    case 'dark-teal':
      return '#358271'
    case 'teal':
      return '#207399'
    case 'purple':
      return '#5644bf'
    default:
      return null
  }
}
