import { format } from 'date-fns-tz'
import { zonedTimeToUtc } from 'date-fns-tz'

export const getDate = (event_date) => {
  const init_tz = 'America/New_York'

  // convert event date to UTC
  const utcDate = zonedTimeToUtc(event_date, init_tz)

  const target_tz = Intl.DateTimeFormat().resolvedOptions().timeZone

  const date = format(utcDate, 'MMM d, HH:mm zzz', {
    timeZone: target_tz,
  })

  return date
}
