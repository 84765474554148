/**
 * source: https://hiddedevries.nl/en/blog/2017-01-29-using-javascript-to-trap-focus-in-an-element
 */

import { useEffect } from 'react'

export const useTrapFocus = (element, modalOpen, firstElement) => {
  const KEYCODE_TAB = 9
  useEffect(() => {
    if (!element) return
    const focusableEls = element?.querySelectorAll(
      'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled]), div[tabindex="0"]'
    )

    const firstFocusableEl = firstElement ?? focusableEls[0]
    const lastFocusableEl = focusableEls[focusableEls.length - 1]

    const handleKeydown = (e) => {
      const isTabPressed = e.key === 'Tab' || e.keyCode === KEYCODE_TAB

      if (!isTabPressed) {
        return
      }

      if (e.shiftKey) {
        /* shift + tab */ if (document.activeElement === firstFocusableEl) {
          lastFocusableEl.focus()
          e.preventDefault()
        }
      } /* tab */ else {
        if (document.activeElement === lastFocusableEl) {
          firstFocusableEl.focus()
          e.preventDefault()
        }
      }
    }

    if (modalOpen) {
      firstFocusableEl.addEventListener('keydown', handleKeydown)
      lastFocusableEl.addEventListener('keydown', handleKeydown)

      return () => {
        firstFocusableEl.removeEventListener('keydown', handleKeydown)
        lastFocusableEl.removeEventListener('keydown', handleKeydown)
      }
    }
  }, [element, modalOpen, firstElement])
}
