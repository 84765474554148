import { useCallback, useLayoutEffect, useRef } from 'react'
import styles from './index.module.scss'

const svgDefs = `
  <defs>
    <marker
      id="arrow"
      orient="auto"
      markerWidth="12"
      markerHeight="12"
      refX="6" refY="3">
      <path d="M0,0 V6 L6,3 Z" fill="currentColor"/>
    </marker>
  </defs>
`

/**
 * Returns the position of an element's
 * left or right edge (centered vertically)
 * relative to a container
 *
 * @param {HTMLElement} element
 * @param {HTMLElement} container
 * @param {boolean} left
 * @returns {Array} [x, y] relative to container
 */
export const getEdgePosInContainer = (element, container, left = false) => {
  let localX = 0
  let localY = element.offsetHeight / 2

  if (left) {
    localX = 0
  } else {
    localX = element.offsetWidth
  }

  let currentElement = element
  let x = localX
  let y = localY

  do {
    x += currentElement.offsetLeft
    y += currentElement.offsetTop
    currentElement = currentElement.offsetParent
  } while (currentElement !== container)

  return [x, y]
}

export const setupLabelPath = (refs) => {
  if (!refs.container || !refs.nextLabel || !refs.targetLabel) {
    return
  }

  const startPos = getEdgePosInContainer(refs.nextLabel, refs.container)
  const endPos = getEdgePosInContainer(refs.targetLabel, refs.container, true)
  const distance = endPos[0] - startPos[0]

  let pathsBuffer = svgDefs

  pathsBuffer += `
    <path
      d="
        M ${startPos[0]} ${startPos[1]}
        C ${startPos[0] + distance * 0.25} ${startPos[1] + 8},
        ${endPos[0] - distance * 0.25} ${endPos[1] + 8},
        ${endPos[0]} ${endPos[1]}
      "
      stroke="currentColor"
      fill="transparent"
      class="${styles.line}"/>
  `

  refs.svg.innerHTML = pathsBuffer

  for (const path of refs.svg.querySelectorAll('path')) {
    path.style.setProperty('--length', path.getTotalLength())
  }
}

export const setupPaths = (refs) => {
  if (!refs.container || !refs.next || !refs.targets) {
    return
  }

  const startPos = getEdgePosInContainer(refs.next, refs.container)

  let pathsBuffer = svgDefs

  for (const target of refs.targets.children) {
    const endPos = getEdgePosInContainer(target, refs.container, true)
    const distance = endPos[0] - startPos[0]
    const lineCommandsBuffer = `
			M ${startPos[0]} ${startPos[1]}
			C ${startPos[0] + distance * 0.33} ${startPos[1]},
			${endPos[0] - distance * 0.33} ${endPos[1]},
			${endPos[0]} ${endPos[1]}
		`
    pathsBuffer += `
      <path
        d="${lineCommandsBuffer}"
        stroke="currentColor"
        fill="transparent"
        class="${styles.line}"/>`
  }

  refs.svg.innerHTML = pathsBuffer

  for (const path of refs.svg.querySelectorAll('path')) {
    path.style.setProperty('--length', path.getTotalLength())
  }
}

export const usePaths = ({ hasOccupations, loadingTargetOccupations }) => {
  const containerRef = useRef(null)
  const labelSvgRef = useRef(null)
  const svgRef = useRef(null)
  const svgRefWithCallback = useCallback((node) => {
    // Only set the ref if its not null
    if (node) {
      svgRef.current = node
    }
  }, [])
  const nextLabelRef = useRef(null)
  const nextRef = useRef(null)
  const targetLabelRef = useRef(null)
  const targetsRef = useRef(null)

  useLayoutEffect(() => {
    if (hasOccupations && labelSvgRef.current) {
      const currentRefs = {
        container: containerRef.current,
        svg: labelSvgRef.current,
        nextLabel: nextLabelRef.current,
        targetLabel: targetLabelRef.current,
      }

      const setupLabelPathWithRefs = () => {
        setupLabelPath(currentRefs)
      }
      setupLabelPathWithRefs()
      window.addEventListener('resize', setupLabelPathWithRefs)

      return () => {
        window.removeEventListener('resize', setupLabelPathWithRefs)
      }
    }
  }, [hasOccupations])

  useLayoutEffect(() => {
    if (!loadingTargetOccupations && svgRef.current) {
      const currentRefs = {
        container: containerRef.current,
        svg: svgRef.current,
        nextLabel: nextLabelRef.current,
        next: nextRef.current,
        targetLabel: targetLabelRef.current,
        targets: targetsRef.current,
      }

      const setupPathsWithRefs = () => {
        setupPaths(currentRefs)
        svgRef.current.style.height = `${
          svgRef.current.getBoundingClientRect().height
        }px`
      }
      setupPathsWithRefs()
      window.addEventListener('resize', setupPathsWithRefs)

      return () => {
        window.removeEventListener('resize', setupPathsWithRefs)
      }
    }
  }, [loadingTargetOccupations, svgRef])

  return {
    containerRef,
    labelSvgRef,
    svgRef: svgRefWithCallback,
    nextLabelRef,
    nextRef,
    targetLabelRef,
    targetsRef,
  }
}
