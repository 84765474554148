import { useCallback, useState } from 'react'
import cn from 'classnames'
import styles from './index.module.scss'
import Container from 'components/layout/Container'
import Grid from 'components/layout/Grid'
import SectionHeader from 'components/pieces/SectionHeader'
import Button from 'components/pieces/Button'
import OccupationSelector from '../OccupationSelector'
import { useProgressionState } from './lib'
import OccupationTable from '../OccupationTable'
import OccupationDetails from '../OccupationDetails'
import { ReactComponent as ToolTip } from 'assets/images/tooltip.svg'
import PropTypes from 'prop-types'
import Modal from 'components/pieces/Modal'
import { useTranslation } from 'react-i18next'
import { parseHtml } from 'utils/parseHtml'
import { useTooltip as useTip } from '@react-aria/tooltip'
import { useTooltip } from 'utils/tooltip'
import { AnimatePresence, motion } from 'framer-motion'
import { mergeProps } from 'react-aria'
import Feedback from 'components/pieces/Feedback'
import IconWrapper from 'components/pieces/IconWrapper'
import { ReactComponent as Exit } from 'assets/images/exit.svg'

const DescriptionTooltip = ({ state, ...props }) => {
  let { tooltipProps } = useTip(props, state)

  return (
    <span className={styles.tooltip} {...mergeProps(props, tooltipProps)}>
      {props.children}
    </span>
  )
}

DescriptionTooltip.propTypes = {
  state: PropTypes.object,
  children: PropTypes.node,
}

const Progression = () => {
  const { t } = useTranslation('progression')
  const [submittedFeedback, setSubmittedFeedback] = useState(false)
  const [showFeedbackWidget, setShowFeedbackWidget] = useState(true)
  const state = useProgressionState()
  const { tooltipRef, tooltipState, tooltipProps, triggerProps } = useTooltip(
    t('tooltip')
  )

  const setModalOpen = useCallback(
    (open) => {
      if (!open) {
        state.setTargetOccupation(null)
      }
    },
    [state]
  )

  return (
    <Container className={cn(styles.container)}>
      <Grid className={styles.grid}>
        <div className={styles.inner}>
          <SectionHeader
            eyebrow={t('eyebrow')}
            title={t('title')}
            description={
              <p>
                {t('description')}{' '}
                <button ref={tooltipRef} {...triggerProps}>
                  <ToolTip alt="Info" className={styles.tooltipIcon} />
                </button>
              </p>
            }
          />
          <div className={styles.selectorContainer}>
            <OccupationSelector
              className={styles.selector}
              editing={state.editingOccupation}
              setEditing={state.setEditingOccupation}
              selectedOccupation={state.currentOccupationTitle}
              onSelectionChange={state.setOccupationId}
            />
            <AnimatePresence>
              {tooltipState.isOpen && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.1 }}
                  className={styles.tooltipContainer}>
                  <DescriptionTooltip state={tooltipState} {...tooltipProps}>
                    {parseHtml(t('tooltip'))}
                  </DescriptionTooltip>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          {state.editingOccupation && (
            <Button
              disabled={!state.occupationId}
              onClick={state.confirmOccupation}
              className={styles.selectorConfirm}>
              {state.currentOccupationTitle
                ? t('selector.update_label')
                : t('selector.confirm_label')}
            </Button>
          )}
          <div className={styles.occupationsContainer}>
            {state.currentOccupationTitle && (
              <OccupationTable
                className={styles.occupations}
                occupationsSalaryDetails={state.occupationsSalaryDetails}
                loadingNextOccupations={state.loadingNextOccupations}
                hasLoadedFirstOccupations={state.hasLoadedFirstOccupations}
                nextOccupations={state.nextOccupations}
                loadingTargetOccupations={state.loadingTargetOccupations}
                targetOccupations={state.targetOccupations}
                nextOccupationId={state.nextOccupationId}
                onNextOccupationSelect={state.setNextOccupationId}
                onTargetOccupationSelect={
                  state.setTargetOccupationId
                }></OccupationTable>
            )}
            <Modal
              className={styles.modal}
              closeClassName={styles.modalClose}
              open={!!state.selectedTargetOccupation}
              setOpen={setModalOpen}>
              <div className={styles.detailsModalContainer}>
                {state.selectedTargetOccupation && (
                  <OccupationDetails
                    className={styles.details}
                    occupation={state.selectedTargetOccupation}
                    setTargetOccupationId={state.setTargetOccupationId}
                  />
                )}
              </div>
            </Modal>
          </div>

          <div className={styles.footer}>
            {showFeedbackWidget && state.currentOccupationTitle && (
              <div className={styles.feedback}>
                <IconWrapper
                  aria={t('global.close')}
                  onClick={() => {
                    setShowFeedbackWidget(false)
                  }}
                  wrapperClassName={styles.exit}>
                  <Exit />
                </IconWrapper>

                {!submittedFeedback && (
                  <Feedback
                    onScore={async (score) => {
                      await fetch(
                        `${process.env.REACT_APP_API_URL}/readytowork/api/feedback/?format=json`,
                        {
                          method: 'POST',
                          headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify({ score }),
                        }
                      )
                    }}
                    onSubmit={async (score, feedback) => {
                      await fetch(
                        `${process.env.REACT_APP_API_URL}/readytowork/api/feedback/?format=json`,
                        {
                          method: 'POST',
                          headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify({ score, feedback }),
                        }
                      )
                      setSubmittedFeedback(true)
                    }}></Feedback>
                )}
                {submittedFeedback && (
                  <div className={styles.feedback}>
                    <p style={{ textAlign: 'center' }}>
                      Thanks for your feedback!
                    </p>
                  </div>
                )}
              </div>
            )}

            <div className={styles.footerCopy}>
              {parseHtml(t('footer.copy'))}
            </div>
          </div>
        </div>
      </Grid>
    </Container>
  )
}

export default Progression
