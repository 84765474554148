import PropTypes from 'prop-types'
import styles from './index.module.scss'
import cn from 'classnames'

const SizerWrapper = ({ aspectRatio = 1, className, children }) => {
  return (
    <div
      className={cn(styles.wrapper, className)}
      style={{ paddingTop: `var(--pt, ${aspectRatio * 100 + '%'})` }}>
      <div className={styles.inner}>{children}</div>
    </div>
  )
}

export default SizerWrapper

SizerWrapper.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  aspectRatio: PropTypes.number,
}
