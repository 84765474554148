import PropTypes from 'prop-types'
import styles from './index.module.scss'
import cn from 'classnames'
import ReactModal from 'react-modal'
import { useRef, useEffect } from 'react'
import { ReactComponent as CloseIcon } from 'assets/images/close.svg'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

ReactModal.setAppElement('#root')

const Modal = ({
  open,
  setOpen,
  children,
  className = '',
  overlayClassName = '',
  closeClassName = '',
}) => {
  const { t } = useTranslation('ada')
  const modalRef = useRef()
  const closeRef = useRef()
  const location = useLocation()

  useEffect(() => {
    document.documentElement.classList.remove('disable-scroll')
  }, [])

  useEffect(() => {
    document.documentElement.classList.toggle('disable-scroll', open)
  }, [open])

  // click an link within the modal should close the modal
  useEffect(() => {
    if (!modalRef?.current.node) return

    if (open) {
      setTimeout(() => {
        const links = modalRef?.current?.node?.querySelectorAll('a[href]')
        links?.forEach((link) => {
          link?.addEventListener('click', () => {
            setOpen(false)
          })

          return () =>
            link?.removeEventListener('click', () => {
              setOpen(false)
            })
        })
      }, 300)
    }
  }, [modalRef, location, open, setOpen])

  return (
    <ReactModal
      ref={modalRef}
      isOpen={open}
      onRequestClose={() => {
        setOpen(false)
      }}
      className={cn(className, styles.content, 'dark')}
      overlayClassName={cn(overlayClassName, styles.overlay)}>
      <button
        aria-label={t('modal.close.aria')}
        ref={closeRef}
        className={cn(closeClassName, styles.close)}
        onClick={() => {
          setOpen(false)
        }}>
        <CloseIcon />
      </button>
      {children}
    </ReactModal>
  )
}
Modal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
  closeClassName: PropTypes.string,
}
export default Modal
