import PropTypes from 'prop-types'
// import cn from 'classnames'
// import styles from './index.module.scss'
import { DismissButton, FocusScope, useOverlay } from 'react-aria'
import { useRef } from 'react'

const Popover = (props) => {
  // Get props for the menu item element
  let ref = useRef()
  let { popoverRef = ref, isOpen, onClose, children } = props

  // Handle events that should cause the popup to close,
  // e.g. blur, clicking outside, or pressing the escape key.
  let { overlayProps } = useOverlay(
    {
      isOpen,
      onClose,
      shouldCloseOnBlur: true,
      isDismissable: true,
    },
    popoverRef
  )

  // Add a hidden <DismissButton> component at the end of the popover
  // to allow screen reader users to dismiss the popup easily.
  return (
    <FocusScope restoreFocus>
      <div {...overlayProps} ref={popoverRef}>
        {children}
        <DismissButton onDismiss={onClose} />
      </div>
    </FocusScope>
  )
}

Popover.propTypes = {
  popoverRef: PropTypes.any,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
}
export default Popover
