import { addHours, isPast } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
// use 1 hour past start date to evaluate if it is a past event
export const isPastEvent = (date) => {
  const endTime = addHours(zonedTimeToUtc(date, 'America/New_York'), 1)

  return isPast(endTime)
}

export const resolveDate = (event) => {
  const date = new Date(event?.datetime)
  return date
}
