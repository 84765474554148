// Use multiple refs on a single element
export const mergeRefs = (...refs) => {
  // remove all empty items from array
  const filteredRefs = refs.filter((item) => Boolean(item))
  if (!filteredRefs.length) return null
  if (filteredRefs.length === 0) return filteredRefs[0]
  return (inst) => {
    for (const ref of filteredRefs) {
      if (typeof ref === 'function') {
        ref(inst)
      } else if (ref) {
        ref.current = inst
      }
    }
  }
}
