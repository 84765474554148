/* eslint-disable no-unused-vars */
import styles from './index.module.scss'
import Grid from 'components/layout/Grid'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import RichTextField from 'components/pieces/RichTextField'
import Button from 'components/pieces/Button'
import Collapsible from 'components/pieces/Collapsible'
// import { getColor } from 'utils/getColor'
import cn from 'classnames'
import { ReactComponent as Arrow } from 'assets/images/arrow.svg'
import Image from 'components/pieces/Image'
import SocialShare from 'components/pieces/ShareButtons/SocialShare'
import eventShape from 'shapes/event'
import MarketoForm from './MarketoForm'
import { useState, useRef } from 'react'
import { parseHtml } from 'utils/parseHtml'
import Card from 'components/pieces/Card/Base'
import lyftIcon from 'assets/images/lyft.png'
import Modal from 'components/pieces/Modal'
import FAQ from '../FAQ'
import { FREE_EVENTS_SOCIAL_SHARE } from 'constant/social'
import Schedule from './Schedule'
import { getCodes } from '../WorkEssentials/lib'
import { isAfter } from 'date-fns'

const EventPage = ({ event }) => {
  const [hide, setHide] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const formRef = useRef()

  const {
    title,
    description,
    date,
    time,
    map,
    schedule,
    address,
    location,
    bgColor,
    imagePosition,
    twitterShareCopy,
    emailShareCopy,
    formId,
    formIdEs,
    schedule_notice,
    datetime_end,
    coalition_title,
    coalition,
  } = event || {}

  const { t } = useTranslation('events', 'essentials', 'common')
  const brands = t('essentials:brands', { returnObjects: true })

  const { i18n } = useTranslation()
  const formIdLocale = i18n.language === 'es' && formIdEs ? formIdEs : formId
  const code = getCodes(event)
  const isPastEvent = isAfter(new Date(), new Date(datetime_end))
  const showPastEventState = formIdLocale !== 'es' && isPastEvent

  return (
    <div
      className={cn(styles.wrapper, styles.wrapper)}
      style={{ '--theme-color': bgColor }}>
      <Grid>
        <div className={styles.bg}>
          <div
            className={styles.image}
            style={{ '--image-position': imagePosition }}>
            <Image image={{ url: map }} layout="fill" />
          </div>
        </div>
        {!showPastEventState && (
          <div className={styles.content}>
            <Link to="/#events" className={styles.back} exact="true">
              <div className={styles.icon}>
                <Arrow />
              </div>
              {t('labels.back_cta')}
            </Link>
            <div className={styles.eyebrow}> {t('labels.upcoming_events')}</div>
            <div className={styles.title}>
              <h1>{title} </h1>
            </div>
            <div className={styles.date}>
              {date && parseHtml(date.replace('\n', '<br>'), 'span')}
              {time && (
                <>
                  <br />
                  {time}
                </>
              )}

              {address && (
                <span className={styles.address}>{parseHtml(address)}</span>
              )}
            </div>

            <RichTextField
              text={parseHtml(description)}
              className={styles.text}
            />
            <div className={styles.iconWrapper}>
              <SocialShare
                shareItems={FREE_EVENTS_SOCIAL_SHARE}
                content={window?.location?.href}
                twitterCopy={twitterShareCopy}
                emailCopy={emailShareCopy}
                className={styles.iconWrapperInner}
              />
            </div>

            <Button
              onClick={() => {
                formRef?.current && formRef.current.scrollIntoView()
              }}
              gaEventData={[`Event Page - ${title}`, 'Click', 'Register']}
              className={styles.cta}>
              {t('labels.register_cta')}
            </Button>

            <Collapsible
              title={t('labels.location')}
              gaEventData={[
                `Event Page - ${title}`,
                'Click',
                'Location Details Expand',
              ]}
              headingLevel={2}
              className={styles.dropdown}>
              <div className={styles.expandedContent}>
                {parseHtml(location)}
              </div>
            </Collapsible>
            <Collapsible
              title={t('labels.schedule')}
              headingLevel={2}
              gaEventData={[
                `Event Page - ${title}`,
                'Click',
                'Schedule Expand',
              ]}
              className={styles.dropdown}>
              {schedule_notice && parseHtml(schedule_notice)}
              <div className={styles.expandedContent}>
                {typeof schedule === 'string' ? (
                  parseHtml(schedule)
                ) : (
                  <Schedule schedule={schedule} />
                )}
              </div>
            </Collapsible>
            <Collapsible
              title={t('labels.faq')}
              headingLevel={2}
              gaEventData={[`Event Page - ${title}`, 'Click', 'FAQ Expand']}
              className={styles.dropdown}>
              <div className={styles.expandedContent}>
                {parseHtml(t('FAQs'))}
              </div>
            </Collapsible>

            {code && code !== '' && (
              <div className={styles.lyft}>
                <Card>
                  <div className={styles.lyftTop}>
                    <p className={styles.lyftDescription}>
                      <div className={styles.lyftTitle}>
                        {t('labels.lyft_cta.title')}
                      </div>
                      {parseHtml(t('labels.lyft_cta.description'))}
                    </p>
                    <div className={styles.lyftIcon}>
                      <Image
                        image={{ url: lyftIcon }}
                        alt="Lyft"
                        width={1280}
                        height={907}
                      />
                    </div>
                  </div>
                  <Button
                    className={styles.lyftCta}
                    type="whitebg"
                    link={t('labels.lyft_cta.cta_link')}>
                    {t('labels.lyft_cta.cta_text')}
                  </Button>

                  <button
                    aria-label="frequently asked questions"
                    className={styles.lyftFaq}
                    onClick={() => setModalOpen(true)}>
                    {t('common:FAQ')}
                  </button>
                </Card>
              </div>
            )}

            <div ref={formRef}>
              <MarketoForm
                successMessage={t('labels.marketo_success_message')}
                formId={formIdLocale}
                hide={hide}
                setHide={setHide}
                gaEventData={[`Event Page - ${title}`, 'Submit']}
              />
            </div>
            {/* hide virtual cta on es  */}
            {i18n.language === 'en' && (
              <>
                <div className={styles.text}>
                  {t('labels.virtual_events_cta.description')}
                </div>
                <Button
                  link="/#virtual"
                  type="outline"
                  gaEventData={[`Event Page - ${title}`, 'Click', 'See more']}
                  className={styles.virtualCta}>
                  {t('labels.virtual_events_cta.cta_text')}
                </Button>
              </>
            )}

            <div className={styles.text}>
              {parseHtml(t('labels.social_share_cta.description'))}
            </div>
          </div>
        )}
        {showPastEventState && (
          <div className={styles.content}>
            <Link to="/#events" className={styles.back} exact="true">
              <div className={styles.icon}>
                <Arrow />
              </div>
              {t('labels.back_cta')}
            </Link>
            <div className={styles.eyebrow}>
              {' '}
              {t('labels.past_events_eyebrow')}
            </div>
            <div className={styles.title}>
              <h1>{title} </h1>
            </div>
            <RichTextField
              text={parseHtml(t('labels.past_event_description'))}
              className={cn(styles.text, styles.description)}
            />

            <Button
              link="/#virtual"
              gaEventData={[`Event Page - ${title}`, 'Click', 'Virtual Events']}
              className={styles.cta}>
              {t('labels.virtual_events_cta.cta_text')}
            </Button>

            <div className={styles.subtitle}>
              <h2>{coalition_title}</h2>
            </div>
            <div className={styles.text}>{parseHtml(coalition)}</div>

            <div className={cn(styles.text, styles.description)}>
              {parseHtml(t('labels.social_share_cta.description'))}
            </div>
          </div>
        )}
      </Grid>

      <Modal open={modalOpen} setOpen={setModalOpen}>
        <FAQ
          questions={brands[0]?.faq}
          title={null}
          eyebrow={null}
          initActive={-1}
        />
      </Modal>
    </div>
  )
}

export default EventPage

EventPage.propTypes = {
  event: eventShape,
}
