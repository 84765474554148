/* eslint-disable jsx-a11y/no-autofocus */
import PropTypes from 'prop-types'
import { Item } from '@react-stately/collections'
import MenuButton from '../MenuButton'

const Dropdown = ({ items, label, ...props }) => {
  return (
    <MenuButton {...props} items={items} label={label}>
      {items?.map((item) => (
        <Item key={`${item}`}>{item}</Item>
      ))}
    </MenuButton>
  )
}

Dropdown.propTypes = {
  items: PropTypes.array,
  label: PropTypes.string,
  onAction: PropTypes.func,
}
export default Dropdown
