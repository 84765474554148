import PropTypes from 'prop-types'
// import { useState } from 'react'
import cn from 'classnames'
import styles from './index.module.scss'
import Grid from 'components/layout/Grid'
import Collapsible from 'components/pieces/Collapsible'
import Bio from 'components/pieces/Bio'
import { isPast } from 'date-fns'

const Schedule = ({ schedule }) => {
  // find the first event that is current
  const getActiveEvent = () => {
    const currentTime = new Date()
    const currentActive = schedule?.findIndex((eventDay) => {
      const eventTime = new Date(eventDay.datetime)
      const isPastEvent = isPast(eventTime, currentTime)
      return !isPastEvent
    })

    return currentActive
  }

  const activeEventIndex = getActiveEvent() ?? 0

  return (
    <>
      {schedule?.map((eventDay, i) => {
        return (
          <Collapsible
            eventType={i < activeEventIndex ? 'past' : 'current'}
            className={cn({
              'current-event': i === activeEventIndex,
            })}
            title={eventDay.date}
            isSubItem
            active={i === activeEventIndex}
            key={eventDay.date}
            disabled={!eventDay?.itinerary || eventDay?.itinerary?.length < 1}>
            {eventDay?.itinerary?.map((event) => (
              <Grid className={styles.grid} key={event.title}>
                <div className={styles.time}>{event?.time}</div>
                <div className={styles.title}>{event.title}</div>
                {event?.bio && (
                  <Bio
                    speaker={event?.bio.speaker}
                    description={event?.bio.description}
                    links={event?.bio.links}
                    headshot={event?.bio?.headshot}
                  />
                )}
              </Grid>
            ))}
          </Collapsible>
        )
      })}
    </>
  )
}

export default Schedule

Schedule.propTypes = {
  schedule: PropTypes.array,
}
