import PropTypes from 'prop-types'
import IconWrapper from 'components/pieces/IconWrapper'
import { ReactComponent as Copy } from 'assets/images/copy.svg'
import { ReactComponent as Link } from 'assets/images/link.svg'
import { useCopyToClipboard } from 'utils/useCopyToClipboard'
import Toast from 'components/pieces/Toast'
import { useTranslation } from 'react-i18next'

const CopyToShare = ({ type, label, content }) => {
  const { isCopied, handleCopyClick } = useCopyToClipboard(content)
  const { t } = useTranslation('common')

  let icon
  switch (type) {
    case 'link':
      icon = <Link />

      break

    case 'copy':
      icon = <Copy />

      break
    default:
  }

  return (
    <>
      <IconWrapper onClick={handleCopyClick} aria={label} outlineIcon={true}>
        {icon}
      </IconWrapper>
      <Toast
        show={isCopied}
        message={type === 'copy' ? t('questions_copied') : undefined}
      />
    </>
  )
}

CopyToShare.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  content: PropTypes.any,
}

export default CopyToShare
