/* eslint-disable no-undef */
import PropTypes from 'prop-types'
import styles from './index.module.scss'
import cn from 'classnames'
import Header from '../Header/_Index'
import Footer from '../Footer'
import { ThemeContextWrapper } from './themeContext'
import { SeekerStatusWrapper } from './seekerStatusContext'
import { useTheme } from 'utils/useTheme'
import { useEffect, useState, useRef } from 'react'
import { PageLineSVG } from 'components/pieces/PageLine'
import ScrollToTop from 'components/pieces/ScrollToTop'
import SkipToMain from 'components/pieces/SkipToMain'
import BackToTop from 'components/pieces/BackToTop'
import { ReactComponent as BgDark } from 'assets/images/page-bg.svg'
import { ReactComponent as BgLight } from 'assets/images/page-bg-light.svg'
import { useLocation } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import { AnimatePresence, motion } from 'framer-motion'
import { useReducedMotionQuery } from 'utils/useReducedMotionQuery'
import { scrollToHash } from 'utils/scrollToSection'

const Layout = ({
  pageTheme,
  pageType,
  pageLine,
  hideFooter,
  headerPosition = 'relative',
  showDunkin = false,
  hideBackToTop,
  children,
  whiteLogo = false,
}) => {
  const [theme, setTheme] = useTheme()
  const [loaded, setLoaded] = useState(false)
  const ref = useRef()
  const location = useLocation()
  const reducedMotion = useReducedMotionQuery()

  useEffect(() => {
    if (ref.current) {
      setLoaded(true)
    }
  }, [ref])

  // calculate the scrollbar width
  useEffect(() => {
    const body = document?.documentElement
    if (body) {
      const scrollbarWidth = window?.innerWidth - body?.clientWidth + 'px'
      body.style.setProperty('--scrollbar-width', scrollbarWidth)
    }
  }, [ref])

  const _theme = pageTheme ?? theme

  useEffect(() => {
    setTimeout(() => {
      if (location.hash) {
        scrollToHash(location.hash)
      }
    }, 1000)
  }, [location?.hash])

  useEffect(() => {
    document.documentElement.classList.toggle('dark', _theme === 'dark')
    document.documentElement.classList.toggle('light', _theme === 'light')
  }, [_theme])

  return (
    <ThemeContextWrapper theme={_theme} setTheme={setTheme}>
      <SeekerStatusWrapper>
        <ScrollToTop />
        <div
          className={cn(
            styles.container,
            {
              [styles.loaded]: loaded,
              [styles.fixedHeader]: headerPosition === 'fixed',
            },
            styles[pageType]
          )}
          ref={ref}>
          <SkipToMain />
          {!showDunkin && (
            <Header
              theme={theme}
              setTheme={setTheme}
              hideExtra={!!pageTheme}
              position={headerPosition}
              whiteLogo={whiteLogo}
              pageType={pageType}
            />
          )}
          <main
            id="main"
            role="main"
            className={styles.main}
            style={{
              position: 'relative',
              // apply a minimum padding so the height of the section inside include it's margin
              paddingBottom: '0.1px',
            }}>
            <div style={{ position: 'relative', zIndex: 2 }}>
              <Toaster position="bottom-center" />
              {children}
            </div>
            {pageLine && !showDunkin && !reducedMotion && <PageLineSVG />}
            {!hideFooter && !hideBackToTop && !showDunkin && <BackToTop />}
            <div className={cn(styles.bg, styles[theme])} id="page-bg">
              <AnimatePresence exitBeforeEnter initial={false}>
                <div className={styles.bgGradient}>
                  <motion.span
                    className={styles.bgWrapper}
                    key={theme + 'bg'}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { duration: 0.45 } }}
                    exit={{
                      opacity: 0,
                      transition: { duration: 0.15 },
                    }}>
                    {_theme === 'light' ? <BgLight /> : <BgDark />}
                  </motion.span>
                </div>
              </AnimatePresence>
            </div>
          </main>
          {!hideFooter && !showDunkin && <Footer />}
        </div>
      </SeekerStatusWrapper>
    </ThemeContextWrapper>
  )
}

export default Layout

Layout.propTypes = {
  pageTheme: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.oneOf(['dark', 'light']),
  ]),
  pageType: PropTypes.string,
  showDunkin: PropTypes.bool,
  pageLine: PropTypes.bool,
  hideFooter: PropTypes.bool,
  hideBackToTop: PropTypes.bool,
  headerPosition: PropTypes.oneOf(['relative', 'fixed']),
  children: PropTypes.node,
  whiteLogo: PropTypes.bool,
}
