import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Container from 'components/layout/Container'
import Grid from 'components/layout/Grid'
import SectionHeader from 'components/pieces/SectionHeader'
import Video from 'components/pieces/Video'
import Button from 'components/pieces/Button'
import styles from './index.module.scss'

import { useNavigate } from 'react-router-dom'
import gaEvent from 'utils/analytics'
import { imageResolver } from 'utils/imageResolver'

const AskTheRightQuestions = () => {
  const { t } = useTranslation('questions')
  const [term, setTerm] = useState('')
  const navigate = useNavigate()
  const onChange = (e) => {
    setTerm(e?.target?.value)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    gaEvent('The right questions to ask', 'Submit', 'Interview Prep', term)
    // add additonal query to url to distinguish deeplinked vs linked from home page
    term && term !== '' && navigate(`/questions-to-ask/${term}?from=home`)
  }

  return (
    <section>
      <Container>
        <Grid className={styles.grid}>
          <div className={styles.wrapper}>
            <SectionHeader
              eyebrow={t('eyebrow')}
              title={t('title')}
              description={t('description')}
            />
          </div>
          <div className={styles.video}>
            <Video
              rounded
              videoUrl={t('video.video_url')}
              gaEventData={[
                'The right questions to ask',
                'Click',
                'Video Play',
              ]}
              videoPoster={imageResolver(t('video.video_poster'))}
              videoPosterAlt={t('video.video_poster_alt')}></Video>
          </div>
          <form className={styles.form} onSubmit={onSubmit}>
            <div className={styles.fieldGroup}>
              <label className={styles.label} htmlFor="jobTitleSelector">
                {t('labels.input_label')}
              </label>
              <input
                className={styles.input}
                id="jobTitleSelector"
                onChange={onChange}
                value={term}
                placeholder={t('labels.input_placeholder')}
              />
            </div>
            <Button
              fullWidth
              onClick={onSubmit}
              gaEventData={[
                ['The right questions to ask', 'Click', 'More Resources'],
              ]}
              disabled={term === '' || !term}>
              {t('labels.submit_button_label')}
            </Button>
          </form>
        </Grid>
      </Container>
    </section>
  )
}

export default AskTheRightQuestions
