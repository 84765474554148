import { useEffect, useMemo, useState } from 'react'
import { Item } from 'react-stately'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './index.module.scss'
import { fetchOccupations } from 'constant/occupations'
import ComboBox from 'components/pieces/ComboBox/_Index'
import { ReactComponent as Background } from 'assets/images/occupations-bg.svg'
import { ReactComponent as Pencil } from 'assets/images/pencil.svg'
import { ReactComponent as Search } from 'assets/images/search.svg'
import { useTranslation } from 'react-i18next'

const OccupationSelector = ({
  className,
  selectedOccupation,
  editing,
  setEditing,
  onSelectionChange,
}) => {
  const [allOccupations, setAllOccupations] = useState({})
  const { t } = useTranslation('progression')
  const { i18n } = useTranslation()
  useEffect(() => {
    async function fetcher() {
      const response = await fetchOccupations(i18n.language)
      // console.log('res', response)
      setAllOccupations(response)
    }
    fetcher()
  }, [i18n.language])

  const categories = useMemo(
    () => Object.keys(allOccupations).sort((a, b) => a.localeCompare(b)),
    [allOccupations]
  )
  const [category, setCategory] = useState(null)
  const [occupationId, setOccupationId] = useState(null)
  const occupations = allOccupations[category] ?? []

  const handleOccupationSelection = (id) => {
    setOccupationId(id)
    onSelectionChange(id)
  }

  return (
    <div className={cn(className, styles.container)}>
      <div className={styles.backgroundContainer}>
        <Background alt="" className={styles.background} />
      </div>
      {selectedOccupation && (
        <div className={cn(styles.selectedContainer)}>
          <p className={styles.selectedLabel}>{t('selector.results_label')}</p>
          <button
            aria-label={selectedOccupation}
            onClick={() => {
              setEditing(true)
            }}
            className={styles.selectedButton}>
            <h3>
              {selectedOccupation}{' '}
              <Pencil alt="Edit" className={styles.selectedIcon} />
            </h3>
          </button>
        </div>
      )}
      {editing && (
        <div className={styles.combos}>
          <ComboBox
            menuTrigger="focus"
            className={styles.combo}
            labelClassName={styles.comboLabel}
            inputClassName={styles.comboInput}
            popoverClassName={styles.comboPopover}
            listboxClassName={styles.comboListbox}
            itemClassName={styles.comboItem}
            selectedKey={category}
            onSelectionChange={setCategory}
            label={t('selector.industry_label')}>
            {categories.map((category) => (
              <Item key={category}>{category}</Item>
            ))}
          </ComboBox>
          <ComboBox
            menuTrigger="focus"
            icon={<Search alt="" className={styles.searchIcon} />}
            className={cn(styles.combo, { [styles.disabled]: !category })}
            labelClassName={styles.comboLabel}
            inputWrapperClassName={styles.comboInputWrapper}
            inputClassName={cn(styles.occupationInput, styles.comboInput)}
            popoverClassName={styles.comboPopover}
            listboxClassName={styles.comboListbox}
            itemClassName={styles.comboItem}
            selectedKey={occupationId}
            onSelectionChange={handleOccupationSelection}
            defaultItems={occupations}
            label={t('selector.occupation_label')}>
            {(item) => <Item>{item.title}</Item>}
          </ComboBox>
        </div>
      )}
    </div>
  )
}

export default OccupationSelector

OccupationSelector.propTypes = {
  className: PropTypes.string,
  selectedOccupation: PropTypes.string,
  editing: PropTypes.bool,
  setEditing: PropTypes.func,
  onSelectionChange: PropTypes.func,
}
