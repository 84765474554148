import PropTypes from 'prop-types'
import styles from './index.module.scss'
import { useEffect } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

const Toast = ({ show, message }) => {
  const { t } = useTranslation('common')
  const DEFAULT_MESSAGE = t('copied message')
  useEffect(() => {
    if (show) {
      toast(message ? message : DEFAULT_MESSAGE, {
        className: 'toast',
        duration: 2000,
      })
    }
  }, [DEFAULT_MESSAGE, message, show])
  return <div className={styles.message}></div>
}

Toast.propTypes = {
  show: PropTypes.bool,
  message: PropTypes.string,
}
export default Toast
