// import PropTypes from 'prop-types'
import styles from './index.module.scss'
import cn from 'classnames'
import { Fragment } from 'react'
import InViewReveal from 'components/pieces/InViewReveal'
import Hero from 'components/slices/Hero/_Index'
import Banner from 'components/slices/Banner'
import man from 'assets/images/employer.jpg'
// import Impact from 'components/slices/Impact'
import Carousel from 'components/slices/Carousel/_Index'
import Events from '../Events'
import AskTheRightQuestions from '../AskTheRightQuestions/_Index'
import VirtualEvents from '../VirtualEvents'
import { MODULES, VIEWS } from 'constant/views'
import { useSeekerStatusContext } from 'components/layout/_Layout/seekerStatusContext'
import Coalition from '../Coalition/_Index'
import FAQ from 'components/slices/FAQ'
import Wellbeing from '../Wellbeing/_Index'
import JobList from '../JobList'
import WorkEssentials from '../WorkEssentials/_Index'
import { useTranslation } from 'react-i18next'
import Progression from '../Progression/_Index'
import Sweepstakes from '../Sweepstakes'
import SeeMore from '../SeeMore'
import Impact from '../Impact'

const Slices = () => {
  const isPostEvents = process.env.REACT_APP_STATE === 'events_over'
  const { status } = useSeekerStatusContext() || {}
  const { t } = useTranslation('tips', 'partners')
  const tips = t('tips', { returnObjects: true })
  // const partnerTopics = t('partners:topics', { returnObjects: true })
  const currentView = isPostEvents
    ? VIEWS.find((view) => view?.view === 'events over')
    : VIEWS.find((view) => view?.view === status?.toLowerCase()) ?? VIEWS[0]

  const slices = currentView.modules.map((handle) =>
    MODULES.find((module) => module.handle === handle)
  )
  const locale = document.documentElement.getAttribute('lang')

  const renderSlices = ({ sliceType, handle, index }) => {
    let Component

    switch (sliceType) {
      case 'coalition':
        Component = <Coalition key={index} />
        break

      case 'virtual events':
        Component = <VirtualEvents key={index} />
        break

      case 'job board':
        Component = <JobList key={index} />
        break

      case 'interview skills':
        Component = (
          <Carousel
            key={index}
            items={tips}
            sectionHeaderCopy={{
              eyebrow: t('eyebrow'),
              title: t('title'),
              description: t('description'),
            }}
            type="skills"
            sectionId={handle}
          />
        )

        break

      case 'ask the right questions':
        if (locale === 'es') return <></>
        Component = <AskTheRightQuestions key={index} />
        break

      case 'employer hub':
        Component = <Banner imageSrc={man} key={index} />
        break

      case 'wellbeing':
        Component = <Wellbeing key={index} />
        break

      case 'impact':
        Component = <Impact key={index} />
        break

      case 'sweepstakes':
        Component = <Sweepstakes key={index} />
        break

      case 'IRL events':
        Component = <Events key={index} />
        break

      case 'work essentials':
        Component = <WorkEssentials key={index} />
        break

      case 'see more':
        Component = <SeeMore key={index} />
        break

      case 'FAQ':
        Component = <FAQ key={index} />
        break

      case 'job progression':
        Component = <Progression key={index} />
        break

      // case 'partners':
      //   Component = (
      //     <Carousel
      //       key={index}
      //       items={partnerTopics}
      //       sectionHeaderCopy={{
      //         eyebrow: t('partners:eyebrow'),
      //         title: t('partners:title'),
      //         description: t('partners:description'),
      //       }}
      //       type="partners"
      //       sectionId={handle}
      //     />
      //   )
      //   break

      default:
        return <></>
    }

    return (
      <InViewReveal
        id={handle}
        key={handle}
        gaEventData={['Global', 'In View', handle]}>
        <div
          className={cn('slice', styles[`slice-${handle}`], {
            [styles.first]: index === 0,
          })}>
          {Component}
        </div>
      </InViewReveal>
    )
  }

  return (
    <>
      <Hero />

      {slices.map((slice, index) => (
        <Fragment key={`slice-${index}`}>
          {renderSlices({
            sliceType: slice?.name,
            handle: slice?.handle,
            index,
          })}
        </Fragment>
      ))}
    </>
  )
}

export default Slices

Slices.propTypes = {}
