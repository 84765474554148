import PropTypes from 'prop-types'
import styles from './index.module.scss'
import Button from 'components/pieces/Button'

const ResourceCTAs = ({ ctas, parentTitle = '' }) => {
  return (
    <div className={styles.resourceCTAs}>
      {ctas?.length &&
        ctas.map((cta, k) => (
          <Button
            thin
            key={`cta-${k}`}
            type="outline"
            link={cta.cta_link}
            gaEventData={[
              'Resources',
              'Click',
              'CTA Button',
              parentTitle + ' - ' + cta?.cta_text,
            ]}
            className={styles.resourceCTA}>
            {cta.cta_text}
          </Button>
        ))}
    </div>
  )
}

ResourceCTAs.propTypes = {
  ctas: PropTypes.arrayOf(
    PropTypes.shape({
      cta_text: PropTypes.string,
      cta_link: PropTypes.string,
    })
  ),
  parentTitle: PropTypes.string,
}

export default ResourceCTAs
