/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types'
import styles from './index.module.scss'
import cn from 'classnames'
import Player from 'react-player/lazy'
import { useInView } from 'react-intersection-observer'
import { useEffect, useState, useRef } from 'react'
import { ReactComponent as PlayIcon } from 'assets/images/play.svg'
import ImageComponent from '../Image'
import SizerWrapper from '../SizerWrapper'
import gaEvent from 'utils/analytics'
import { useTranslation } from 'react-i18next'

const Video = ({
  videoUrl,
  videoPoster,
  videoPosterAlt,
  className,
  rounded,
  autoplay = false,
  gaEventData,
}) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [isPaused, setIsPaused] = useState(false)
  const containerRef = useRef()
  const { ref, inView } = useInView({
    threshold: 0,
  })
  const { i18n } = useTranslation()

  const youtube = {
    playerVars: {
      rel: 0,
      cc_lang_pref: i18n.language,
      start: 0,
    },
  }
  if (i18n.language === 'es') {
    youtube.playerVars.cc_load_policy = 1
  }

  useEffect(() => {
    if (!inView) {
      setIsPlaying(false)
    }
  }, [inView])

  useEffect(() => {
    if (autoplay) {
      setIsPlaying(true)
    }
  }, [autoplay])

  // toggle the iframe's ada visibility and tabbability based on whether the video has been started or not
  useEffect(() => {
    if (!containerRef?.current) return
    const iframe = containerRef?.current.querySelector('iframe')
    if (!iframe) return

    if (isPlaying || isPaused || autoplay) {
      iframe.tabIndex = 0
      iframe.ariaHidden = 'false'
    } else {
      iframe.tabIndex = -1
      iframe.ariaHidden = 'true'
    }
  }, [containerRef, autoplay, isPaused, isPlaying])

  return (
    <div className={cn(styles.wrapper)} ref={containerRef}>
      {videoUrl && (
        <SizerWrapper
          className={rounded ? styles.sizerWrapper : undefined}
          aspectRatio={0.5625}>
          <div className={cn(styles.video, className)} ref={ref}>
            {!autoplay && (
              <div
                className={cn(styles.image, {
                  [styles.visible]: !isPlaying && !isPaused,
                })}
                onClick={() => {
                  setIsPlaying(!isPlaying)
                }}>
                <ImageComponent
                  layout="fill"
                  lazy={false}
                  image={{
                    url: videoPoster,
                    alt: videoPosterAlt,
                  }}
                />
              </div>
            )}

            <Player
              url={videoUrl}
              width="100%"
              height="100%"
              playing={isPlaying}
              onPlay={() => {
                setIsPlaying(true)
              }}
              onStart={() => {
                setIsPaused(false)
                setIsPlaying(true)
                if (gaEventData) {
                  gaEvent(...gaEventData)
                }
              }}
              onPause={() => {
                setIsPaused(true)
                setIsPlaying(false)
              }}
              onEnded={() => {
                setIsPaused(false)
                setIsPlaying(false)
              }}
              controls={true}
              config={{ youtube }}
            />

            <PlayBtn
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              isPaused={isPaused}
            />
          </div>
        </SizerWrapper>
      )}
    </div>
  )
}

export default Video

Video.propTypes = {
  autoplay: PropTypes.bool,
  videoUrl: PropTypes.string,
  videoPoster: PropTypes.string,
  className: PropTypes.string,
  rounded: PropTypes.bool,
  gaEventData: PropTypes.array,
  videoPosterAlt: PropTypes.string,
}

const PlayBtn = ({ setIsPlaying, isPlaying, isPaused }) => {
  const { t } = useTranslation('ada')
  return (
    <button
      tabIndex={isPlaying ? -1 : 0}
      aria-hidden={isPlaying ? 'true' : 'false'}
      className={cn(styles.playBtn, {
        [styles.visible]: !isPlaying && !isPaused,
      })}
      aria-label={t('video.play')}
      onClick={() => {
        setIsPlaying(!isPlaying)
      }}>
      <span aria-hidden="true">
        <PlayIcon />
      </span>
    </button>
  )
}

PlayBtn.propTypes = {
  setIsPlaying: PropTypes.func,
  isPlaying: PropTypes.bool,
  isPaused: PropTypes.bool,
}
