import { useLocation } from 'react-router-dom'
import { useReducedMotionQuery } from './useReducedMotionQuery'

export const useIntroHasPlayed = () => {
  const { hash } = useLocation()
  const reducedMotion = useReducedMotionQuery()

  const introHasPlayed =
    sessionStorage.getItem('intro-complete') ||
    process.env.REACT_APP_SKIP_INTRO === 'true' ||
    process.env.REACT_APP_SKIP_INTRO === true ||
    reducedMotion ||
    navigator.userAgent === 'ReactSnap' ||
    hash
  return introHasPlayed
}
