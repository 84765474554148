/* eslint-disable react-hooks/exhaustive-deps */
// import cn from 'classnames'
import PropTypes from 'prop-types'
import Container from 'components/layout/Container'
import Grid from 'components/layout/Grid'
import Button from 'components/pieces/Button'
import styles from './index.module.scss'
import { ReactComponent as Bg } from 'assets/images/questions-to-ask-bg.svg'
import InViewReveal from 'components/pieces/InViewReveal'
import CopyToShare from 'components/pieces/ShareButtons/CopyToShare'
import { useEffect, useState } from 'react'
import Loader from 'components/pieces/Loader'
import { useTranslation } from 'react-i18next'
import { parseHtml } from 'utils/parseHtml'

const QuestionScreen = ({ term }) => {
  const { t } = useTranslation('questions', 'common')
  const [shareLink, setShareLink] = useState('')
  const [loading, setLoading] = useState(true)

  const [questions, setQuestions] = useState([])
  const [displayTerm, setDisplayTerm] = useState('')

  async function getQuestions(job) {
    const apiPath = process.env.REACT_APP_API_URL || '.'
    setLoading(true)
    const questionRequest = await fetch(
      `${apiPath}/readytowork/api/questions/?format=json&job=${job}`
    )
    const _questions = await questionRequest.json()
    if (_questions?.questions && _questions?.term) {
      setQuestions(_questions.questions)
      setDisplayTerm(_questions.term)
    }
    setLoading(false)
  }

  useEffect(() => {
    const base = window?.location?.origin
    const fullUrl = base + `/readytowork/questions-to-ask/${encodeURI(term)}`
    setShareLink(fullUrl)
    term && term !== '' && getQuestions(term)
  }, [term])

  const socialShares = [
    {
      type: 'link',
      content: shareLink,
      label: 'copy the link to share',
    },
    {
      type: 'copy',
      content: questions.join('\n'),
      label: 'copy the questions',
    },
  ]

  return (
    <Container className={styles.container}>
      <InViewReveal>
        <div>
          {loading && (
            <div className={styles.loading}>
              <Loader />
            </div>
          )}
          {!loading && (
            <Grid className={styles.grid}>
              <div className={styles.leftScreen}>
                <div className={styles.text}>
                  <div className={styles.title}>
                    {parseHtml(
                      t('questions_screen.title', { term: displayTerm })
                    )}
                  </div>

                  <div className={styles.description}>
                    {parseHtml(t('questions_screen.description'))}
                  </div>
                </div>
              </div>
              <div className={styles.rightScreen}>
                {/* social share  */}
                <ul className={styles.shareBtns}>
                  {socialShares.map((item, i) => {
                    const { type, content, label } = item || {}
                    return (
                      <li
                        key={`social-share-${i}`}
                        style={{ position: 'relative' }}>
                        <CopyToShare
                          content={content}
                          type={type}
                          label={label}
                        />
                      </li>
                    )
                  })}
                </ul>
                <ul className={styles.questions}>
                  {questions.map((question, index) => (
                    <li key={index} className={styles.question}>
                      {question}
                    </li>
                  ))}
                  <li className={styles.questionCta}>
                    <Button
                      fullWidth
                      type="bluebg"
                      link={t('ctas.more_resources.cta_link')}
                      linkQueryString={displayTerm}>
                      {t('ctas.more_resources.cta_text')}
                    </Button>
                  </li>
                </ul>

                <div className={styles.bg}>
                  <Bg />
                </div>
              </div>

              <div className={styles.backBtn}>
                <Button link="/#questions">{t('common:back')}</Button>
              </div>
            </Grid>
          )}
        </div>
      </InViewReveal>
    </Container>
  )
}

QuestionScreen.propTypes = {
  term: PropTypes.string,
}
export default QuestionScreen
