import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
// import LanguageDetector from 'i18next-browser-languagedetector'
import enCommon from './translations/en/common.json'
import esCommon from './translations/es/common.json'
import enGlobal from './translations/en/global.json'
import esGlobal from './translations/es/global.json'
import enHero from './translations/en/hero.json'
import esHero from './translations/es/hero.json'
import enFAQ from './translations/en/FAQ.json'
import esFAQ from './translations/es/FAQ.json'
import enCoalition from './translations/en/coalition.json'
import esCoalition from './translations/es/coalition.json'
import enJobList from './translations/en/job-list.json'
import esJobList from './translations/es/job-list.json'
import enVirtual from './translations/en/virtual-events.json'
import esVirtual from './translations/es/virtual-events.json'
import enProgression from './translations/en/progression.json'
import esProgression from './translations/es/progression.json'
import enEvents from './translations/en/IRL-events.json'
import esEvents from './translations/es/IRL-events.json'
import enTips from './translations/en/tips.json'
import esTips from './translations/es/tips.json'
import enEssentials from './translations/en/work-essentials.json'
import esEssentials from './translations/es/work-essentials.json'
import enQuestions from './translations/en/questions.json'
import esQuestions from './translations/es/questions.json'
import enEmployers from './translations/en/employers.json'
import esEmployers from './translations/es/employers.json'
import enWellbeing from './translations/en/wellbeing.json'
import esWellbeing from './translations/es/wellbeing.json'
import enSweepstakes from './translations/en/sweepstakes.json'
import esSweepstakes from './translations/es/sweepstakes.json'
import enImpact from './translations/en/impact.json'
import esImpact from './translations/es/impact.json'
import enDunkinModal from './translations/en/dunkin-modal.json'
import esDunkinModal from './translations/es/dunkin-modal.json'
import enPartners from './translations/en/partners.json'
import esPartners from './translations/es/partners.json'
import enAda from './translations/en/ada.json'
import esAda from './translations/es/ada.json'

i18n
  .use(initReactI18next)
  // .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    debug: false,
    resources: {
      en: {
        common: enCommon,
        global: enGlobal,
        hero: enHero,
        faq: enFAQ,
        coalition: enCoalition,
        jobList: enJobList,
        virtual: enVirtual,
        progression: enProgression,
        events: enEvents,
        tips: enTips,
        essentials: enEssentials,
        questions: enQuestions,
        employers: enEmployers,
        wellbeing: enWellbeing,
        sweepstakes: enSweepstakes,
        impact: enImpact,
        dunkinModal: enDunkinModal,
        partners: enPartners,
        ada: enAda,
      },
      es: {
        common: esCommon,
        global: esGlobal,
        hero: esHero,
        faq: esFAQ,
        coalition: esCoalition,
        jobList: esJobList,
        virtual: esVirtual,
        progression: esProgression,
        events: esEvents,
        tips: esTips,
        essentials: esEssentials,
        questions: esQuestions,
        employers: esEmployers,
        wellbeing: esWellbeing,
        sweepstakes: esSweepstakes,
        impact: esImpact,
        dunkinModal: esDunkinModal,
        partners: esPartners,
        ada: esAda,
      },
    },
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  })

export default i18n
