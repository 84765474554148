export let OCCUPATIONS = {}

export async function fetchOccupations(lang) {
  const apiPath = process.env.REACT_APP_PROGRESSIONS_API_URL || '.'

  const langParam = lang === 'es' ? '?lang=spanish' : ''
  try {
    const response = await fetch(`${apiPath}/recent-occupations${langParam}`)
    const data = await response.json()
    //OCCUPATIONS = data
    const occupations = {}
    data.forEach((item) => {
      if (!occupations[item.category]) {
        occupations[item.category] = []
      }
      occupations[item.category].push(item)
    })
    OCCUPATIONS = occupations
    return occupations || {}
  } catch (error) {
    return {}
  }
}

fetchOccupations()
