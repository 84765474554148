import ReactDOM from 'react-dom/client'
import './index.css'
import './i18n/i18n.js'
import App from './app'
import { BrowserRouter } from 'react-router-dom'

// A manual redirect for dev environments
if (window.location.pathname === '/') {
  document.location.pathname = '/readytowork'
}

// eslint-disable-next-line no-undef
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter basename="/readytowork">
    <App />
  </BrowserRouter>
)
