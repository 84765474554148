import { Box } from '@mui/material'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied'
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral'
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt'
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied'

const iconStyle = {
  width: '3rem',
  height: '3rem',
}

const icons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon sx={iconStyle} />,
    text: 'Not at all',
  },
  2: {
    icon: <SentimentDissatisfiedIcon sx={iconStyle} />,
    text: '',
  },
  3: {
    icon: <SentimentNeutralIcon sx={iconStyle} />,
    text: 'Somewhat',
  },
  4: {
    icon: <SentimentSatisfiedAltIcon sx={iconStyle} />,
    text: '',
  },
  5: {
    icon: <SentimentVerySatisfiedIcon sx={iconStyle} />,
    text: 'Extremely',
  },
}

const FeedbackInputButton = (props) => {
  // eslint-disable-next-line react/prop-types
  const { level, score, setScore, setHasFeedback } = props

  return (
    <Box
      // eslint-disable-next-line jsx-a11y/tabindex-no-positive
      tabIndex={1}
      key={level}
      sx={{
        flex: 1,
        textAlign: 'center',
        minWidth: {
          xs: '40px',
          sm: '80px',
        },
        cursor: 'pointer',
        backgroundColor: score === level ? 'neutral.200' : null,
      }}
      onClick={() => {
        setHasFeedback(true)
        setScore(level)
      }}>
      {icons[level].icon}
      <Box
        component="p"
        sx={{
          fontSize: '1rem',
          color: 'neutral.900',
        }}>
        {icons[level].text}
      </Box>
    </Box>
  )
}

const FeedbackInput = (props) => {
  return (
    <Box
      sx={{
        maxWidth: '897px',
        mx: ['auto', null],
        mr: 'auto',
        padding: {
          xs: '16px',
          sm: '24px 32px',
        },
      }}>
      <Box component="h2" level={5} sx={{ color: 'neutral.900', mb: '24px' }}>
        Overall, how useful is the information presented in this tool?
      </Box>
      <Box
        as={'ul'}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          pl: 0,
        }}>
        {[1, 2, 3, 4, 5].map((level) => (
          <FeedbackInputButton key={level} {...props} level={level} />
        ))}
      </Box>
    </Box>
  )
}

export default FeedbackInput
