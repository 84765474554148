/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import PropTypes from 'prop-types'
import styles from './index.module.scss'
import cn from 'classnames'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { ReactComponent as Caret } from 'assets/images/caret.svg'
import { HEADER_SHARES, SOCIAL_SHARE } from 'constant/social'
import { useCopyToClipboard } from 'utils/useCopyToClipboard'
import Toast from 'components/pieces/Toast'
import gaEvent from 'utils/analytics'

const ShareDropdown = () => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation('global', 'ada')
  const socialShareTexts = t('social_share', { returnObjects: true })
  const shareItems = HEADER_SHARES.map((type) => ({
    ...SOCIAL_SHARE[type],
    ...socialShareTexts[type],
  }))
  const { isCopied, handleCopyClick } =
    useCopyToClipboard(window?.location?.href) || {}

  // click else where to  close the dropdown
  useEffect(() => {
    const handleOutsideMenu = (e, target) => {
      const { component } = e?.target?.dataset || {}

      if (open && component !== target) {
        setOpen(!open)
      }
    }

    document.addEventListener('click', (e) =>
      handleOutsideMenu(e, 'header-social-button')
    )
    return document.removeEventListener('click', (e) =>
      handleOutsideMenu(e, 'header-social-button')
    )
  }, [open])

  return (
    <div className={cn(styles.wrapper, { [styles.open]: open })}>
      <button
        data-component="header-social-button"
        onFocus={() => {
          if (open) {
            setOpen(false)
          }
        }}
        onClick={() => {
          setOpen(!open)
          if (!open) {
            gaEvent('Global', 'Click', 'Share Navigation')
          }
        }}
        className={styles.button}
        aria-label={t('ada:header.social_share.aria', {
          toggle: open ? t('ada:global.close') : t('ada:global.open'),
        })}>
        <span> {t('ada:header.social_share.label')}</span>

        <span className={styles.caret} aria-hidden="true">
          <Caret />
        </span>
      </button>

      <motion.ul
        className={cn(styles.menu)}
        animate={{
          maxHeight: open ? window.innerHeight : 0,
          opacity: open ? 1 : 0,
          transition: {
            duration: open ? 0.6 : 0.2,
          },
        }}
        initial={false}>
        {shareItems?.map((item, i) => {
          const { type, icon, label, onAction, aria } = item || {}

          let handleClick = () => {}
          switch (type) {
            case 'copy':
              handleClick = handleCopyClick
              break
            case 'email':
              handleClick = () =>
                onAction({
                  subject: t('header.shares.email.subject'),
                  body: t('header.shares.email.body'),
                })
              break
            case 'twitter':
              handleClick = () =>
                onAction({
                  text: t('header.shares.twitter'),
                })
              break

            default:
              handleClick = onAction
          }

          return (
            <li key={`social-share-${i}`}>
              <button
                tabIndex={open ? '0' : '-1'}
                aria-hidden={open ? 'false' : 'true'}
                onClick={(event) => {
                  gaEvent('Share Navigation', 'Click', label)
                  handleClick(event)
                }}
                className={styles.item}
                aria-label={aria}
                data-component="header-social-item"
                data-component-index={i}>
                <span className={styles.icon} aria-hidden="true">
                  {icon}
                </span>
                <span> {label} </span>
              </button>
            </li>
          )
        })}
      </motion.ul>
      <div
        tabIndex={open ? '0' : '-1'}
        className={styles.closeMenuBtn}
        onKeyDown={(e) => {
          if (e.key === 'Tab') {
            setOpen(false)
            // TODO: focus on the next element
          }
        }}></div>
      {/* copy to clipboard message popup */}
      <Toast show={isCopied} />
    </div>
  )
}

export default ShareDropdown

ShareDropdown.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark']),
  setTheme: PropTypes.func,
}
