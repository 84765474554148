import { createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import { useInitSeekerStatus } from 'utils/useSeekerStatus'
import { useTranslation } from 'react-i18next'

export const SeekerStatus = createContext()

export function SeekerStatusWrapper({ children }) {
  const { t } = useTranslation('hero')
  const options = t('job_seeking_statuses.options', { returnObjects: true })
  const [status, setStatus] = useInitSeekerStatus()

  // convert the label(change with locale) to value(always english)
  const updateStatus = (label) => {
    const option = options.find((option) => option.label === label)
    const value = option?.value ?? ''
    setStatus(value)
  }

  return (
    <SeekerStatus.Provider value={{ status, setStatus, updateStatus }}>
      {children}
    </SeekerStatus.Provider>
  )
}

export function useSeekerStatusContext() {
  return useContext(SeekerStatus)
}

SeekerStatusWrapper.propTypes = {
  status: PropTypes.object,
  setStatus: PropTypes.func,
  children: PropTypes.node,
}
