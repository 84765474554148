import PropTypes from 'prop-types'
import Button from 'components/pieces/Button'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Bg } from 'assets/images/resource-card-bg.svg'
import Eyedropper from 'components/pieces/Eyedropper'
import ResourceCard from 'components/pieces/Card/IconCard'
import InViewReveal from 'components/pieces/InViewReveal'
import Image from 'components/pieces/Image'
import { imageResolver } from 'utils/imageResolver'

const LocationCards = ({ focusRef, active, results, back }) => {
  const { t } = useTranslation('essentials', 'common')

  return (
    <InViewReveal>
      <div
        className={styles.cards}
        style={{
          '--resource-theme-color': active.color,
          '--icon-text-color': active.color,
        }}>
        {results?.map((result, i) => (
          <ResourceCard
            inView
            ref={i === 0 ? focusRef : null}
            item={{
              ...result,
              cta_text: t('labels.get_directions'),
              description: (
                <div className={styles.description}>{result?.description}</div>
              ),
            }}
            key={`location-${i}`}
            Bg={<Bg />}
            iconElement={<Eyedropper label={i + 1} />}
            className={styles.card}
            titleClass={styles.title}></ResourceCard>
        ))}

        <div className={styles.byline}>
          <div> {t('labels.provided_by')}</div>
          <div className={styles.logo}>
            <Image image={{ url: imageResolver(active?.name) }} />
          </div>
        </div>

        <div className={styles.backBtn}>
          <Button onClick={back}>{t('common:back')}</Button>
        </div>
      </div>
    </InViewReveal>
  )
}

LocationCards.propTypes = {
  focusRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  active: PropTypes.shape({
    color: PropTypes.string,
    name: PropTypes.string,
  }),
  results: PropTypes.array,
  back: PropTypes.func,
}
export default LocationCards
