import PropTypes from 'prop-types'
import { parseHtml } from 'utils/parseHtml'
import { PageLineCheckpoint } from '../PageLine'
import RichTextField from '../RichTextField'
import styles from './index.module.scss'

const SectionHeader = ({ eyebrow, title, description }) => {
  let parsedDescription = description

  if (
    description &&
    typeof description === 'string' &&
    description?.indexOf('<') === 0
  ) {
    parsedDescription = parseHtml(description)
  }

  return (
    <div className={styles.header}>
      {eyebrow && (
        <div className={styles.eyebrow}>
          <span className={styles.eyebrowInner}>
            <PageLineCheckpoint className={styles.checkpoint} />
            {eyebrow}
          </span>
        </div>
      )}
      {title && <h2 className={styles.title}>{title}</h2>}
      {description && (
        <RichTextField
          text={parsedDescription}
          className={styles.description}
        />
      )}
    </div>
  )
}

SectionHeader.propTypes = {
  eyebrow: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}
export default SectionHeader
