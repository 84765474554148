/* eslint-disable react-hooks/exhaustive-deps */
import Layout from 'components/layout/_Layout'
import QuestionScreen from 'components/slices/AskTheRightQuestions/Questions'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { gaPageview } from 'utils/analytics'

function Questions() {
  const params = useParams()
  const [currentTerm, setCurrentTerm] = useState('')

  const fetchData = async () => {
    const newTerm = params && params?.handle
    setCurrentTerm(newTerm)
  }

  useEffect(() => {
    const newTerm = params && params?.handle
    if (newTerm) {
      fetchData()
    }
  }, [params])

  useEffect(() => {
    gaPageview()
  }, [])

  return (
    <div className="Questions">
      <Helmet>
        <title data-react-helmet="true">
          Your journey to better work begins here
        </title>
        <meta
          property="og:title"
          content="Your journey to better work begins here."
          data-react-helmet="true"
        />
        <meta
          name="twitter:title"
          content="Your journey to better work begins here."
          data-react-helmet="true"
        />
        <meta
          name="description"
          content="Connect with actively hiring employers, get resume assistance, attend in-person events, access resources from our coalition and more."
          data-react-helmet="true"
        />
        <meta
          property="og:description"
          content="Connect with actively hiring employers, get resume assistance, attend in-person events, access resources from our coalition and more."
          data-react-helmet="true"
        />
        <meta
          name="twitter:description"
          content="Connect with actively hiring employers, get resume assistance, attend in-person events, access resources from our coalition and more."
          data-react-helmet="true"
        />
      </Helmet>

      <Layout hideBackToTop headerPosition="fixed" pageType="questions">
        <div className="slice">
          <QuestionScreen term={currentTerm} results={[]} />
        </div>
      </Layout>
    </div>
  )
}

export default Questions
