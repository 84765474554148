import PropTypes from 'prop-types'
import styles from './index.module.scss'
import { ReactComponent as Drop } from 'assets/images/drop.svg'

const Eyedropper = ({ label }) => {
  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <Drop />
        <div className={styles.label}> {label} </div>
      </div>
    </div>
  )
}

Eyedropper.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
export default Eyedropper
