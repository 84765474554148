import Layout from 'components/layout/_Layout'
import Component from 'components/slices/Sweepstakes'
import { Helmet } from 'react-helmet'
import { useEffect } from 'react'
import { gaPageview } from 'utils/analytics'
import { useTranslation } from 'react-i18next'
import InViewReveal from 'components/pieces/InViewReveal'
import { useDocumentSize } from 'utils/useDocumentSize'

function Sweepstakes() {
  const { t } = useTranslation('global')
  const documentSize = useDocumentSize() || {}
  useEffect(() => {
    gaPageview()
  }, [])

  return (
    <div className="Sweepstakes">
      <Helmet>
        <title data-react-helmet="true">{t('site.meta_title')}</title>
        <meta
          property="og:title"
          content={t('site.meta_title')}
          data-react-helmet="true"
        />
        <meta
          name="twitter:title"
          content={t('site.meta_title')}
          data-react-helmet="true"
        />
        <meta
          name="description"
          content={t('site.meta_description')}
          data-react-helmet="true"
        />
        <meta
          property="og:description"
          content={t('site.meta_description')}
          data-react-helmet="true"
        />
        <meta
          name="twitter:description"
          content={t('site.meta_description')}
          data-react-helmet="true"
        />
      </Helmet>

      <Layout hideBackToTop>
        <InViewReveal delay={1.5}>
          <div>
            <Component
              signed_in={true}
              // 560px is the breakpoint when sweepstakes changes to column layout and the confetti animation is controlled by it's own inview prop and thus don't need manual delay
              delay={documentSize?.width < 560 ? 0 : 3}
            />
          </div>
        </InViewReveal>
      </Layout>
    </div>
  )
}

export default Sweepstakes
