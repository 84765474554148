import PropTypes from 'prop-types'

const eventShape = PropTypes.shape({
  date: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  ctaText: PropTypes.string,
  ctaLink: PropTypes.string,
  bgImage: PropTypes.object,
  schedule: PropTypes.any,
  location: PropTypes.node,
  map: PropTypes.string,
  paragraph: PropTypes.node,
  imagePosition: PropTypes.string,
  bgColor: PropTypes.string,
  twitterShareCopy: PropTypes.string,
  emailShareCopy: PropTypes.shape({
    subject: PropTypes.string,
    body: PropTypes.string,
  }),
})

export default eventShape
