import cn from 'classnames'
import PropTypes from 'prop-types'
import Button from '../Button'
import styles from './index.module.scss'

const IconWrapper = ({
  wrapperClassName,
  className,
  type,
  outlineIcon,
  children,
  buttonRef,
  ...args
}) => {
  return (
    <div
      className={cn(styles.wrapper, wrapperClassName, {
        [styles.outlineIcon]: outlineIcon,
      })}>
      <div className={styles.container}>
        <div className={styles.inner}>
          <Button
            className={className}
            type={type}
            round
            ref={buttonRef}
            {...args}>
            {children}
          </Button>
        </div>
      </div>
    </div>
  )
}

IconWrapper.propTypes = {
  buttonRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  wrapperClassName: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.oneOf([
    'primary',
    'mono',
    'reversed',
    'outline',
    'outlineReversed',
  ]),
  outlineIcon: PropTypes.bool,
  children: PropTypes.node,
}
export default IconWrapper
