import styles from './index.module.scss'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Container from '../../layout/Container'
import Image from 'components/pieces/Image'
import Button from 'components/pieces/Button'
import Grid from '../../layout/Grid'
import SectionHeader from 'components/pieces/SectionHeader'
import { useTranslation } from 'react-i18next'
import { parseHtml } from 'utils/parseHtml'
import image from 'assets/images/sweepstakes.png'
import mobileImage from 'assets/images/sweepstakes-mobile.png'
import Confetti from 'components/pieces/Confetti'
import { useInView } from 'react-intersection-observer'
import { useState, useEffect, useRef } from 'react'
import { useDocumentSize } from 'utils/useDocumentSize'
import { AnimatePresence, motion } from 'framer-motion'

const Sweepstakes = ({ signed_in = false, delay = 0 }) => {
  const { t } = useTranslation('sweepstakes')
  const { i18n } = useTranslation()
  const documentSize = useDocumentSize() || {}
  const timerRef = useRef()
  const [played, setPlayed] = useState(false)
  const { inView, ref } =
    useInView({
      threshold: 0.5,
    }) || {}

  useEffect(() => {
    if (inView && !played) {
      if (timerRef) {
        timerRef.current = setTimeout(() => {
          setPlayed(true)
        }, 1600)
      }
    }

    if (!inView && timerRef?.current) {
      clearTimeout(timerRef.current)
      setPlayed(false)
    }
  }, [inView, played])

  const langParam = i18n.language === 'es' ? '&lng=es' : ''

  return (
    <Container className={cn(styles.container, { [styles.page]: signed_in })}>
      <Grid>
        <div className={styles.header}>
          <SectionHeader eyebrow={t('eyebrow')} title={t('title')} />
        </div>
      </Grid>
      <Grid className={styles.wrapper}>
        <div className={cn(styles.textWrapper)}>
          <div className={styles.description}>
            {signed_in
              ? parseHtml(t('description_signed_in'))
              : parseHtml(
                  t('description', {
                    return_url: encodeURIComponent(
                      'https://www.indeed.com/readytowork/sweepstakes?sid=us_cont_g_sweepstakes&kw=rtw&utm_source=rtw&utm_medium=cont&utm_campaign=readytowork&utm_content=sweepstakes' +
                        langParam
                    ),
                  })
                )}
          </div>

          <div className={styles.buttonWrapper}>
            {signed_in ? (
              <Button
                linkTarget="_self"
                link={t('ctas.ready.cta_link')}
                fullWidth>
                {t('ctas.ready.cta_text')}
              </Button>
            ) : (
              <Button
                link={t('ctas.enter.cta_link', {
                  return_url: encodeURIComponent(
                    'https://www.indeed.com/readytowork/sweepstakes?sid=us_cont_g_sweepstakes&kw=rtw&utm_source=rtw&utm_medium=cont&utm_campaign=readytowork&utm_content=sweepstakes' +
                      langParam
                  ),
                })}
                fullWidth>
                {t('ctas.enter.cta_text')}
              </Button>
            )}
          </div>
          {!signed_in && (
            <div className={styles.footerCopy}>
              {parseHtml(t('disclaimer'))}
            </div>
          )}
        </div>
        <div className={cn(styles.imageWrapper)} ref={ref}>
          <Image
            image={{
              alt: '',
              url: documentSize?.width > 560 ? image : mobileImage,
            }}
            className="imageWrapper"
            round
          />
          <AnimatePresence>
            {inView && !played && (
              <>
                <motion.div
                  exit={{
                    opacity: 0,
                    transition: {
                      duration: 0.2,
                      delay,
                    },
                  }}
                  className={cn(styles.confetti, styles.confettiBottom)}
                  style={{
                    '--delay': `${1 + delay ?? 0}s`,
                  }}>
                  <Confetti />
                </motion.div>
                <motion.div
                  exit={{
                    opacity: 0,
                    transition: {
                      duration: 0.2,
                      delay,
                    },
                  }}
                  className={cn(styles.confetti, styles.confettiTop)}
                  style={{
                    '--delay': `${0 + delay ?? 0}s`,
                  }}>
                  <Confetti />
                </motion.div>
              </>
            )}
          </AnimatePresence>
        </div>
      </Grid>
    </Container>
  )
}

export default Sweepstakes

Sweepstakes.propTypes = {
  signed_in: PropTypes.bool,
  delay: PropTypes.number,
}
