import 'styles/globals.scss'
import 'styles/marketoform.scss'
import { Routes, Route } from 'react-router-dom'
import Home from 'pages/home'
import Tips from 'pages/tips'
import NotFound from 'pages/notfound'
import Layout from 'components/layout/_Layout'
import Events from 'pages/events'
import Questions from 'pages/questions'
import Sweepstakes from 'pages/sweepstakes'
import PageLineWrapper from 'components/pieces/PageLine'
// import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'
import { LocaleProvider } from 'components/layout/_Layout/localeContext'
import ForceEs from 'pages/force-es'

// ReactGA.initialize('UA-000000-01', { debug: true })
TagManager.initialize({
  gtmId: 'GTM-K8RKLN',
})

function App() {
  return (
    <LocaleProvider>
      <PageLineWrapper active={true}>
        <Routes>
          <Route
            path="*"
            element={
              <Layout>
                <NotFound />
              </Layout>
            }
          />
          <Route path="/" element={<Home />}></Route>
          <Route path="/tips/:handle" element={<Tips />}></Route>
          <Route path="/events/:handle" element={<Events />}></Route>
          <Route path="/sweepstakes" element={<Sweepstakes />}></Route>
          <Route
            path="/questions-to-ask/:handle"
            element={<Questions />}></Route>
          {navigator.userAgent === 'ReactSnap' && (
            <>
              <Route
                path="/events-es/:handle"
                element={
                  <>
                    <ForceEs />
                    <Events />
                  </>
                }></Route>
              <Route
                path="/index-es"
                element={
                  <>
                    <ForceEs />
                    <Home />
                  </>
                }></Route>
            </>
          )}
        </Routes>
      </PageLineWrapper>
    </LocaleProvider>
  )
}

export default App
