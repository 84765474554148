export default function gaEvent(category, action, label, value) {
  if (!window?.dataLayer) return

  window.dataLayer.push({
    event: 'event',
    eventProps: {
      category: category,
      action: action,
      label: label,
      value: value,
    },
  })
}

export function gaPageview() {
  if (!window?.dataLayer) return

  setTimeout(() => {
    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: window.location.pathname + window.location.search,
        title: document.title,
      },
    })
  }, 10)
}
