import { Fragment, useState } from 'react'
import { isWithinInterval, isAfter } from 'date-fns'

export const getLocations = async (zipcode) => {
  try {
    if (!zipcode || zipcode === '') return
    const apiPath = process.env.REACT_APP_API_URL || '.'

    const locationRequest = await fetch(
      `${apiPath}/readytowork/api/search/?format=json&zip=${zipcode}`
    )
    const locations = await locationRequest.json()

    const data = await new Promise((resolve) => {
      const results = []
      locations.forEach((item) => {
        let title = ''
        let address = <></>
        if (item.address) {
          let addressParts = item.address.split(',')
          if (addressParts[0]) {
            title = addressParts[0]
          }
          addressParts.shift()
          address = (
            <>
              {addressParts.map((adr) => {
                return (
                  <Fragment key={adr}>
                    {adr}
                    <br />
                  </Fragment>
                )
              })}
            </>
          )
        }
        const result = {
          title,
          description: address,
          cta_link: item.website,
          cta_text: 'Get Directions',
        }
        results.push(result)
      })

      resolve(results)
    })

    return data
  } catch (err) {
    console.log(err)
  }
}

export const useLyft = () => {
  // locations found
  const [locations, setLocations] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchLocations = (payload) => {
    if (payload && payload !== '') {
      setLoading(true)
    }
    getLocations(payload).then((data) => {
      if (data) {
        setLocations(data)
        setLoading(false)
      }
    })
  }

  return { locations, setLocations, loading, fetchLocations }
}

export const getCodes = (event) => {
  const location = event?.title
  const eventEnd = new Date(event?.datetime_end)
  const currentTime = new Date()

  // if event is past, return false
  if (isAfter(currentTime, eventEnd)) return false

  const codes = event?.lyft_codes
  // for none chicago events, there is only one code
  if (location !== 'Chicago') {
    return codes[0].code
  } else {
    // for chicago events, find applicable code
    const codeItem = codes?.find((item) => {
      const codeEnd = new Date(item?.end)
      const codeStart = item?.start ? new Date(item?.start) : currentTime

      if (isAfter(codeStart, codeEnd)) {
        return false
      } else {
        return isWithinInterval(currentTime, {
          start: codeStart,
          end: codeEnd,
        })
      }
    })
    return codeItem?.code
  }
}
