import cn from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import gaEvent from 'utils/analytics'
import styles from './index.module.scss'

const getCheckboxId = (value) =>
  `wellbeing-option-${value?.title?.toLowerCase().replace(/\s+/, '-')}`

const ValueOption = ({ value, activeIndex, onClick }) => {
  const selected = activeIndex !== null

  const { t } = useTranslation('wellbeing')

  const handleChange = () => {
    onClick(value)
    gaEvent('Wellbeing', 'Click', 'Select option', value?.title)
  }

  const label = `${value.title}, ${value.message}${
    selected ? `, ${t('labels.selected', { index: activeIndex })}` : ''
  }`
  return (
    <div
      className={styles.container}
      style={{ '--value-color': `var(--${value.color}, var(--magenta))` }}>
      <input
        aria-label={label}
        type="checkbox"
        checked={selected}
        onChange={handleChange}
        name={value.title}
        id={getCheckboxId(value)}
        className={styles.input}></input>
      <label
        aria-hidden
        htmlFor={getCheckboxId(value)}
        className={cn(styles.label, {
          [styles.selected]: selected,
        })}>
        <span className={styles.title}>{value.title}</span>
        <span className={styles.message}>{value.message}</span>
        <span className={styles.check}>{activeIndex}</span>
      </label>
    </div>
  )
}

export default ValueOption

ValueOption.propTypes = {
  value: PropTypes.shape({
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    color: PropTypes.string,
  }).isRequired,
  activeIndex: PropTypes.number,
  onClick: PropTypes.func,
}
