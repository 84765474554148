import { useCallback, useEffect, useState } from 'react'
import { OCCUPATIONS } from 'constant/occupations'

function langParam(lang) {
  return lang === 'es' ? '&lang=spanish' : ''
}

/**
 * API and formatting methods
 * https://raa-coaching-api.mckinsey.digital/api-docs/
 */

/**
 * Fetch either next or target occupations
 *
 * @param {*} occupationId
 * @param {('next-occupations'|'target-occupations')} type
 * @returns {Array} occupations
 */
export const getOccupations = async (occupationId, type, lang) => {
  try {
    if (!occupationId) return
    if (!['next-occupations', 'target-occupations'].includes(type)) return

    const apiPath = process.env.REACT_APP_PROGRESSIONS_API_URL || '.'
    const response = await fetch(
      `${apiPath}/${type}?occupationId=${occupationId}${langParam(lang)}`
    )
    const json = await response.json()

    if (json.error) {
      throw new Error(json.error)
    }

    return json
  } catch (err) {
    console.log(err)
  }
}

export const getOccupationDetails = async (
  nextOccupationId,
  targetOccupationId,
  lang
) => {
  try {
    if (!nextOccupationId || !targetOccupationId) return

    const apiPath = process.env.REACT_APP_PROGRESSIONS_API_URL || '.'
    const response = await fetch(
      `${apiPath}/target-occupations/${targetOccupationId}?occupationId=${nextOccupationId}${langParam(
        lang
      )}`
    )
    const json = await response.json()

    if (json.error) {
      throw new Error(json.error)
    }

    return json
  } catch (err) {
    console.log(err)
  }
}

export const getSalaryDetails = async (occupationId, lang) => {
  try {
    if (!occupationId) return

    const apiPath = process.env.REACT_APP_PROGRESSIONS_API_URL || '.'
    const response = await fetch(
      `${apiPath}/salary-summary?originOccupationId=${occupationId}${langParam(
        lang
      )}`
    )
    const json = await response.json()

    if (json.error) {
      throw new Error(json.error)
    }

    return json
  } catch (err) {
    console.log(err)
  }
}

/**
 * Finds the occupation title from an id,
 * looking inside our base occupations data
 */
export const getBaseOccupationTitle = (id) => {
  if (!id) {
    return null
  }

  let title = null

  for (const group in OCCUPATIONS) {
    const occupation = OCCUPATIONS[group].find(
      (occupation) => occupation.id === id
    )

    if (occupation?.title) {
      return occupation.title
    }
  }

  return title
}

/**
 * Filters an array of occupations under this criteria:
 *  - Has a median salary of over $42,000
 *  - Is a gateway occupation
 * (middle-to-high wages according to mckinsey)
 *
 * @param {Array} occupations
 * @returns {Array} filteredOccupations
 */
export const filterNextOccupations = (occupations) => {
  return occupations.filter(
    (occupation) =>
      (occupation.median_salary === '' || occupation.median_salary > 41500) &&
      !!parseInt(occupation.is_gateway)
  )
}

export const formatSalaryLimit = (salary) => {
  const thousands = Math.max(Math.trunc((salary / 1000).toFixed(2)), 1)
  return `$${thousands}K`
}

export const useProgressionState = () => {
  const [occupationId, setOccupationId] = useState(null)
  const [currOccupationId, setCurrOccupationId] = useState(null)
  const [currOccupationTitle, setCurrOccupationTitle] = useState(null)
  const [editingOccupation, setEditingOccupation] = useState(true)

  const [occupationsSalaryDetails, setOccupationsSalaryDetails] = useState(null)

  const [hasLoadedFirstOccupations, setHasLoadedFirstOccupations] =
    useState(false)
  const [loadingNextOccupations, setLoadingNextOccupations] = useState(false)
  const [nextOccupations, setNextOccupations] = useState(null)
  const [nextOccupationId, _setNextOccupationId] = useState(null)

  const [loadingTargetOccupations, setLoadingTargetOccupations] =
    useState(false)
  const [targetOccupations, setTargetOccupations] = useState(null)
  const [targetOccupationId, setTargetOccupationId] = useState(null)
  const [targetOccupation, setTargetOccupation] = useState(null)

  // API fetching

  // Get salary ranges and next occupations on visualization confirmation
  useEffect(() => {
    if (!currOccupationId) {
      return
    }

    const lang = document.documentElement.getAttribute('lang')

    setLoadingNextOccupations(true)

    getOccupations(currOccupationId, 'next-occupations', lang)
      .then((nextOccupationsData) => {
        setNextOccupations(filterNextOccupations(nextOccupationsData))
      })
      .finally(() => {
        setHasLoadedFirstOccupations(true)
        setLoadingNextOccupations(false)
      })

    getSalaryDetails(currOccupationId, lang).then((salaryDetails) => {
      setOccupationsSalaryDetails(salaryDetails)
    })
  }, [currOccupationId])

  // Fetch target occupations on next occupation selection
  useEffect(() => {
    if (!nextOccupationId) {
      return
    }

    setLoadingTargetOccupations(true)

    const lang = document.documentElement.getAttribute('lang')

    getOccupations(nextOccupationId, 'target-occupations', lang)
      .then((nextOccupationsData) => {
        setTargetOccupations(nextOccupationsData)
      })
      .finally(() => {
        setLoadingTargetOccupations(false)
      })
  }, [nextOccupationId])

  // Fetch details about selected target occupation
  useEffect(() => {
    if (!nextOccupationId || !targetOccupationId) {
      setTargetOccupation(null)

      return
    }
    const lang = document.documentElement.getAttribute('lang')

    getOccupationDetails(nextOccupationId, targetOccupationId, lang).then(
      (occupationDetails) => {
        setTargetOccupation(occupationDetails)
      }
    )
  }, [nextOccupationId, targetOccupationId])

  // Callbacks

  const setNextOccupationId = useCallback((id) => {
    _setNextOccupationId(id)

    if (!id) {
      setTargetOccupations(null)
    }

    setTargetOccupationId(null)
    setTargetOccupation(null)
  }, [])

  const confirmOccupation = useCallback(() => {
    const title = getBaseOccupationTitle(occupationId)
    if (title) {
      setEditingOccupation(false)
    }

    /**
     * Clear all visualization state
     * when we change our selection
     */
    setCurrOccupationTitle(title)
    setOccupationsSalaryDetails(null)
    setCurrOccupationId(occupationId)
    _setNextOccupationId(null)
    setTargetOccupations(null)
    setTargetOccupationId(null)
    setTargetOccupation(null)
  }, [occupationId])

  return {
    occupationId,
    setOccupationId,
    confirmOccupation,
    editingOccupation,
    setEditingOccupation,
    currentOccupationTitle: currOccupationTitle,
    occupationsSalaryDetails,
    hasLoadedFirstOccupations,
    loadingNextOccupations,
    nextOccupations,
    nextOccupationId,
    setNextOccupationId,
    loadingTargetOccupations,
    targetOccupations,
    targetOccupationId,
    setTargetOccupationId,
    selectedTargetOccupation: targetOccupation,
    setTargetOccupation,
  }
}
