import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './index.module.scss'
import { ReactComponent as NextIcon } from 'assets/images/skip-forward.svg'
import { ReactComponent as TargetIcon } from 'assets/images/target.svg'
import { ReactComponent as ToolTip } from 'assets/images/tooltip.svg'
import { ReactComponent as ExpandIcon } from 'assets/images/button-collapse.svg'
import { forwardRef, useCallback, useLayoutEffect, useRef } from 'react'
import { formatSalaryLimit } from '../_Index/lib'
import { useTranslation } from 'react-i18next'
import { AnimatePresence, motion } from 'framer-motion'
import Loader from 'components/pieces/Loader'
import { usePaths } from './paths'
import { useTooltip } from 'utils/tooltip'
import { useTooltip as useTip } from '@react-aria/tooltip'
import { mergeProps } from 'react-aria'
import { parseHtml } from 'utils/parseHtml'

const Occupation = forwardRef(
  ({ buttonRef, className, occupation, selected, onClick, children }, ref) => {
    const handleClick = useCallback(() => {
      if (selected) {
        onClick(null)
      } else {
        onClick(occupation.onet)
      }
    }, [onClick, selected, occupation])

    return (
      <li ref={ref}>
        <button
          ref={buttonRef}
          onClick={handleClick}
          className={cn(className, styles.occupation, {
            [styles.selected]: selected,
          })}>
          {children}
        </button>
      </li>
    )
  }
)

Occupation.displayName = 'Occupation'

Occupation.propTypes = {
  buttonRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  className: PropTypes.string,
  occupation: PropTypes.object,
  onClick: PropTypes.func,
  children: PropTypes.node,
  selected: PropTypes.bool,
}

const LabelTooltip = ({ state, ...props }) => {
  let { tooltipProps } = useTip(props, state)

  return (
    <span className={styles.tooltip} {...mergeProps(props, tooltipProps)}>
      {props.children}
    </span>
  )
}

LabelTooltip.propTypes = {
  state: PropTypes.object,
  children: PropTypes.node,
}

const OccupationTable = ({
  className,
  occupationsSalaryDetails,
  hasLoadedFirstOccupations,
  loadingNextOccupations,
  nextOccupations,
  nextOccupationId,
  onNextOccupationSelect,
  onTargetOccupationSelect,
  loadingTargetOccupations,
  targetOccupations,
}) => {
  const { t } = useTranslation('progression')
  const hasOccupations = hasLoadedFirstOccupations && nextOccupations.length > 0
  const refs = usePaths({ hasOccupations, loadingTargetOccupations })
  const firstTargetTooltipRef = useRef(null)
  const nextTooltip = useTooltip(t('occupations.next_tooltip'))
  const targetTooltip = useTooltip(t('occupations.target_tooltip'))

  useLayoutEffect(() => {
    if (!loadingTargetOccupations && firstTargetTooltipRef.current) {
      firstTargetTooltipRef.current.focus()
    }
  }, [loadingTargetOccupations])

  return (
    <div
      ref={refs.containerRef}
      className={cn(className, styles.container, {
        [styles.loading]: loadingNextOccupations,
      })}>
      {hasOccupations && (
        <svg alt="" ref={refs.labelSvgRef} className={styles.paths} />
      )}
      <AnimatePresence>
        {nextOccupationId && (
          <motion.svg
            alt=""
            key={nextOccupationId}
            className={styles.paths}
            ref={!loadingNextOccupations ? refs.svgRef : null}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1 }}
          />
        )}
      </AnimatePresence>
      {hasOccupations && (
        <div className={styles.column}>
          <div ref={refs.nextLabelRef} className={styles.columnLabelContainer}>
            <NextIcon alt="" className={styles.columnIcon} />
            <div className={styles.columnLabel}>
              <h4>{t('occupations.next_label')}</h4>{' '}
              {occupationsSalaryDetails && (
                <span className={styles.columnRange}>
                  {formatSalaryLimit(occupationsSalaryDetails.next_lb_national)}
                  {'-'}
                  {formatSalaryLimit(occupationsSalaryDetails.next_ub_national)}
                </span>
              )}
            </div>
            <button ref={nextTooltip.tooltipRef} {...nextTooltip.triggerProps}>
              <ToolTip alt="Info" className={styles.tooltipIcon} />
            </button>
          </div>
          <AnimatePresence>
            {nextTooltip.tooltipState.isOpen && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.1 }}
                className={styles.tooltipContainer}>
                <LabelTooltip
                  state={nextTooltip.tooltipState}
                  {...nextTooltip.tooltipProps}>
                  {parseHtml(t('occupations.next_tooltip'))}
                </LabelTooltip>
              </motion.div>
            )}
          </AnimatePresence>
          {nextOccupations && (
            <ul className={styles.occupationList}>
              {nextOccupations.map((occupation) => {
                const selected = occupation.onet === nextOccupationId

                return (
                  <Occupation
                    key={occupation.onet}
                    occupation={occupation}
                    ref={selected ? refs.nextRef : null}
                    selected={selected}
                    onClick={onNextOccupationSelect}
                    className={styles.nextOccupation}>
                    <ExpandIcon className={styles.expandIcon} />{' '}
                    {occupation.role}
                  </Occupation>
                )
              })}
            </ul>
          )}
        </div>
      )}
      {hasOccupations && (
        <div className={styles.column}>
          <div
            ref={refs.targetLabelRef}
            className={styles.columnLabelContainer}>
            <TargetIcon alt="" className={styles.columnIcon} />
            <div className={styles.columnLabel}>
              <h4>{t('occupations.target_label')}</h4>{' '}
              {occupationsSalaryDetails && (
                <span className={styles.columnRange}>
                  {formatSalaryLimit(
                    occupationsSalaryDetails.target_lb_national
                  )}
                  {'-'}
                  {formatSalaryLimit(
                    occupationsSalaryDetails.target_ub_national
                  )}
                </span>
              )}
            </div>
            <button
              ref={targetTooltip.tooltipRef}
              {...targetTooltip.triggerProps}>
              <ToolTip alt="Info" className={styles.tooltipIcon} />
            </button>
          </div>
          <AnimatePresence>
            {targetTooltip.tooltipState.isOpen && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.1 }}
                className={styles.tooltipContainer}>
                <LabelTooltip
                  state={targetTooltip.tooltipState}
                  {...targetTooltip.tooltipProps}>
                  {parseHtml(t('occupations.target_tooltip'))}
                </LabelTooltip>
              </motion.div>
            )}
          </AnimatePresence>
          {targetOccupations ? (
            <ul
              ref={refs.targetsRef}
              className={cn(styles.occupationList, {
                [styles.loading]: loadingTargetOccupations,
              })}>
              {targetOccupations.map?.((occupation, i) => (
                <Occupation
                  key={occupation.onet}
                  occupation={occupation}
                  buttonRef={i === 0 ? firstTargetTooltipRef : null}
                  onClick={onTargetOccupationSelect}
                  className={cn(styles.targetOccupation)}>
                  {occupation.role}
                </Occupation>
              ))}
            </ul>
          ) : loadingTargetOccupations ? (
            <div className={styles.columnLoader}>
              <Loader />
            </div>
          ) : nextOccupationId ? (
            <div className={styles.emptyContainer}>
              <p className={styles.emptyMessage}>
                {t('selector.no_results_label')}
              </p>
            </div>
          ) : (
            <div className={styles.emptyContainer}>
              <ExpandIcon className={styles.emptyIcon} />
              <p className={styles.emptyMessage}>
                {t('occupations.empty_message')}
              </p>
            </div>
          )}
        </div>
      )}
      {!hasOccupations && !loadingNextOccupations && (
        <div className={styles.errorContainer}>
          <p className={styles.error}>No results found</p>
        </div>
      )}
      {loadingNextOccupations && (
        <div className={styles.loader}>
          <Loader />
        </div>
      )}
    </div>
  )
}

export default OccupationTable

OccupationTable.propTypes = {
  className: PropTypes.string,
  occupationsSalaryDetails: PropTypes.object,
  hasLoadedFirstOccupations: PropTypes.bool,
  loadingNextOccupations: PropTypes.bool,
  nextOccupations: PropTypes.array,
  loadingTargetOccupations: PropTypes.bool,
  targetOccupations: PropTypes.array,
  nextOccupationId: PropTypes.string,
  onNextOccupationSelect: PropTypes.func,
  onTargetOccupationSelect: PropTypes.func,
}
