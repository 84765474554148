const params = 'menubar=no,toolbar=no,status=no,width=570,height=570' // for window

// twitter
const HASH_TAGS = ['ReadyToWork']
const HASH_TAG_PARAM = HASH_TAGS.reduce(
  (prevStr, hash) => `${prevStr}&hashtags=${hash}`,
  ''
)
const TWITTER_SHARE_TEXT = encodeURIComponent(
  `Who’s ready for better work? ✋ Check out Ready to Work by @indeed to connect with actively hiring employers and attend in-person events across the U.S. 👉`
)

// email
const EMAIL_TITLE = encodeURIComponent(
  `You might like this: Ready to Work? Employers are ready to hire.`
)
const EMAIL_TEXT = encodeURIComponent(
  `Indeed has brought together a coalition to help job seekers on their journey to better work. Through the Ready to Work virtual hub, you can connect with actively hiring employers, sign up for in-person events and access resources to help you in your job search. Check it out!
  
https://www.indeed.com/readytowork`
)

export const handleSocialShare = (type, payload) => {
  let shareUrl = ''
  let site_url = window?.location?.origin + window?.location?.pathname

  const locale = document.documentElement.getAttribute('lang')
  if (locale === 'es') {
    site_url += '?lng=es'
  }

  const URL = encodeURIComponent(site_url)
  const { twitter, email } = payload || {}
  const SHARE_TEXT = twitter?.text && encodeURIComponent(twitter?.text)
  const EMAIL_SHARE_TITLE = email?.subject ?? EMAIL_TITLE
  const EMAIL_SHARE_BODY = email?.body ?? EMAIL_TEXT

  switch (type) {
    case 'facebook':
      shareUrl = `https://www.facebook.com/sharer/sharer.php?hashtag=%23${HASH_TAGS[0]}&u=${URL}`

      break

    case 'twitter':
      shareUrl = `https://twitter.com/intent/tweet?url=${URL}&text=${
        SHARE_TEXT ?? TWITTER_SHARE_TEXT
      }${HASH_TAG_PARAM}`

      break

    case 'linkedin':
      shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${URL}&source=linkedin`

      break

    case 'email':
      shareUrl = `mailto:?subject=${EMAIL_SHARE_TITLE}&body=${EMAIL_SHARE_BODY}`
      break
    default:
  }

  window.open(shareUrl, 'NewWindow', params)
}
