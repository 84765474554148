import detroitBg from 'assets/images/detroit.svg'
import detroitMap from 'assets/images/detroit-map.jpg'
import chicagoBg from 'assets/images/chicago.svg'
import chicagoMap from 'assets/images/chicago-map.jpg'
import dcBg from 'assets/images/washington-dc.svg'
import dcMap from 'assets/images/washington-dc-map.jpg'
import laBg from 'assets/images/los-angeles.svg'
import laMap from 'assets/images/los-angeles-map.jpg'
import dallasBg from 'assets/images/dallas.svg'
import dallasMap from 'assets/images/dallas-map.jpg'

export const IRL_EVENTS = [
  {
    title: 'Chicago',
    handle: 'chicago',
    bgColor: '#c74289',
    formId: 126,
    formIdEs: 217,
    map: chicagoMap,
    imagePosition: '0 35%',
    bgImage: { src: chicagoBg },
  },
  {
    formId: 128,
    formIdEs: 219,
    title: 'Detroit',
    handle: 'detroit',
    bgColor: '#1B3D52',
    imagePosition: '0 30%',
    map: detroitMap,
    bgImage: { src: detroitBg },
  },
  {
    formId: 129,
    formIdEs: 220,
    title: 'Washington D.C.',
    handle: 'washington-dc',
    bgColor: '#358271',
    imagePosition: '0 32%',
    map: dcMap,
    bgImage: { src: dcBg },
  },
  {
    title: 'Los Angeles',
    handle: 'los-angeles',
    bgColor: '#5644bf',
    formId: 97,
    formIdEs: 199,
    imagePosition: '0 30%',
    map: laMap,
    bgImage: { src: laBg, top: true },
  },
  {
    title: 'DFW',
    handle: 'dallas-fort-worth',
    bgColor: '#A54411',
    formId: 127,
    formIdEs: 218,
    imagePosition: '0 35%',
    map: dallasMap,
    bgImage: { src: dallasBg, top: true },
  },
]
