/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types'
import styles from './index.module.scss'
import IconCard from 'components/pieces/Card/IconCard'
import ImageCard from './ImageCard'
import GalleryCard from './GalleryCard'
import { ReactComponent as Bg } from 'assets/images/resource-card-bg.svg'

const CarouselCard = ({ type, ...props }) => {
  return (
    <>
      {type === 'eventGallery' ? (
        <GalleryCard {...props} type={type} />
      ) : type === 'resources' ? (
        <IconCard
          {...props}
          type={type}
          Bg={<Bg />}
          className={styles.resourceSlide}
        />
      ) : (
        <ImageCard {...props} type={type} />
      )}
    </>
  )
}

CarouselCard.propTypes = {
  type: PropTypes.string,
}
export default CarouselCard
