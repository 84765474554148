import styles from './index.module.scss'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import Container from '../../layout/Container'
import Image from 'components/pieces/Image'
import Button from 'components/pieces/Button'
import Grid from '../../layout/Grid'
import { getColor } from 'utils/getColor'
import SectionHeader from 'components/pieces/SectionHeader'

const Banner = ({ imageSrc, shadowColor = 'indigo' }) => {
  const color = getColor(shadowColor)
  const { t } = useTranslation('employers')
  const ctas = t('ctas', { returnObjects: true })

  return (
    <Container>
      <Grid>
        <div className={styles.header}>
          <SectionHeader eyebrow={t('eyebrow')} title={t('title')} />
        </div>
      </Grid>
      <Grid className={styles.wrapper}>
        <div className={cn(styles.textWrapper)}>
          <h3 className={styles.description}>{t('description')}</h3>
          <div className={styles.buttonWrapper}>
            {ctas?.map((cta, i) => (
              <Button
                key={`employer-cta-${i}`}
                type="primary"
                link={cta.cta_link}
                gaEventData={['Employers', 'Click', cta.cta_text]}
                fullWidth={true}>
                {cta.cta_text}
              </Button>
            ))}
          </div>
        </div>
        <div
          className={cn(styles.imageWrapper)}
          style={{ '--shadow-color': color }}>
          <Image
            image={{ alt: '', url: imageSrc, height: 264, width: 434 }}
            className="imageWrapper"
            layout="responsive"
            round
          />
        </div>
      </Grid>
    </Container>
  )
}

export default Banner

Banner.propTypes = {
  imageSrc: PropTypes.string,
  shadowColor: PropTypes.string,
}
