import {
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './index.module.scss'
import PageLine from './pageline'
import { motion } from 'framer-motion'

const PageLineContext = createContext({})

const PageLineCheckpoint = ({ className, invisible }) => {
  const ref = useRef()
  const { pageLine } = useContext(PageLineContext)

  useEffect(() => {
    if (!pageLine) {
      return
    }
    const marker = ref.current
    pageLine.addCheckpoint(marker)

    return () => {
      pageLine.removeCheckpoint(marker)
    }
  }, [pageLine])

  return (
    <span
      role="presentation"
      ref={ref}
      className={cn(
        styles.checkpoint,
        { [styles.invisible]: invisible },
        className
      )}></span>
  )
}

PageLineCheckpoint.propTypes = {
  className: PropTypes.string,
  invisible: PropTypes.bool,
}

const PageLineWrapper = ({ active, children }) => {
  const [pageLine] = useState(() => new PageLine(active))

  useEffect(() => {
    pageLine.setActive(active)
  }, [pageLine, active])

  return (
    <PageLineContext.Provider value={{ pageLine }}>
      {children}
    </PageLineContext.Provider>
  )
}

PageLineWrapper.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
}

const PageLineSVG = () => {
  const { pageLine } = useContext(PageLineContext)
  const ref = useRef()

  useLayoutEffect(() => {
    if (!pageLine) {
      return
    }

    pageLine.setup(ref.current)

    return () => {
      pageLine.unmount()
    }
  }, [pageLine])

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 0.5 }}
      transition={{ delay: 0.25, ease: 'easeOut' }}
      ref={ref}
      className={styles.container}
      id="pageline"
      aria-hidden="true"></motion.div>
  )
}

export default PageLineWrapper
export { PageLineSVG, PageLineCheckpoint }
