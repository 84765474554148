import { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import Container from 'components/layout/Container'
import Grid from 'components/layout/Grid'
import SectionHeader from 'components/pieces/SectionHeader'
import EventCard from 'components/pieces/EventCard'
import { IRL_EVENTS } from 'constant/events'
import { useDocumentSize } from 'utils/useDocumentSize'
import { isPast } from 'date-fns'
import Carousel from '../Carousel/_Index'

import eventImage1 from 'assets/images/events/event1.jpg'
import eventImage2 from 'assets/images/events/event2.jpg'
import eventImage3 from 'assets/images/events/event3.jpg'
import eventImage4 from 'assets/images/events/event4.jpg'
import eventImage5 from 'assets/images/events/event5.jpg'
import eventImage6 from 'assets/images/events/event6.jpg'
import eventImage7 from 'assets/images/events/event7.jpg'
import eventImage8 from 'assets/images/events/event8.jpg'
import eventImage9 from 'assets/images/events/event9.jpg'
import eventImage10 from 'assets/images/events/event10.jpg'
import eventImage11 from 'assets/images/events/event11.jpg'
import eventImage12 from 'assets/images/events/event12.jpg'

const eventImages = [
  eventImage1,
  eventImage2,
  eventImage3,
  eventImage4,
  eventImage5,
  eventImage6,
  eventImage7,
  eventImage8,
  eventImage9,
  eventImage10,
  eventImage11,
  eventImage12,
]

const Events = () => {
  const isPostEvents = process.env.REACT_APP_STATE === 'events_over'
  const { t } = useTranslation('events', 'common')
  const documentSize = useDocumentSize() || {}
  const events = t('events', { returnObjects: true })
  const eventGalleryItems = eventImages.map((img) => ({
    image: {
      url: img,
    },
  }))
  const [activeEvent, setActiveEvent] = useState('')

  const _events = events.map((event, i) => ({
    ...event,
    ...IRL_EVENTS[i],
  }))
  const _pastEvents = []
  const _currentEvents = []
  _events.forEach((event) => {
    const eventDate = new Date(event.datetime_end)
    const currentDate = new Date()
    const isPastEvent = isPast(eventDate, currentDate)
    if (isPastEvent) {
      _pastEvents.push(event)
    } else {
      _currentEvents.push(event)
    }
  })

  useEffect(() => {
    if (events?.length && !activeEvent) {
      setActiveEvent(events[0]?.title)
    }
  }, [events, activeEvent])

  return (
    <>
      <Container className={styles.container}>
        <Grid>
          <div className={styles.headerWrapper}>
            <SectionHeader
              eyebrow={isPostEvents ? t('post.eyebrow') : t('eyebrow')}
              title={isPostEvents ? t('post.title') : t('title')}
              description={
                isPostEvents ? t('post.description') : t('description')
              }
            />
          </div>
        </Grid>

        <Grid className={styles.grid}>
          <div className={styles.eventsWrapper}>
            <div className={styles.events}>
              {_currentEvents?.length &&
                _currentEvents?.map((item, i) => {
                  return (
                    <EventCard
                      key={'current' + i}
                      id={item.title}
                      setActiveEvent={setActiveEvent}
                      // on tablet and mobile, all cards are collapsed
                      open={
                        activeEvent === item.title || documentSize?.width < 1024
                      }
                      event={item}
                    />
                  )
                })}
              {_pastEvents?.length &&
                _pastEvents?.map((item, i) => {
                  return (
                    <EventCard
                      key={'past' + i}
                      id={item?.title}
                      setActiveEvent={setActiveEvent}
                      // on tablet and mobile, all cards are collapsed
                      open={
                        activeEvent === item.title || documentSize?.width < 1024
                      }
                      event={item}
                      isPast
                    />
                  )
                })}
            </div>
          </div>
        </Grid>
      </Container>

      {eventGalleryItems?.length && (
        <div className={styles.gallery}>
          <Carousel
            items={eventGalleryItems}
            type="eventGallery"
            showNavigation
          />
        </div>
      )}
    </>
  )
}

export default Events
