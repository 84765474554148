export const getMarqueeItems = (items, reducedMotion) => {
  const _items = [
    ...items,
    ...(reducedMotion ? [] : items).map((item) => {
      const { top, bottom, position } = item

      // add additional copy of data
      // reverse the position of each item for the copy if there is an odd number of items
      const newItem = {
        ...item,
        position:
          items?.length % 2 === 1
            ? position === 'top'
              ? 'bottom'
              : 'top'
            : position,
        top: items?.length % 2 === 1 ? (top ? undefined : bottom) : top,
        bottom: items?.length % 2 === 1 ? (bottom ? undefined : top) : bottom,
      }

      return newItem
    }),
  ]

  return _items
}
