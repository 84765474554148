/* eslint-disable react/prop-types */

import { useEffect, useState } from 'react'
import { createContext, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useQuery } from 'utils/useQuery'

const LocaleContext = createContext({})

export const LocaleProvider = ({ children }) => {
  const location = useLocation()
  const { i18n } = useTranslation()
  // get locale from user setting
  const userLocale =
    navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.language
  const [tempLocale, setTempLocale] = useState(
    ['en', 'es'].indexOf(userLocale) === -1 ? 'en' : userLocale
  )

  const qp = useQuery()

  useEffect(() => {
    const temp = qp.get('lng')

    if (temp) {
      const newLocale = temp.replace('/', '')
      const _newLocale =
        ['en', 'es'].indexOf(newLocale) === -1 ? 'en' : newLocale
      setTempLocale(_newLocale)
    } else if (localStorage.getItem('lng')) {
      setTempLocale(localStorage.getItem('lng'))
    }
  }, [qp])

  useEffect(() => {
    i18n.changeLanguage(tempLocale)
    localStorage.setItem('lng', tempLocale)
    // update html lang tag
    const tagLocale = document.documentElement.getAttribute('lang')
    if (tagLocale !== tempLocale) {
      document.documentElement.setAttribute('lang', tempLocale)
    }

    // if there is lng query in url, replace it
    window?.history.replaceState(
      {},
      '',
      window.location.href.replace(/lng=[a-z][a-z]/, `lng=${tempLocale}`)
    )
  }, [tempLocale, i18n, location])

  const formatPercent = (val) => {
    return `${val}%`
  }

  const formatNumber = (val, options = {}) => {
    const locale = i18n.language

    const { decimals = 0, abbr, max, isCurrency } = options

    const currencyOptions = isCurrency
      ? { style: 'currency', currency: 'USD' }
      : {}

    let valToFormat = val
    let isMaxed = false
    let isAbbr = false

    if (max && val > max) {
      valToFormat = max
      isMaxed = true
    }

    if (abbr && valToFormat >= 1000) {
      valToFormat = Math.floor(valToFormat / 1000)
      isAbbr = true
    }

    return `${new Intl.NumberFormat(isCurrency ? locale : 'en-US', {
      ...currencyOptions,
      maximumFractionDigits: decimals,
      minimumFractionDigits: decimals,
    }).format(valToFormat)}${isAbbr ? 'K' : ''}${isMaxed ? '+' : ''}`
  }

  return (
    <LocaleContext.Provider
      value={{
        formatPercent,
        formatNumber,
        changeLocale: setTempLocale,
      }}>
      {children}
    </LocaleContext.Provider>
  )
}

export const useLocale = () => useContext(LocaleContext)
