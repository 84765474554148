import PropTypes from 'prop-types'
import styles from './index.module.scss'
import classNames from 'classnames'

const Container = ({ children, className, Tag = 'div', fullbleed = false }) => {
  return (
    <Tag
      className={classNames(className, styles.container, {
        [styles.fullbleed]: fullbleed,
      })}>
      {children}
    </Tag>
  )
}

export default Container

Container.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  Tag: PropTypes.string,
  fullbleed: PropTypes.bool,
}
