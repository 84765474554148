import style from './index.module.scss'
import { ReactComponent as Arrow } from 'assets/images/arrow-long.svg'
import IconWrapper from '../IconWrapper'
import InViewReveal from '../InViewReveal'
import Container from 'components/layout/Container'
import { scrollTo } from 'utils/scrollTo'
import gaEvent from 'utils/analytics'
import { useTranslation } from 'react-i18next'

const BackToTop = () => {
  const { t } = useTranslation('ada')

  const handleClick = () => {
    document?.activeElement.blur()

    gaEvent('Global', 'Click', 'Jump to top button')

    scrollTo(0, () => {
      const header = document.querySelector('header')
      const firstFocusable = header?.querySelector('a')
      firstFocusable?.focus()
    })
  }

  return (
    <InViewReveal>
      <div>
        <Container>
          <IconWrapper
            wrapperClassName={style.button}
            onClick={() => handleClick()}
            aria={t('global.scroll_to_top')}>
            <Arrow />
          </IconWrapper>
        </Container>
      </div>
    </InViewReveal>
  )
}

export default BackToTop
