import image1 from 'assets/images/placeholder1.jpg'
import image2 from 'assets/images/placeholder2.jpg'
import image3 from 'assets/images/placeholder3.jpg'
import image4 from 'assets/images/placeholder4.jpg'
import image5 from 'assets/images/resources-5.jpg'
import lyft from 'assets/images/lyft.png'
import dunkin from 'assets/images/dunkin.png'
import appreciation from 'assets/images/wellbeing/appreciation.png'
import flexibility from 'assets/images/wellbeing/flexibility.png'
import trust from 'assets/images/wellbeing/trust.png'
import support from 'assets/images/wellbeing/support.png'
import learning from 'assets/images/wellbeing/learning.png'
import inclusion from 'assets/images/wellbeing/inclusion.png'
import energy from 'assets/images/wellbeing/energy.png'
import achievment from 'assets/images/wellbeing/achievment.png'
import fairpay from 'assets/images/wellbeing/fairpay.png'
import belonging from 'assets/images/wellbeing/belonging.png'
import managersupport from 'assets/images/wellbeing/managersupport.png'
import rightQuestions from 'assets/images/right-questions.jpg'
import valerie_jarrett from 'assets/images/headshots/valerie_jarrett.jpg'
import ugo_mozie from 'assets/images/headshots/ugo_mozie.jpg'
import Derrick_Coleman from 'assets/images/headshots/Derrick-Coleman.jpg'
import Gabourey_Sidibe from 'assets/images/headshots/Gabourey-Sidibe.jpg'
import Joanne_Molinaro from 'assets/images/headshots/Joanne-Molinaro.jpg'
import Opal_Lee from 'assets/images/headshots/Opal-Lee.jpg'
import Qian_Julie_Wang from 'assets/images/headshots/Qian-Julie-Wang.jpg'
import stephanie_izard from 'assets/images/headshots/stephanie-izard-updated.jpg'
import Don_Crawley from 'assets/images/headshots/Don-Crawley.jpg'

export const imageResolver = (name, type) => {
  switch (name) {
    case 'tip-image1':
    case 'partners-image1':
      return image1
    case 'tip-image5':
    case 'partners-image5':
      return image5
    case 'tip-image2':
    case 'partners-image2':
      return image2
    case 'tip-image3':
    case 'partners-image3':
      return image3
    case 'tip-image4':
    case 'partners-image4':
      return image4
    case 'right-questions':
      return rightQuestions
    case 'Lyft':
      return lyft
    case 'Dunkin':
      return dunkin
    case 'Appreciation':
      return appreciation
    case 'Flexibility':
      return flexibility
    case 'Trust':
      return trust
    case 'Support':
      return support
    case 'Learning':
      return learning
    case 'Inclusion':
      return inclusion
    case 'Energy':
      return energy
    case 'Achievement':
      return achievment
    case 'Fair pay':
      return fairpay
    case 'Belonging':
      return belonging
    case 'Manager support':
      return managersupport
    case 'Valerie Jarrett':
      return valerie_jarrett
    case 'Gabourey Sidibe':
      return Gabourey_Sidibe
    case 'Don C':
      return Don_Crawley
    case 'Stephanie Izard':
      return stephanie_izard
    case 'Joanne Molinaro':
      return Joanne_Molinaro
    case 'Derrick Coleman':
      return Derrick_Coleman
    case 'Opal Lee':
      return Opal_Lee
    case 'Ugo Mozie':
      return ugo_mozie
    case 'Qian Julie Wang':
      return Qian_Julie_Wang

    default:
      return name
  }
}
