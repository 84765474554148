import styles from './index.module.scss'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import indeedDunkinLogo from 'assets/images/indeed-dunkin.svg'
import { ReactComponent as Exit } from 'assets/images/exit.svg'
import Button from 'components/pieces/Button'
import IconWrapper from 'components/pieces/IconWrapper'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { parseHtml } from 'utils/parseHtml'

const DunkinModal = ({ setShowDunkin }) => {
  const { t } = useTranslation('dunkinModal', 'ada')
  const handleClick = useCallback(
    (e) => {
      if (
        e.target.id === 'dunkin-modal' ||
        e.target.parentElement.id === 'dunkin-modal'
      )
        return
      setShowDunkin(false)
    },
    [setShowDunkin]
  )

  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => document.removeEventListener('click', handleClick)
  }, [handleClick])

  return (
    <motion.div
      initial={{ y: '100px', opacity: 0 }}
      animate={{ y: '0px', opacity: 1 }}
      transition={{ delay: 0.25, ease: 'easeOut' }}
      className={styles.wrapper}>
      <div id="dunkin-modal" className={styles.modal}>
        <img
          className={styles.logo}
          src={indeedDunkinLogo}
          alt="Indeed + Dunkin'"
        />
        <p className={styles.description}>{parseHtml(t('description'))}</p>
        <Button
          fullWidth={true}
          onClick={() => {
            setShowDunkin(false)
          }}
          className={styles.button}
          type="reversed">
          {parseHtml(t('cta_text'), 'span')}
        </Button>
        <IconWrapper
          wrapperClassName={styles.exit}
          onClick={() => {
            setShowDunkin(false)
          }}
          aria={t('global.continue_to_site')}>
          <Exit />
        </IconWrapper>
      </div>
    </motion.div>
  )
}

DunkinModal.propTypes = {
  setShowDunkin: PropTypes.func,
}

export default DunkinModal
