import PropTypes from 'prop-types'
import styles from './index.module.scss'
import Image from '../Image'
import { parseHtml } from 'utils/parseHtml'
import imageShape from 'shapes/image'
import { imageResolver } from 'utils/imageResolver'

const Bio = ({ speaker, description, links }) => {
  return (
    <>
      <div className={styles.leftCol}>
        <div className={styles.imageContainer}>
          <Image
            lazy={false}
            image={{ url: imageResolver(speaker) }}
            alt={`${speaker} headshot`}
          />
        </div>
      </div>

      <div className={styles.rightCol}>
        <div className={styles.text}>{parseHtml(description)}</div>
        <ul className={styles.links}>
          {links?.map((link) => {
            return (
              <a
                href={link?.link}
                target="_blank"
                rel="noreferrer"
                key={speaker + link.type}
                className={styles.link}>
                {link?.type}
              </a>
            )
          })}
        </ul>
      </div>
    </>
  )
}

Bio.propTypes = {
  speaker: PropTypes.string,
  description: PropTypes.string,
  headshot: imageShape,
  links: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export default Bio
