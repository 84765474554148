import { useState } from 'react'
import { Box } from '@mui/material'
import FeedbackInput from './FeedbackInput'
import FeedbackTextInput from './FeedbackTextInput'

const Feedback = (props) => {
  // eslint-disable-next-line react/prop-types
  const { onScore, onSubmit } = props
  const [score, setScore] = useState()
  const [feedback, setFeedback] = useState('')
  const [hasFeedback, setHasFeedback] = useState(false)

  const handleScoreChange = (score) => {
    setScore(score)
    onScore(score)
  }

  const handleSubmit = () => {
    onSubmit(score, feedback)
  }

  return (
    <Box>
      <Box
        component="section"
        sx={{
          background: 'rgba(255, 255, 255, .06)',
          overflow: [null, null, 'hidden'],
          position: 'relative',
          color: 'white',
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          {!hasFeedback ? (
            <FeedbackInput
              score={score}
              setScore={handleScoreChange}
              setHasFeedback={setHasFeedback}
            />
          ) : (
            <FeedbackTextInput
              score={score}
              feedback={feedback}
              setFeedback={setFeedback}
              handleSubmit={handleSubmit}
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default Feedback
