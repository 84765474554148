import { createContext, useContext } from 'react'
import PropTypes from 'prop-types'

const ThemeContext = createContext()

export function ThemeContextWrapper({ theme = 'dark', setTheme, children }) {
  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}

export function useThemeContext() {
  return useContext(ThemeContext)
}

ThemeContextWrapper.propTypes = {
  theme: PropTypes.oneOf(['dark', 'light']),
  setTheme: PropTypes.func,
  children: PropTypes.node,
}
