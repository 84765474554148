import Layout from 'components/layout/_Layout'
import IntroAnimation from 'components/pieces/IntroAnimation'
import { useState, useEffect } from 'react'
import Slices from 'components/slices/_Index'
import { Helmet } from 'react-helmet'
import DunkinModal from 'components/slices/DunkinModal'
import { IntroContextWrapper } from 'components/layout/_Layout/introContext'
import { gaPageview } from 'utils/analytics'
import { useIntroHasPlayed } from 'utils/useIntroHasPlayed'
import useStore from 'components/layout/_Layout/introStore'
import { useTranslation } from 'react-i18next'

function Home() {
  const [showDunkin, setShowDunkin] = useState(false)
  const [interruptIntro, setInterruptIntro] = useState(false)
  const introComplete = useStore((state) => state.introComplete)
  const { t } = useTranslation('global')

  useEffect(() => {
    gaPageview()

    const isDunkin = window.location.hash === '#dunkin'
    if (isDunkin) setShowDunkin(true)
  }, [])

  const skipIntro = useIntroHasPlayed()

  return (
    <IntroContextWrapper
      interruptIntro={interruptIntro}
      setInterruptIntro={setInterruptIntro}>
      {!skipIntro && <IntroAnimation interrupted={interruptIntro} />}
      {(introComplete || skipIntro) && (
        <div
          className="Home"
          style={{
            visibility: introComplete || skipIntro ? 'visible' : 'hidden',
            overflow: 'hidden',
            opacity: navigator.userAgent === 'ReactSnap' ? 0 : 1,
          }}>
          <Helmet>
            <title data-react-helmet="true">{t('site.meta_title')}</title>
            <meta
              property="og:title"
              content={t('site.meta_title')}
              data-react-helmet="true"
            />
            <meta
              name="twitter:title"
              content={t('site.meta_title')}
              data-react-helmet="true"
            />
            <meta
              name="description"
              content={t('site.meta_description')}
              data-react-helmet="true"
            />
            <meta
              property="og:description"
              content={t('site.meta_description')}
              data-react-helmet="true"
            />
            <meta
              name="twitter:description"
              content={t('site.meta_description')}
              data-react-helmet="true"
            />
          </Helmet>
          <Layout showDunkin={showDunkin} pageLine>
            {showDunkin && <DunkinModal setShowDunkin={setShowDunkin} />}
            {!showDunkin && <Slices />}
          </Layout>
        </div>
      )}
    </IntroContextWrapper>
  )
}

export default Home
