import PropTypes from 'prop-types'
import imageShape from './image'

const companyShape = PropTypes.shape({
  position: PropTypes.oneOf(['top', 'bottom']),
  logo: imageShape,
  name: PropTypes.string,
})

export default companyShape
