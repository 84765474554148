import PropTypes from 'prop-types'
import styles from './index.module.scss'
import useMarketo from 'utils/useMarketo'
import gaEvent from 'utils/analytics'
import Button from 'components/pieces/Button'
import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'

const MarketoForm = ({
  formId,
  hide,
  setHide,
  gaEventData,
  successMessage,
}) => {
  const { t } = useTranslation('events')
  const form = useMarketo({
    formId: formId,
    callback: function (form) {
      // Add an onSuccess handler
      form.onSuccess(function () {
        setHide(true)

        // Return false to prevent the submission handler from taking the lead to the follow up url
        return false
      })

      //listen for the validate event
      form.onValidate(function () {
        // Get the values
        // var vals = form.vals()
        //Check your condition
        // if (!vals.Email) {
        //   // Prevent form submission
        //   form.submittable(false)
        //   // Show error message
        //   form.showErrorMessage('error')
        // } else {
        //   // Enable submission for those who met the criteria
        //   form.submittable(true)
        // }
      })
    },
  })

  const variants = {
    hidden: {
      opacity: 0,
      y: 15,
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
    exit: {
      opacity: 0,
      y: 15,
    },
  }

  return (
    <AnimatePresence initial={false} exitBeforeEnter>
      {!hide && (
        <motion.div
          animate="show"
          variants={variants}
          initial="hidden"
          exit="exit">
          <div className={styles.title}>{t('labels.register_text')}</div>
          <div className={styles.formWrapper}>{form}</div>
        </motion.div>
      )}

      {hide && (
        <motion.div
          animate="show"
          variants={variants}
          onAnimationComplete={() => {
            if (gaEventData) {
              gaEvent(...gaEventData)
            }
          }}
          initial="hidden">
          <div className={styles.message}>
            <Button styleOnly fullWidth type="outline">
              {successMessage}
            </Button>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

MarketoForm.propTypes = {
  formId: PropTypes.number.isRequired,
  hide: PropTypes.bool,
  setHide: PropTypes.func,
  gaEventData: PropTypes.array,
  successMessage: PropTypes.string,
}

export default MarketoForm
