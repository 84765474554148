import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './index.module.scss'
import IconWrapper from 'components/pieces/IconWrapper'
import Image from 'components/pieces/Image'
import companyShape from 'shapes/company'
import { useEffect, useMemo, useRef, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { parseHtml } from 'utils/parseHtml'
import _ from 'lodash'
import { useDocumentSize } from 'utils/useDocumentSize'
import { useTranslation } from 'react-i18next'

const CoalitionItem = ({ playing, item, index, type }) => {
  const { t } = useTranslation('common', 'ada')
  const ref = useRef(null)
  const buttonRef = useRef(null)
  const [visible, setVisible] = useState(true)
  const documentSize = useDocumentSize()
  const delay = useMemo(() => {
    return _.random(-4.2, 0.1)
  }, [])

  useEffect(() => {
    const checkVisibility = () => {
      if (!ref.current) {
        return
      }

      window.requestAnimationFrame(() => {
        const rect = ref.current.getBoundingClientRect()
        setVisible(rect.left >= -32 && rect.right <= window.innerWidth + 32)
      })
    }

    let interval
    if (playing) {
      interval = setInterval(checkVisibility, 1000)
    } else {
      clearInterval(interval)
    }

    return () => {
      clearInterval(interval)
    }
  }, [playing, ref])

  return (
    <>
      <span
        ref={ref}
        style={{
          alignSelf: item?.position === 'top' ? 'flex-start' : 'flex-end',
          marginTop: item?.position === 'top' ? item?.top : 'auto',
          marginBottom: item?.position === 'bottom' ? item?.bottom : 'auto',
          '--delay': delay + 's',
          '--max-width':
            item?.logo?.width / item?.logo.height > 1.1 ? '100px' : '80px',
          '--max-height':
            item?.logo?.width / item?.logo.height > 1.1 ? '42px' : '56px',
        }}
        className={cn(styles.item, styles[type])}>
        <IconWrapper
          aria={t('ada:coalition.aria', { title: item.name })}
          buttonRef={buttonRef}
          data-iscapture="true"
          className={cn(styles.button, { [styles.visible]: visible })}
          aria-hidden={visible ? 'false' : 'true'}
          onClick={
            documentSize?.width > 768
              ? () => {}
              : (e) => {
                  e.preventDefault()
                }
          }
          data-for={`tooltip-${item?.name}-${type}-${index}`}
          data-tip
          tabIndex={visible ? 0 : -1}
          link={item.link}
          data-event="mouseenter focus click"
          data-event-off="mouseleave blur">
          <Image image={item.logo} alt={item.name} layout="responsive" />
        </IconWrapper>
      </span>

      <ReactTooltip
        clickable={documentSize?.width < 768 && true}
        id={`tooltip-${item?.name}-${type}-${index}`}
        key={`tooltip-${index}`}
        multiline={true}>
        {item?.description && parseHtml(item?.description)}
        {documentSize?.width < 768 && item?.link && (
          <a
            href={item?.link}
            target="_blank"
            rel="noreferrer"
            className={styles.tooltipLink}
            aria-label={t('ada:global.learn_more', { title: item.name })}>
            {t('learn_more')}
          </a>
        )}
      </ReactTooltip>
    </>
  )
}

export default CoalitionItem

CoalitionItem.propTypes = {
  playing: PropTypes.bool,
  item: companyShape,
  index: PropTypes.number,
  type: PropTypes.oneOf(['marquee', 'grid']),
}
