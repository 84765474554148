import { useIntroContext } from 'components/layout/_Layout/introContext'
import { useTranslation } from 'react-i18next'
import Button from '../Button'
import styles from './index.module.scss'

const SkipToMain = () => {
  const { t } = useTranslation('common')
  const introContext = useIntroContext()
  const skipHeader = () => {
    introContext?.setInterruptIntro(true)
    const main = document.getElementById('main')

    const focusableEls = main?.querySelectorAll(
      'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled]), div[tabindex="0"]'
    )

    if (focusableEls && focusableEls?.length) {
      const firstFocusable = focusableEls[0]
      firstFocusable.focus()
    }
  }
  return (
    <Button className={styles.skipToMain} onClick={skipHeader}>
      {t('skip_to_main')}
    </Button>
  )
}

export default SkipToMain
