import cn from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Button from 'components/pieces/Button'
import styles from './index.module.scss'
import IconCard from 'components/pieces/Card/IconCard'
import { useState } from 'react'
import { imageResolver } from 'utils/imageResolver'
import { parseHtml } from 'utils/parseHtml'
import gaEvent from 'utils/analytics'

const BrandCards = ({ items, onAction, openModal }) => {
  const { t } = useTranslation('events', 'essentials', 'common', 'ada')
  const [tempPayload, setTempPayload] = useState()

  const onChange = (e) => {
    setTempPayload(e?.target?.value)
  }

  const onSubmit = (e, item) => {
    e.preventDefault()
    onAction(item, tempPayload)
    const module = document.getElementById('essentials')
    module?.scrollIntoView()
  }

  const renderCTA = (item) => {
    switch (item.name) {
      case 'Dunkin':
        return (
          <>
            <form
              onSubmit={(e) => {
                onSubmit(e, item)
              }}>
              <label className={styles.label} htmlFor="zip">
                {t('essentials:labels.zip')}
              </label>
              <input
                id="zip"
                onChange={onChange}
                value={tempPayload || ''}
                className={styles.input}
                placeholder={t('essentials:labels.zip')}
              />
              <Button
                disabled={!tempPayload || tempPayload === ''}
                fullWidth
                id="dunkinCta"
                type="whitebg"
                onClick={(e) => {
                  onSubmit(e, item)
                  gaEvent('Essentials', 'Submit', 'Dunkin - Find Stores')
                }}>
                {item?.cta_text}
              </Button>
            </form>
            <button
              aria-label={t('ada:dunkin_faq')}
              className={styles.faqBtn}
              onClick={() => openModal(item)}>
              {t('common:FAQ')}
            </button>
          </>
        )

      case 'Lyft':
        return (
          <div className={styles.buttons}>
            <Button
              fullWidth
              id="lyftCta"
              type="whitebg"
              link={item?.lyft_link}>
              {item?.lyft_text}
            </Button>
            <button
              aria-label={t('ada:lyft_faq')}
              className={styles.faqBtn}
              onClick={() => openModal(item)}>
              {t('common:FAQ')}
            </button>
          </div>
        )

      default:
        return <> </>
    }
  }

  return (
    <div className={styles.cards}>
      {items.map((item, j) => {
        return (
          <div
            style={{
              '--resource-theme-color': item?.color,
              '--bg-bottom': item?.background_svg_bottom,
              '--bg-right': item?.background_svg_right,
              '--bg-left': item?.name === 'Dunkin' ? '0px' : '40%',
              '--bg-top': 'auto',
              zIndex: j === 0 ? 1 : 0,
            }}
            key={`item-${j}`}
            className={cn(styles.cardWrapper)}>
            <IconCard
              item={{
                ...item,
                title: parseHtml(item.title),
                logo: imageResolver(item?.name),
                description: (
                  <div className={styles.description}>
                    {parseHtml(item?.description)}
                  </div>
                ),
              }}
              Bg={item?.background_svg}
              className={styles.card}
              titleClass={styles.title}
              inView={true}>
              {renderCTA(item)}
            </IconCard>
          </div>
        )
      })}
    </div>
  )
}

BrandCards.propTypes = {
  items: PropTypes.array,
  code: PropTypes.string,
  onAction: PropTypes.func,
  openModal: PropTypes.func,
}
export default BrandCards
