import Layout from 'components/layout/_Layout'
import InViewReveal from 'components/pieces/InViewReveal'
import EventPage from 'components/slices/EventPage'
import { useTranslation } from 'react-i18next'
import { IRL_EVENTS } from 'constant/events'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useEffect } from 'react'
import { gaPageview } from 'utils/analytics'

function Events() {
  const params = useParams()
  const { t } = useTranslation('events')
  const events = t('events', { returnObjects: true })
  const _events = events.map((event, i) => ({
    ...event,
    ...IRL_EVENTS[i],
  }))
  const event = _events.find((element) => params.handle === element?.handle)
  useEffect(() => {
    gaPageview()
  }, [])

  const title = t('meta_title', { title: event.title })
  const description = t('meta_description')

  return (
    <div className="Event">
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta name="twitter:title" content={event.title} />
        <meta
          name="description"
          content={description}
          data-react-helmet="true"
        />
        <meta
          property="og:description"
          content={description}
          data-react-helmet="true"
        />
        <meta
          name="twitter:description"
          content={description}
          data-react-helmet="true"
        />
      </Helmet>
      <Layout pageTheme="light" whiteLogo hideFooter pageType="event">
        <InViewReveal
          id={`${event?.handle}-event`}
          key={`${event?.handle}-event`}>
          <div>
            <EventPage event={event} />
          </div>
        </InViewReveal>
      </Layout>
    </div>
  )
}

export default Events
