import Layout from 'components/layout/_Layout'
import { useParams } from 'react-router-dom'
import CarouselModal from 'components/slices/Carousel/Modal'
import NotFound from './notfound'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { gaPageview } from 'utils/analytics'

function Tips() {
  const params = useParams()
  const { t } = useTranslation('tips')
  const tips = t('tips', { returnObjects: true })
  const tip = tips.find((element) => params.handle === element?.handle)

  useEffect(() => {
    gaPageview()
  }, [])

  return (
    <div className="Tips">
      <Helmet>
        <title> Interview Tip - {tip.title} | Ready to Work </title>
        <meta
          property="og:title"
          content={`Interview Tip - ${tip.title} | Ready to Work`}
        />
        <meta
          name="twitter:title"
          content={`Interview Tip - ${tip.title} | Ready to Work`}
        />
        <meta
          name="description"
          content="Connect with actively hiring employers, get resume assistance, attend in-person events, access resources from our coalition and more."
          data-react-helmet="true"
        />
        <meta
          property="og:description"
          content="Connect with actively hiring employers, get resume assistance, attend in-person events, access resources from our coalition and more."
          data-react-helmet="true"
        />
        <meta
          name="twitter:description"
          content="Connect with actively hiring employers, get resume assistance, attend in-person events, access resources from our coalition and more."
          data-react-helmet="true"
        />
      </Helmet>

      <Layout>
        {tip && (
          <CarouselModal
            index={0}
            type="skills"
            item={tip}
            showNavButtons={false}></CarouselModal>
        )}
        {!tip && <NotFound />}
      </Layout>
    </div>
  )
}

export default Tips
