/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import PropTypes from 'prop-types'
import styles from './index.module.scss'
import cn from 'classnames'
import { useState, useEffect, useCallback, useLayoutEffect } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import CarouselButton from '../CarouselButton'
import Container from 'components/layout/Container'
import Grid from 'components/layout/Grid'
import CarouselCard from '../CarouselCard'
import SectionHeader from 'components/pieces/SectionHeader'
import CarouselModal from '../Modal'
import sectionHeaderShape from 'shapes/sectionHeader'
import { getColor } from 'utils/getColor'
import { useDocumentSize } from 'utils/useDocumentSize'
import InViewReveal from 'components/pieces/InViewReveal'
import { scrollToSection } from 'utils/scrollToSection'
import ResourceCTAs from '../CTAs'
import { useTranslation } from 'react-i18next'
import { debounce } from 'lodash'
import gaEvent from 'utils/analytics'
import CarouselDot from '../CarouselDot'

// TODO: contextural routing for the modals(so the modal can be sharable)
const Carousel = ({
  items,
  sectionHeaderCopy,
  partner,
  logo,
  type,
  ctas,
  sectionId,
  showNavigation,
}) => {
  const documentSize = useDocumentSize()

  const { t } = useTranslation('tips', 'common')
  const _items = items ?? t('tips', { returnObjects: true })

  const [viewportRef, embla] = useEmblaCarousel({
    slidesToScroll: 1,
    align:
      (documentSize?.width < 768 && type !== 'resources') ||
      type === 'eventGallery'
        ? 'center'
        : 'start',
    loop: false,
    speed: 15,
    inViewThreshold: 0.5,
    // on mobile, disable trim snaps to allow center mode
    containScroll: documentSize?.width < 768 ? '' : 'trimSnaps',
  })
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)
  // selected slide is the one user interacted with
  const [selectedIndex, setSelectedIndex] = useState(-1)
  // active slide is current slide determined by embla
  const [activeSlide, setActiveSlide] = useState(-1)
  const [resizing, setResizing] = useState(false)
  const [confirm, setConfirm] = useState()
  const [nextPhaseId, setNextPhaseId] = useState(-1)
  const [inviewSlides, setInviewSlides] = useState([])

  const scrollPrev = useCallback(() => {
    if (embla) {
      embla.scrollPrev()
    }
  }, [embla])
  const scrollNext = useCallback(() => {
    if (embla) {
      embla.scrollNext()
    }
  }, [embla])
  const scrollTo = useCallback(
    (index) => embla && embla.scrollTo(index),
    [embla]
  )
  const onSelect = useCallback(() => {
    if (!embla) return
    setPrevBtnEnabled(embla.canScrollPrev())
    setNextBtnEnabled(embla.canScrollNext())
    setInviewSlides(embla.slidesInView(true))
    setActiveSlide(embla.selectedScrollSnap())
  }, [embla])

  const refreshEmbla = useCallback(() => {
    if (!embla) return
    embla.reInit()
    setPrevBtnEnabled(embla.canScrollPrev())
    setNextBtnEnabled(embla.canScrollNext())
    setInviewSlides(embla.slidesInView(true))
  }, [embla])

  useLayoutEffect(() => {
    if (!embla) return

    refreshEmbla()
  }, [embla, refreshEmbla, nextPhaseId])

  useEffect(() => {
    if (!embla) return

    const debouncedRefresh = debounce(() => {
      // Refresh embla once resizing and scrolling stops
      setTimeout(() => {
        refreshEmbla()
      }, 300)

      // Turn off our resizing class after resizing transitions are done
      setTimeout(() => {
        setResizing(false)
        refreshEmbla()
      }, 600)
    }, 150)

    const handleResize = () => {
      setResizing(true)
      debouncedRefresh()
    }

    embla.on('resize', handleResize)

    return () => {
      embla.off('resize', handleResize)
    }
  }, [embla, refreshEmbla])

  useEffect(() => {
    if (!embla) return

    embla.on('select', onSelect)
    onSelect()

    return () => {
      embla.off('select', onSelect)
    }
  }, [embla, onSelect])
  const currentResourceTheme = _items[selectedIndex]?.theme

  return (
    <div
      className={cn(styles[type], styles.section)}
      style={{ '--resource-theme-color': getColor(currentResourceTheme) }}>
      {nextPhaseId > -1 ? (
        <InViewReveal>
          <Container className={styles.wrapper}>
            <Grid>
              <div
                className={cn(styles.inner, {
                  [styles.cards]: !_items[selectedIndex]?.video,
                })}>
                <CarouselModal
                  item={_items[selectedIndex]}
                  index={selectedIndex}
                  setNextPhaseId={setNextPhaseId}
                  setSelectedIndex={setSelectedIndex}
                  total={_items?.length}
                  type={type}
                  sectionId={sectionId}
                  sectionHeaderCopy={{
                    eyebrow: sectionHeaderCopy?.eyebrow,
                    title: _items[selectedIndex]?.topic,
                    description: _items[selectedIndex]?.description,
                  }}
                />
              </div>
            </Grid>
          </Container>
        </InViewReveal>
      ) : (
        <>
          <Container className={styles.header}>
            <Grid>
              <div className={styles.inner}>
                <SectionHeader
                  eyebrow={sectionHeaderCopy?.eyebrow}
                  title={sectionHeaderCopy?.title}
                  description={sectionHeaderCopy?.description}
                />
                {ctas?.length && (
                  <ResourceCTAs
                    parentTitle={sectionHeaderCopy?.title}
                    ctas={ctas}
                  />
                )}
              </div>
            </Grid>
          </Container>
          <Container className={styles.wrapper}>
            <CarouselButton
              onClick={scrollPrev}
              enabled={prevBtnEnabled}
              type="prev"
              contentType={type}
            />
            <Grid>
              <div
                className={cn(styles.inner, {
                  [styles.hasSelected]:
                    selectedIndex > -1 &&
                    inviewSlides.indexOf(selectedIndex) > -1,
                  [styles.hasConfirm]: confirm,
                })}
                style={{ '--total': _items?.length }}>
                <div className={styles.embla}>
                  <div className={styles.viewport} ref={viewportRef}>
                    <div
                      className={cn(styles.container, {
                        [styles.resizing]: resizing,
                      })}>
                      {_items.map((item, index) => (
                        <CarouselCard
                          item={{ partner, logo, ...item }}
                          index={index}
                          activeSlide={activeSlide}
                          selectedIndex={selectedIndex}
                          hasSelected={
                            selectedIndex > -1 &&
                            inviewSlides.indexOf(selectedIndex) > -1
                          }
                          onClick={() => {
                            if (!embla?.clickAllowed()) return
                            setSelectedIndex(index)
                            setConfirm(true)
                            setNextPhaseId(index)
                            scrollToSection(sectionId)
                            gaEvent(
                              'Resources',
                              'Click',
                              'Select Resource',
                              item?.topic
                            )
                          }}
                          key={`card-${index}`}
                          type={type}
                          inView={inviewSlides.indexOf(index) > -1}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <CarouselButton
              onClick={scrollNext}
              enabled={nextBtnEnabled}
              type="next"
              contentType={type}
            />
            {showNavigation && (
              <div className={styles.navigation}>
                {items.map((_, index) => (
                  <CarouselDot
                    key={index}
                    active={index === activeSlide}
                    onClick={() => scrollTo(index)}
                  />
                ))}
              </div>
            )}
          </Container>
        </>
      )}
    </div>
  )
}

Carousel.propTypes = {
  items: PropTypes.array,
  sectionHeaderCopy: sectionHeaderShape,
  type: PropTypes.string,
  partner: PropTypes.string,
  logo: PropTypes.string,
  ctas: PropTypes.array,
  sectionId: PropTypes.string,
  showNavigation: PropTypes.bool,
}
export default Carousel
